import React from 'react';
import moment from 'moment';

import PendingCard from './PendingCard';
import {
  STACK_OVERFLOW,
  CONTENT,
  OSS_CONTRIBUTION,
  OSS_PROJECT,
} from '../../../constants/achievementTypes';

const achievementData = {
  [STACK_OVERFLOW]: {
    name: 'Stack Overflow',
    icon: 'images/icon_content_creator.svg',
  },
  [CONTENT]: {
    name: 'Content Creator',
    icon: 'images/icon_content_creator.svg',
  },
  [OSS_CONTRIBUTION]: {
    name: 'Open Source Contributor',
    icon: 'images/icon_oss_contributor.svg',
  },
  [OSS_PROJECT]: {
    name: 'Open Source Creator',
    icon: 'images/icon_oss_creator.svg',
  },
};

export default ({ index, achievement }) => (
  <div className={`w-col w-col-6 w-col-stack ${index % 2 ? 'card-col-02' : 'card-col-01'}`}>
    {achievement.status === 'pending' && (
      <PendingCard index={index} />
    )}
    {achievement.status === 'validated' && (
      <div className={`section-card ${index % 2 ? 'right' : 'left'}`}>
        <button type="button" className="card-delete-btn w-inline-block" />
        <div className="card-content achievement">
          <div className="achievement-type">
            <img src={achievementData[achievement.type].icon} alt="" />
            <div className="achievement-title">{achievementData[achievement.type].name}</div>
          </div>
          <div className="section-card-divider achievement" />
          <div className="section-card-title achievement">{achievement.name}</div>
          <div className="body-txt description">{achievement.description}</div>
          <button type="button" className="card-link" style={{ opacity: 0 }}>VIEW&nbsp;PROJECT</button>
          <div className="section-card-divider bottom" />
          <div className="card-bottom-content achievement">
            <div className="verified-wrapper">
              <img src="images/verified_check.svg" alt="" className="verify-check" />
              <div className="verified-txt">Verified by North</div>
              <div className="verified-txt mob">Verified</div>
            </div>
            <div className="card-time-wrapper">
              <img src="images/icon_time.svg" alt="" className="card-time-icon" />
              <div className="card-time">{moment(achievement.achievedAt).format('LL')}</div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
