import React from 'react';

export default ({ closeModal }) => (
  <div className="modals-rank-info">
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Rank info</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-content">
          <div className="rank-wrapper">
            <div className="rank-info-group">
              <div className="rank-type no-pad">
                <div className="rank-label bold">Telepat North Ranks</div>
                <a
                  href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="source-btn rank w-inline-block"
                >
                  <div>Handbook</div>
                </a>
              </div>
              <a
                href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                target="_blank"
                rel="noopener noreferrer"
                className="handbook-link w-inline-block"
              >
                &nbsp;
              </a>
            </div>
            <div className="rank-divider" />
            <div className="body-txt">
              North membership has three Ranks: Cadets, Stars and Superstars.
              Each Rank grants a member access to a distinct set of benefits and opportunities.
              <br />
              <br />
              Aspirant is a special, limited-priviledge North Rank. Aspirants are coders
              that are new to the field, don&apos;t have extensive experience, or need to expand
              or improve their professional skills to advance in rank.
              <br />
              <br />
              Check out&nbsp;
              <a
                href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ranks explained in the Handbook
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
