import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, HashRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './store';

import requireAuth from './components/HOCs/requireAuth';
import Login from './components/Login';
import Landing from './components/LP/Landing';
import Code from './components/LP/Code';
import Handbook from './components/LP/Handbook';
import FAQ from './components/LP/FAQ';
import LoginMobile from './components/LP/LoginMobile';
import SignupMobile from './components/LP/SignupMobile';
import Welcome from './components/360/Welcome';
import SelectUsername from './components/360/SelectUsername';
import TestDashboard from './components/360/TestDashboard';
import LeadQualification from './components/360/LeadQualification';
import UserDashboard from './components/UserDashboard';
import Profile from './components/360/Profile';

import Test from './components/Tests/Test';
import TestGroup from './components/Tests/TestGroup';
import withTracker from './components/HOCs/TrackingHOC';
import withIntercom from './components/HOCs/IntercomHOC';
import withOnboarding from './components/HOCs/Onboarding';

import * as allTestData from './constants/testData';
import AttributionCookie from './services/attributionCookie';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  // eslint-disable-next-line no-console
  console.log(`INIT Telepat North 360 version ${process.env.REACT_APP_SENTRY_RELEASE}`);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
  });
}

ReactGA.initialize(process.env.REACT_APP_GA_ID);

AttributionCookie.track();

ReactDOM.render((
  <HttpsRedirect>
    <Provider store={store}>
      <HashRouter>
        <Route exact path="/" component={withTracker(Landing)} />
        <Route exact path="/login/:jwt" component={withTracker(Login)} />
        <Route exact path="/welcome" component={withIntercom(requireAuth(withTracker(Welcome)))} />
        <Route exact path="/quick-intro" component={withIntercom(requireAuth(withTracker(LeadQualification)))} />
        <Route exact path="/choose-nickname" component={requireAuth(withIntercom(withTracker(SelectUsername)))} />
        <Route exact path="/tests" component={requireAuth(withIntercom(withTracker(withOnboarding(TestDashboard))))} />
        <Route exact path="/users" component={requireAuth(withTracker(UserDashboard))} />
        <Route exact path="/code" component={withTracker(Code)} />
        <Route exact path="/handbook" component={withTracker(Handbook)} />
        <Route exact path="/faq" component={withTracker(FAQ)} />
        <Route exact path="/signup-mb" component={withTracker(SignupMobile)} />
        <Route exact path="/login-mb" component={withTracker(LoginMobile)} />
        <Route
          exact
          path="/tests/:type/section/:section/run"
          component={requireAuth(withTracker(Test))}
        />
        <Route
          exact
          path="/tests/:type/section/:section/group/:groupStartIndex/run"
          component={requireAuth(withTracker(TestGroup))}
        />
        <Route exact path="/p/:nickname" component={withTracker(Profile)} />

        {Object.keys(allTestData).map((testDataKey) => {
          const testData = allTestData[testDataKey];
          return [(
            <Route
              key={`${testData.slug}1`}
              exact
              path={`/tests/${testData.slug}/info`}
              component={withIntercom(requireAuth(withTracker(testData.infoComponent)), true)}
            />
          ), (
            <Route
              key={`${testData.slug}2`}
              exact
              path={`/tests/${testData.slug}/done`}
              component={withIntercom(requireAuth(withTracker(testData.doneComponent)), true)}
            />
          )].concat(testData.sections.map((section, index) => (
            <Route
              key={`${index * 2}`}
              exact
              path={`/tests/${testData.slug}/section/${index + 1}`}
              component={withIntercom(requireAuth(withTracker(section)), true)}
            />
          )));
        })}
      </HashRouter>
    </Provider>
  </HttpsRedirect>
), document.getElementById('root'));

serviceWorker.unregister();
