/* eslint-disable import/no-cycle */
import {
  put, takeEvery, all, call, select,
} from 'redux-saga/effects';
import { debounce } from 'throttle-debounce';

import { jwtLogin } from './user';
import actions from '../constants/actions';
import API from '../services/api';

let debouncedPostAnswers = {};
const getDebouncedPostAnswers = (questionId) => {
  if (!debouncedPostAnswers[questionId]) {
    debouncedPostAnswers[questionId] = debounce(700, API.postAnswers);
  }

  return debouncedPostAnswers[questionId];
};

function* loadTest(action) {
  try {
    const jwt = yield select(state => state.user.jwt);

    yield jwtLogin({ jwt });
    const { data } = yield call(API.getTest, action.testType, jwt);
    yield put({ type: actions.LOAD_TEST_SUCCESS, data });
    yield put({ type: actions.REFRESH_RESULTS, data: data.results });
  } catch (error) {
    yield put({ type: actions.LOAD_TEST_ERROR, error: error.message });
  }
}

export function* loadTests() {
  try {
    const jwt = yield select(state => state.user.jwt);

    const { data } = yield call(API.getTests, jwt);
    yield put({ type: actions.LOAD_TESTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.LOAD_TESTS_ERROR, error: error.message });
  }
}

function* closeTest(action) {
  try {
    const jwt = yield select(state => state.user.jwt);

    const { data } = yield call(API.closeTest, action.testId, jwt);
    yield put({ type: actions.REFRESH_RESULTS, data });
    debouncedPostAnswers = {};
  } catch (error) {
    yield put({ type: actions.CLOSE_TEST_ERROR, error: error.message });
  }
}

function* bumpTestDuration() {
  try {
    const jwt = yield select(state => state.user.jwt);
    const testId = yield select(state => state.test.data._id);

    const { data } = yield call(API.bumpTestDuration, testId, jwt);
    yield put({ type: actions.REFRESH_RESULTS, data });
  } catch (error) {
    console.error(error);
  }
}

function* postAnswers() {
  try {
    const currentQuestionId = yield select(state => state.test.data
      .sections[state.test.currentSectionIndex]
      .questions[state.test.currentQuestionIndex]
      ._id);
    const currentResultsId = yield select(state => state.test.data.results._id);
    const currentAnswers = yield select(state => state.test
      .answers[state.test.currentQuestionIndex]);
    const jwt = yield select(state => state.user.jwt);

    yield call(
      getDebouncedPostAnswers(currentQuestionId),
      currentResultsId,
      currentQuestionId,
      currentAnswers,
      jwt,
    );
  } catch (error) {
    yield put({ type: actions.POST_ANSWER_ERROR, error: error.message });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LOAD_TEST, loadTest),
    yield takeEvery(actions.LOAD_TESTS, loadTests),
    yield takeEvery(actions.POST_ANSWER, postAnswers),
    yield takeEvery(actions.CLOSE_TEST, closeTest),
    yield takeEvery(actions.BUMP_TEST_DURATION, bumpTestDuration),
  ]);
}
