import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUserLogged } from '../selectors/user';

export default (props) => {
  const dispatch = useDispatch();
  const { match: { params: { jwt } } } = props;
  const logged = useSelector(getUserLogged);
  const login = token => dispatch({ type: 'JWT_LOGIN', jwt: token });

  if (logged) {
    return (<Redirect to="/tests" />);
  }

  login(jwt);

  return null;
};
