import React from 'react';
import { useSelector } from 'react-redux';

import useIntercom from './useIntercom';
import { getUserData } from '../../selectors/user';
import { getTestsAvailability } from '../../selectors/tests';

export default (WrappedComponent, hideIntercomLauncher = false) => {
  const HOC = (props) => {
    const user = useSelector(getUserData) || {};
    const testAvailability = useSelector(getTestsAvailability) || {};
    const intercomUserData = Object.keys(user).length
      ? {
        hide_default_launcher: hideIntercomLauncher,
        user_id: user._id || null,
        email: user.email || null,
        north_user: true,
        lead_utm_campaign:
          (user.attributionData && user.attributionData.utmCampaign) || null,
        lead_utm_source: (user.attributionData && user.attributionData.utmSource) || null,
        lead_utm_medium: (user.attributionData && user.attributionData.utmMedium) || null,
        profile_updates_comm_permission:
          (user.permissions && user.permissions.profileUpdatesComm) || false,
        opportunities_comm_permission:
          (user.permissions && user.permissions.profileUpdatesComm) || false,
        picked_nickname: user.pickedNickname || false,
        nickname: user.nickname || '',
        north_objective: user.northObjective || 'unknown',
        profession: user.profession || 'unknown',
        is_gh_user: user.isGhUser || true,
        profile_updated_at: user.updatedAt || null,
        ...(
          Object.keys(testAvailability)
            .reduce((a, k) => {
              const record = (user.recentResults || []).find(r => r.type === k);
              return {
                ...a,
                [`took_${k}`]: !testAvailability[k],
                [`started_${k}_at`]: record ? record.createdAt : null,
              };
            }, {})
        ),
      }
      : {};

    useIntercom(process.env.REACT_APP_INTERCOM_ID, intercomUserData);
    return <WrappedComponent {...props} />;
  };

  return HOC;
};
