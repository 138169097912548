import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import userSaga from '../sagas/user';
import testSaga from '../sagas/test';
import profileSaga from '../sagas/profile';
import achievementSaga from '../sagas/achievement';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = process.env.NODE_ENV !== 'production'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    name: 'Telepat North',
  }) : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  ),
);

sagaMiddleware.run(userSaga);
sagaMiddleware.run(testSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(achievementSaga);

export default store;
