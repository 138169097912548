import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../constants/actions';

export default withRouter(({
  groupLength, section, startIndex, history,
}) => {
  const testData = useSelector(state => state.test.data);
  const dispatch = useDispatch();

  if (!testData) {
    return (<div />);
  }

  const currentQuestionIndex = useSelector(state => state.test.currentQuestionIndex);

  const currentQuestionLabel = currentQuestionIndex === 0
    ? 1 : currentQuestionIndex - parseInt(startIndex, 10) + 2;

  const handlePush = () => {
    dispatch({ type: actions.ALLOW_TRANSITIONS });
    setTimeout(() => {
      history.push(`/tests/exp/section/${section}/run`);
    }, 300);
  };

  return (
    <div className="test-footer fixed">
      <div className="test-footer-wrapper wide">
        <div className="test-footer-box">
          <button
            type="button"
            onClick={handlePush}
            className="test-footer-back w-inline-block"
          />
          <div className="test-footer-progress">
            <div className="test-footer-info">
              <div className="test-section-txt no-time">
                Questions
              </div>
              <div className="question-number">
                {currentQuestionLabel}
                &nbsp;of&nbsp;
                {groupLength}
              </div>
            </div>
            <div className="progress-bg exp-test">
              <div className="progress-bar" style={{ width: `${Math.floor(currentQuestionLabel / groupLength * 100)}%` }} />
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={handlePush}
          className="test-footer-next w-inline-block"
        >
          <img src="images/done_icon.png" width="22" alt="" />
        </button>
      </div>
    </div>
  );
});
