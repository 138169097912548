import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, Link, withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import * as values from 'object.values';

import TestFooter from './TestFooter';
import TestGroupFooterMain from './TestGroupFooterMain';
import Question from './TestQuestion';
import Answers from './TestAnswers';
import actions from '../../constants/actions';
import { getCurrentSection, getAllQuestions, getAllowTransitions } from '../../selectors/test';

import * as allTestData from '../../constants/testData';

export default withRouter(({ history, match: { params: { type, section } } }) => {
  if (!Object.values) {
    values.shim();
  }
  const loading = useSelector(state => state.user.loading || state.test.loading);
  const error = useSelector(state => state.test.error);
  const testData = useSelector(state => state.test.data);
  const tests = useSelector(state => state.tests.data);
  const groupAnswers = useSelector(state => state.test.groupAnswers);
  const allQuestions = useSelector(getAllQuestions);
  const sectionData = useSelector(getCurrentSection);
  const allowTransitions = useSelector(getAllowTransitions);
  const [questionGroups, setQuestionGroups] = useState([]);

  const [showQuestionGroups, setShowQuestionGroups] = useState(false);

  const dispatch = useDispatch();

  const questionRef = useRef();

  const handleNext = () => questionRef.current && questionRef.current.scrollTo(0, 0);

  dispatch({ type: actions.SET_SECTION, sectionIndex: parseInt(section, 10) - 1 });

  useEffect(() => {
    const loadTest = testType => dispatch({ type: actions.LOAD_TEST, testType });

    if (tests) {
      loadTest(
        tests
          .find(
            test => test.type === Object.values(allTestData).find(t => t.slug === type).type,
          )
          ._id,
      );
      if (window.Intercom) {
        window.Intercom('trackEvent', `started-${type}-test`);
      }
    } else {
      dispatch({ type: actions.LOAD_TESTS });
    }
    // TODO check for better practices
    // eslint-disable-next-line
  }, [type, tests]);

  if (
    sectionData
    && sectionData.questionGroups
    && sectionData.questionGroups.length
    && !questionGroups.length
  ) {
    setQuestionGroups(sectionData.questionGroups.map((g, index) => {
      let groupLength;
      if (sectionData.questionGroups[index + 1]) {
        groupLength = sectionData.questionGroups[index + 1].startIndex - g.startIndex;
      } else {
        groupLength = allQuestions.length - g.startIndex;
      }
      return {
        ...g,
        index,
        groupLength,
      };
    }));
    setShowQuestionGroups(true);
  }

  if (showQuestionGroups) {
    const groups = questionGroups.map((group, index) => {
      if (index % 2 === 0) {
        const groupLeft = group;
        const glAnswers = groupAnswers ? groupAnswers[groupLeft.startIndex] : [];
        const glCount = glAnswers ? glAnswers.reduce(c => c + 1, 0) : 0;
        const glProgressBar = Math.ceil(glCount / groupLeft.groupLength * 100.0);

        const groupRight = questionGroups[index + 1];
        const grAnswers = groupRight && groupAnswers ? groupAnswers[groupRight.startIndex] : [];
        const grCount = groupRight && grAnswers ? grAnswers.reduce(c => c + 1, 0) : 0;
        const grProgressBar = groupRight ? Math.ceil(grCount / groupRight.groupLength * 100.0) : 0;

        const handlePush = (startIndex) => {
          dispatch({ type: actions.ALLOW_TRANSITIONS });
          setTimeout(() => {
            history.push(`/tests/exp/section/${section}/group/${startIndex}/run`);
          }, 300);
        };

        const handleLeftPush = () => handlePush(groupLeft.startIndex);
        const handleRightPush = () => handlePush(groupRight.startIndex);

        window.scrollTo(0, 0);

        return (
          <div className="intro-content exp" key={groupLeft.startIndex}>
            <div className="col-wrapper">
              <div className="exp-card first">
                <div className={`exp-card-title ${glCount === 0 ? 'inactive' : ''}`}>{groupLeft.title}</div>
                <div className="progress-bg exp">
                  <div className="progress-bar" style={{ width: `${glProgressBar}%` }} />
                </div>
                <div className="exp-card-actions">
                  <div className="exp-points">
                    {glCount}
                    &nbsp;/&nbsp;
                    {groupLeft.groupLength}
                    &nbsp;points
                  </div>
                  <button
                    type="button"
                    onClick={handleLeftPush}
                    className="card-btn xsm w-inline-block"
                  >
                    <div>{glCount > 0 ? 'Edit' : 'Add' }</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-wrapper right">
              {groupRight !== undefined && (
                <div className="exp-card">
                  <div className={`exp-card-title ${grCount === 0 ? 'inactive' : ''}`}>{groupRight.title}</div>
                  <div className="progress-bg exp">
                    <div className="progress-bar" style={{ width: `${grProgressBar}%` }} />
                  </div>
                  <div className="exp-card-actions">
                    <div className="exp-points">
                      { grCount }
                      &nbsp;/&nbsp;
                      { groupRight.groupLength }
                      &nbsp;points
                    </div>
                    <button
                      type="button"
                      onClick={handleRightPush}
                      className="card-btn xsm w-inline-block"
                    >
                      <div>{grCount > 0 ? 'Edit' : 'Add'}</div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }
      return null;
    });

    return (
      <div>
        <div className="nav-bar">
          <div className="nav-bar-back">
            <div className="nav-back-box">
              <Link to="/tests" className="nav-back-btn w-inline-block" />
              <div className="nav-label">Experience &amp; Practices</div>
            </div>
          </div>
        </div>
        <div className="page-wrapper exp">
          <div className="page-content narrow">
            <div className="intro-header">
              <div className="title">Add experience:</div>
            </div>
            { groups }
          </div>
        </div>
        <Prompt
          when={!allowTransitions}
          message="Leaving this page will reset your progress. Are you sure?"
        />
        <TestGroupFooterMain
          totalGroups={questionGroups.length}
        />
      </div>
    );
  }

  if (loading || !testData) {
    return (
      <div className="full-bg">
        <div className="content-wrapper">
          <ReactLoading type="bars" color="#95A2B8" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  return (
    <div className="test-page">
      <Prompt
        when={!allowTransitions}
        message="Leaving this page will reset your progress. Are you sure?"
      />
      <Question />
      <Answers scrollRef={questionRef} />
      <TestFooter onNext={handleNext} color={testData.color || ''} />
    </div>
  );
});
