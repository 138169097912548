import React from 'react';
import { useSelector } from 'react-redux';

import { getIntroVideos } from '../../selectors/achievements';

export default ({ bottomSeparator = false, publicProfile = false }) => {
  const currentIntroVideo = useSelector(getIntroVideos(publicProfile))[0];

  if (publicProfile && (!currentIntroVideo || currentIntroVideo.status !== 'validated')) {
    return [];
  }

  return (
    <div className="intro-vid">
      <div className="section-header">
        <div className="section-title-wrapper">
          <div className="section-title">Introduction Video</div>
        </div>
        {/* <div className="section-body-txt">
          Even the all-powerful Pointing has no control about
          {' '}
          <strong>the blind texts</strong>
          {' '}
          it is an almost unorthographic life One day however a small line of blind
          {' '}
          <strong>5 XP</strong>
          {' '}
        text by the name of Lorem Ipsum decided to leave.
        </div> */}
      </div>
      <div className="vid-wrapper">
        {(!currentIntroVideo || currentIntroVideo.status === 'rejected') && (
          <div className="empty-card">
            <div className="placeholder-content">
              <img src="images/placeholder_video.svg" alt="" />
              <div className="placeholder-title">Record your Intro</div>
              <a
                href="https://calendly.com/gabidobocan/north-video-intro"
                className="placeholder-btn w-inline-block"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div>SCHEDULE NOW</div>
              </a>
            </div>
          </div>
        )}
        {(currentIntroVideo && currentIntroVideo.status === 'pending') && (
          <div className="empty-card pending">
            <div className="card-content-pending video">
              <img src="images/placeholder_showcase.svg" alt="" />
              <div className="pending-title">Hang Tight!</div>
              <div className="pending-txt">Your video is being generated and will appear here ASAP. Please check back.</div>
              <div className="pending-tag">PENDING</div>
            </div>
          </div>
        )}
        {(currentIntroVideo && currentIntroVideo.status === 'validated') && (
          <iframe
            title="Code Showcase"
            className="showcase-img code-showcase-video"
            width="901"
            height="507"
            src={`https://www.youtube.com/embed/${currentIntroVideo.urls[0]}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      {bottomSeparator && (<div className="profile-section-divider" />)}
    </div>
  );
};
