import React from 'react';

export default ({ currentAnswer, setAnswer }) => (
  <input
    type="text"
    maxLength="256"
    name="Other-Experience"
    data-name="Other Experience"
    placeholder="Type your answer here..."
    id="Other-Experience"
    className="test-input exp w-input"
    autoComplete="off"
    defaultValue={(currentAnswer[0] || { value: '' }).value}
    onChange={event => setAnswer(null, event.target.value)}
  />
);
