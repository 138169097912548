import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back">
        <div className="nav-back-box">
          <Link to="/tests" className="nav-back-btn w-inline-block" />
          <div className="nav-label">Experience &amp; Practices</div>
        </div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="intro-header">
          <div className="intro-bar" />
          <div className="title">Intro</div>
        </div>
        <div className="intro-content">
          <div className="col-wrapper">
            <div className="intro-txt-left">
              <p className="body-txt">
                You’ll be asked to rate your experience with a series of software tools grouped by
                category, and select true statements about your work background and practices.
                <br />
                <br />
                This is your unique professional fingerprint, that will be reflected in your
                profile. There’s no right or wrong answer, so take your time but don’t overthink it.
              </p>
              <div className="intro-txt-right mob">
                <div className="intro-txt-title">We’re working to make this test better!</div>
                <div className="list-item info">
                  <div className="dot blue" />
                  <div className="body-txt">
                    Currently, it’s focused on picking up web Frontend, Backend, Big Data,
                    AI and DevOps skills.
                  </div>
                </div>
                <div className="list-item info">
                  <div className="dot blue" />
                  <div className="body-txt">
                    If, after taking the survey, you feel we’ve missed a big part of your work
                    experience and we can improve the structure or questions within, drop
                    us a note at&nbsp;
                    <span className="inline-link">founders@telepat.io</span>
                    .
                  </div>
                </div>
              </div>
            </div>
            <Link to="/tests/exp/section/1/run" className="button outline w-inline-block">
              <div>Begin the test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="intro-txt-right">
              <div className="intro-txt-title">We’re working to make this test better!</div>
              <div className="list-item info">
                <div className="dot-360 blue" />
                <div className="body-txt">
                  Currently, it’s focused on picking up web Frontend, Backend,
                  Big Data, AI and DevOps skills.
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360 blue" />
                <div className="body-txt">
                  If, after taking the survey, you feel we’ve missed a big part of your work
                  experience and we can improve the structure or questions within, drop us a
                  note at&nbsp;
                  <span className="inline-link">founders@telepat.io</span>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
