import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import actions from '../constants/actions';
import { getAllUsers, isProcessingInvite } from '../selectors/user';

import Footer from './360/Footer';
import Header from './360/Header';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default () => {
  const dispatch = useDispatch();
  const allUsers = useSelector(getAllUsers);
  const inviteProcessing = useSelector(isProcessingInvite);

  const classes = useStyles();

  useEffect(() => {
    dispatch({ type: actions.LOAD_USERS });
    // eslint-disable-next-line
  }, []);

  const handleInvite = userId => () => {
    dispatch({ type: actions.INVITE_USER, userId });
  };

  const handleRemind = userId => () => {
    dispatch({ type: actions.REMIND_USER, userId });
  };

  const renderTakenTest = (takenTests, type) => {
    if (!takenTests[type]) return 'NOT TAKEN';
    if (takenTests[type] === 'taken') return 'DONE';
    return 'IN PROGRESS';
  };

  const renderInvite = (user) => {
    if (inviteProcessing) {
      return (
        <span className="sent">
          Processing request
        </span>
      );
    }
    if (user.invites && user.invites.initialInvite) {
      return (
        <span className="sent">
          Invited:&nbsp;
          { moment.utc(user.invites.invitedAt).format('YYYY-MM-DD') }
        </span>
      );
    }
    return (
      <button type="button" onClick={handleInvite(user._id)} target="_blank">
        Invite email
      </button>
    );
  };

  const renderReminder = (user) => {
    if (inviteProcessing) {
      return (
        <span className="sent">
          Processing request
        </span>
      );
    }
    if (user.invites && user.invites.inviteReminder) {
      return (
        <span className="sent">
          Reminded:&nbsp;
          { moment.utc(user.invites.remindedAt).format('YYYY-MM-DD') }
        </span>
      );
    }
    return (
      <button type="button" onClick={handleRemind(user._id)} target="_blank">
        Invite reminder
      </button>
    );
  };

  const renderLeadQual = (user) => {
    const { profession, attributionData, northObjective } = user;
    let campaignInfo = attributionData ? attributionData.utmCampaign : 'direct';
    campaignInfo = campaignInfo === 'direct' && attributionData && attributionData.gclid ? 'google' : campaignInfo;
    const isGithub = user.ghId ? 'github' : 'email';
    return (
      <div>
        <b>Profession: &nbsp;</b>
        {profession}
        <br />
        <b>Objective: &nbsp;</b>
        {northObjective}
        <br />
        <b>Campaign: &nbsp;</b>
        { campaignInfo }
        <br />
        <b>Signup: &nbsp;</b>
        { isGithub }
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div className="page-wrapper gray">
        <div className="dash-content">
          <div className="divider exp" />
          {allUsers && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Tests</TableCell>
                  <TableCell>Lead Qual.</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers.map((row, index) => (
                  <TableRow key={row.nickname}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>
                      <b>IQ:&nbsp;</b>
                      {renderTakenTest(row.takenTests, 'CCFIT')}
                      <br />
                      <b>EI:&nbsp;</b>
                      {renderTakenTest(row.takenTests, 'SREIS')}
                      <br />
                      <b>EXP:&nbsp;</b>
                      {renderTakenTest(row.takenTests, 'IT_EXPERIENCE')}
                      <br />
                      <b>ORG:&nbsp;</b>
                      {renderTakenTest(row.takenTests, 'SCHNEIDER')}
                    </TableCell>
                    <TableCell>
                      {renderLeadQual(row)}
                    </TableCell>
                    <TableCell>{moment.utc(row.createdAt).format('YYYY-MM-DD HH:mm:SS')}</TableCell>
                    <TableCell className="admin-table action-cell">
                      <Link to={`/p/${row.nickname}`} target="_blank">
                        Profile
                      </Link>
                      <br />
                      {renderInvite(row)}
                      <br />
                      {renderReminder(row)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
          <div className="divider exp" />
        </div>
      </div>
      <Footer />
    </div>
  );
};
