import React from 'react';

export default (props) => {
  const { result, onShowInfo } = props;
  const testTaken = result && !result.open;

  return (
    <div id="gma-results" className="gma-results">
      <div className="result-card gma">
        <div className="card-header">
          <img src="images/test_icon_01.svg" alt="" className="gma-icon" />
          <div className="card-title result">General Mental Ability</div>
        </div>
        <div className="result-card-div" />
        <div className="header-col-right">
          {testTaken && (<div className="top-txt blue">{`Top ${result.percentile}%`}</div>)}
          {!testTaken && (<div className="top-txt">{(result && result.open) ? 'In progress' : 'Not taken'}</div>)}
          <button
            type="button"
            onClick={onShowInfo}
            className="results-more-btn blue w-inline-block"
          >
            <div>More info</div>
          </button>
        </div>
      </div>
    </div>
  );
};
