export default {
  JWT_LOGIN: 'JWT_LOGIN',
  JWT_LOGIN_SUCCESS: 'JWT_LOGIN_SUCCESS',
  JWT_LOGIN_ERROR: 'JWT_LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  USER_SET_PERMISSIONS: 'USER_SET_PERMISSIONS',
  REGENERATE_NICKNAME: 'REGENERATE_NICKNAME',
  REGENERATE_NICKNAME_SUCCESS: 'REGENERATE_NICKNAME_SUCCESS',
  REGENERATE_NICKNAME_ERROR: 'REGENERATE_NICKNAME_ERROR',
  PICK_NICKNAME: 'PICK_NICKNAME',
  QUALIFY_LEAD: 'QUALIFY_LEAD',
  QUALIFY_LEAD_SUCCESS: 'QUALIFY_LEAD_SUCCESS',
  LOAD_TEST: 'LOAD_TEST',
  LOAD_TEST_SUCCESS: 'LOAD_TEST_SUCCESS',
  LOAD_TEST_ERROR: 'LOAD_TEST_ERROR',
  LOAD_TESTS: 'LOAD_TESTS',
  LOAD_TESTS_SUCCESS: 'LOAD_TESTS_SUCCESS',
  LOAD_TESTS_ERROR: 'LOAD_TESTS_ERROR',
  SET_SECTION: 'SET_SECTION',
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
  POST_ANSWER: 'POST_ANSWER',
  POST_GROUP_ANSWER: 'POST_GROUP_ANSWER',
  POST_ANSWER_ERROR: 'POST_ANSWER_ERROR',
  NEXT_QUESTION: 'NEXT_QUESTION',
  PREV_QUESTION: 'PREV_QUESTION',
  CLOSE_TEST: 'CLOSE_TEST',
  CLOSE_TEST_ERROR: 'CLOSE_TEST_ERROR',
  BUMP_TEST_DURATION: 'BUMP_TEST_DURATION',
  REFRESH_RESULTS: 'REFRESH_RESULTS',
  LOAD_PROFILE: 'LOAD_PROFILE',
  LOAD_PROFILE_SUCCESS: 'LOAD_PROFILE_SUCCESS',
  LOAD_PROFILE_ERROR: 'LOAD_PROFILE_ERROR',
  ALLOW_TRANSITIONS: 'ALLOW_TRANSITIONS',
  PREVENT_TRANSITIONS: 'PREVENT_TRANSITIONS',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  LOGIN_EMAIL: 'LOGIN_EMAIL',
  LOGIN_EMAIL_SUCCESS: 'LOGIN_EMAIL_SUCCESS',
  LOGIN_EMAIL_ERROR: 'LOGIN_EMAIL_ERROR',
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',
  INVITE_USER: 'INVITE_USER',
  REMIND_USER: 'REMIND_USER',
  INVITE_FINISHED: 'INVITE_FINISHED',
  POST_ACHIEVEMENT: 'POST_ACHIEVEMENT',
  POST_ACHIEVEMENT_SUCCESS: 'POST_ACHIEVEMENT_SUCCESS',
  POST_ACHIEVEMENT_ERROR: 'POST_ACHIEVEMENT_ERROR',
  RELOAD_USER: 'RELOAD_USER',
  RELOAD_USER_SUCCESS: 'RELOAD_USER_SUCCESS',
  RELOAD_USER_ERROR: 'RELOAD_USER_ERROR',
};
