import React from 'react';
import { useSelector } from 'react-redux';
import Answers from './TestAnswers';

export default (props) => {
  const testData = useSelector(state => state.test.data);

  if (!testData) {
    return (<div />);
  }

  const { question, groupIndex } = props; // also available: index

  const {
    questionSupertext,
    questionText,
    questionImage,
    layout,
    type,
  } = question;

  return (
    <div className={`question-card ${type === 'text_multi_select' ? 'list' : ''}`}>
      {type === 'text_multi_select' && (
        <div className="list-wrapper">
          <div className="question-title left">{questionText}</div>
          <Answers
            isGroup
            parentQuestion={question}
            groupIndex={groupIndex}
          />
        </div>
      )}
      {type !== 'text_multi_select' && (
        <div className="other-content">
          <div className="question-wrapper">
            {layout === 'tag' && (
              <div>
                <div className="question-title">{questionSupertext}</div>
                <div className="question-title tag">{questionText}</div>
              </div>
            )}
            {layout !== 'tag' && (
              <div>
                <div className="question-title top">{questionSupertext}</div>
                <div className="question-title bottom">{questionText}</div>
              </div>
            )}
            {questionImage && (
              <div className="exp-img">
                <img src={`images/tests/${questionImage}`} width="268" alt="" />
              </div>
            )}
          </div>
          <Answers
            isGroup
            parentQuestion={question}
            groupIndex={groupIndex}
          />
        </div>
      )}
    </div>
  );
};
