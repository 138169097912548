import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  const {
    navbarVisible,
    onShowSignup,
    onShowLogin,
    onShowMenu,
    onHideMenu,
    whyRef,
    highlightsRef,
    insOutsRef,
  } = props;

  const scrollToRef = ref => () => {
    onHideMenu();
    window.scrollTo(0, ref.current.offsetTop);
  };

  return (
    <div className={`lpnav-bar-scroll ${navbarVisible ? 'shown' : 'hidden'}`}>
      <div className="lpnav-bar lpnav">
        <Link to="/" className="nav-logo black w-inline-block" />
        <div className="nav-links">
          <button type="button" onClick={scrollToRef(whyRef)} className="nav-link">
            WHY NORTH
          </button>
          <button type="button" onClick={scrollToRef(highlightsRef)} className="nav-link">
            HIGHLIGHTS
          </button>
          <button type="button" onClick={scrollToRef(insOutsRef)} className="nav-link">
            Ins &amp; Outs
          </button>
          <Link to="/tests" className="nav-link">
            North 360
          </Link>
          <button
            type="button"
            onClick={onShowSignup}
            className="nav-link register"
            data-ix="show-signup"
          >
            Join Us
          </button>
          <button
            type="button"
            onClick={onShowLogin}
            className="nav-link register login"
            data-ix="show-login"
          >
            Log In
          </button>
          <button
            type="button"
            onClick={onShowMenu}
            className="nav-menu-dark w-inline-block"
            data-ix="mob-show"
          />
        </div>
      </div>
    </div>
  );
};
