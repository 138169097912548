/* eslint-disable import/no-cycle */
import React from 'react';

import TestInfo from '../TestInfo';
import { ExpData } from '../../../constants/testData';

export default () => (
  <TestInfo
    title={ExpData.title}
    icon={ExpData.iconSmall}
    description={ExpData.description}
    completionTime={`${ExpData.completionTime} min`}
    tips={ExpData.tips}
    modal={ExpData.whyModal}
    slug={ExpData.slug}
    color={ExpData.color}
  />
);
