import axios from 'axios';

export default class API {
  static call(method, endpoint, params, token) {
    return axios({
      method,
      url: `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      data: params,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static getProfile(nickname) {
    return API.call('get', `user/${nickname}`, {});
  }

  static getUser(token) {
    return API.call('get', 'user', {}, token);
  }

  static emailSignup(email, password, attributionData) {
    return API.call('post', 'auth/signup', { email, password, attributionData });
  }

  static emailLogin(email, password) {
    return API.call('post', 'auth/login', { email, password });
  }

  static regenerateUserNickname(token) {
    return API.call('put', 'user/regenerate-nickname', {}, token);
  }

  static pickUserNickname(token, timezone) {
    return API.call('put', 'user/pick-nickname', { timezone }, token);
  }

  static qualifyLead(token, profession, northObjective) {
    return API.call('put', 'user/lead-qualification', { profession, northObjective }, token);
  }

  static setPermissions(profileUpdatesComm, opportunitiesComm, token) {
    return API.call('put', 'user/set-permissions', {
      profileUpdatesComm,
      opportunitiesComm,
    }, token);
  }

  static getTests(token) {
    return API.call('get', 'certification/all', {}, token);
  }

  static getUsers(token) {
    return API.call('get', 'admin/all-users', {}, token);
  }

  static inviteUser(userId, token) {
    return API.call('post', 'admin/invite', { userId }, token);
  }

  static remindUserInvite(userId, token) {
    return API.call('post', 'admin/invite-reminder', { userId }, token);
  }

  static getTest(testId, token) {
    return API.call('get', `certification/${testId}`, {}, token);
  }

  static postAnswers(currentResultsId, currentQuestionId, currentAnswers, token) {
    return API.call('post', 'answer', {
      question: currentQuestionId,
      result: currentResultsId,
      answers: currentAnswers.map(a => ((typeof a === 'object')
        ? a
        : { _id: a })),
    }, token);
  }

  static closeTest(testId, token) {
    return API.call('post', `certification/${testId}/completed`, {}, token);
  }

  static bumpTestDuration(testId, token) {
    return API.call('post', `certification/${testId}/bump-duration`, {}, token);
  }

  static postAchievement(achievement, token) {
    return API.call('post', 'achievement', achievement, token);
  }
}
