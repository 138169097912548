import React from 'react';

export default (props) => {
  const {
    onShowSignup,
  } = props;

  return (
    <div id="join" className="profile-wrapper">
      <div className="profile-col">
        <div className="profile-content">
          <div className="main-title">READY TO JOIN?</div>
          <div className="_360-subtitle">Create your NORTH 360 Profile!</div>
          <div className="profile-info">
            <div className="profile-info-copy">
              <div className="div-title-wrapper join">
                <div className="title-div join" />
                <div className="col-txt dark">
                  In just 40-50 minutes, this tool allows you to assess your
                  experience, cognitive and emotional ability as well as
                  culture and values.
                </div>
              </div>
              <div className="col-label join">
                This is Stage 1 of your Telepat North experience and mandatory
                for being considered for Stage 2.
              </div>
            </div>
            <div className="profile-info-btns">
              <button
                type="button"
                onClick={onShowSignup}
                className="btn-fill join w-inline-block"
                data-ix="show-signup"
              >
                <div>Create profile</div>
              </button>
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}/#/p/the-calculating-line`}
                target="_blank"
                rel="noreferrer noopener"
                className="btn-outline dark sample w-inline-block"
              >
                <div>View Sample Profile</div>
              </a>
            </div>
          </div>
        </div>
        <div className="profile-img">
          <div className="dev-wrapper">
            <img
              src="images/lp/dev.svg"
              className="dev"
              alt=""
              data-w-id="0e3be2af-4b10-2ed3-99b3-afb379a48129"
              style={{
                WebkitTransform:
                  'translate3d(0, -40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                MozTransform:
                  'translate3d(0, -40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                msTransform:
                  'translate3d(0, -40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                transform:
                  'translate3d(0, -40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              }}
            />
            <img
              src="images/lp/dev_shadow.svg"
              className="dev-shadow"
              alt=""
              data-w-id="126bcb76-8cf7-cd9f-b3c2-4964349b777f"
              style={{
                WebkitTransform:
                  'translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                MozTransform:
                  'translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                msTransform:
                  'translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                transform:
                  'translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
