import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default (props) => {
  const {
    highlightsRef,
    onParticlesChanged,
  } = props;

  return (
    <VisibilitySensor onChange={onParticlesChanged} partialVisibility>
      <div id="highlights" ref={highlightsRef} className="highlights-wrapper">
        <div className="stage-highlights">
          <div className="stage-content">
            <div className="main-title light">HIGHLIGHTS</div>
            <div className="stage-number">STAGE 1</div>
            <div className="col-header">
              Your North 360 Professional Profile
            </div>
            <div className="col-list-title">HIGHLIGHTS:</div>
            <div className="columns">
              <div className="col-left">
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Available to all members that sign-up.
                    </div>
                    <div className="col-label">
                      100% free; we keep your data safe &amp; private.
                    </div>
                  </div>
                </div>
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Access to our unique set of tests that offer a 360 degree
                      evaluation of your current experience and cognitive
                      skills.
                    </div>
                  </div>
                </div>
                <div className="stage-list last">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Continuous access to our educational content series on
                      {' '}
                      <a
                        href="https://www.youtube.com/channel/UCehgPeVnbdWf4or-ywsSkSw"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="col-txt-link"
                      >
                        Youtube
                      </a>
                      {' '}
                      and
                      {' '}
                      <a
                        href="https://medium.com/north-code"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="col-txt-link"
                      >
                        Medium
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-right icons">
                <div className="stage-1-icons">
                  <img
                    src="images/lp/icon_test_01.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_test_02.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_test_03.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_test_04.svg"
                    alt=""
                    className="stage-icon"
                  />
                </div>
                <div className="col-label">
                  Stage 1 of your Telepat North experience is mandatory for
                  being considered for Stage 2.
                </div>
              </div>
            </div>
          </div>
          <div className="stage-div" />
          <div className="stage-content">
            <div className="stage-number light">STAGE 2</div>
            <div className="col-header">
              North Membership &amp; Acceleration Experience
            </div>
            <div className="col-list-title">HIGHLIGHTS:</div>
            <div className="columns">
              <div className="col-left">
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Available to selected members.
                    </div>
                    <div className="col-label">
                      We interview and select roughly 10 new members every
                      month, all year round.
                    </div>
                  </div>
                </div>
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Includes a 6 month personal development program that is
                      tailored to your goals.
                    </div>
                  </div>
                </div>
                <div className="stage-2-icons">
                  <img
                    src="images/lp/icon_membership_02.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_membership_01.svg"
                    alt=""
                    className="stage-icon"
                  />
                </div>
              </div>
              <div className="col-right">
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      Includes access to e-books and courses of your choosing.
                    </div>
                    <div className="col-label">
                      You choose the titles – we gift them to you.
                    </div>
                  </div>
                </div>
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      You’ll receive a Mystery Box packed with swag and cool
                      perks.
                    </div>
                  </div>
                </div>
                <div className="stage-2-icons right">
                  <img
                    src="images/lp/icon_membership_03.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_membership_04.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_membership_05.svg"
                    alt=""
                    className="stage-icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="stage-div" />
          <div className="stage-content">
            <div className="stage-number">STAGE 3</div>
            <div className="col-header">
              North Exclusive Career Opportunities
            </div>
            <div className="col-list-title">HIGHLIGHTS:</div>
            <div className="columns">
              <div className="col-left">
                <div className="stage-list">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      We work together towards finding a cool position with an
                      awesome company – think high growth startups or scaleups
                      in Silicon Valley or other technology hubs across the
                      world.
                    </div>
                  </div>
                </div>
                <div className="col-label stage-3">
                  This stage is optional and as the rest of your experience with
                  Telepat North, 100% free.
                </div>
              </div>
              <div className="col-right">
                <div className="stage-list last">
                  <div className="col-list-bullet" />
                  <div className="stage-list-txt-wrapper">
                    <div className="col-txt">
                      We are preachers of remote work and that’s our  focus on –
                      however if you are looking to travel we can find a way.
                    </div>
                  </div>
                </div>
                <div className="stage-3-icons">
                  <img
                    src="images/lp/icon_career_01.svg"
                    alt=""
                    className="stage-icon"
                  />
                  <img
                    src="images/lp/icon_career_02.svg"
                    alt=""
                    className="stage-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};
