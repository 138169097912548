import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back">
        <div className="nav-back-box">
          <Link to="/tests" className="nav-back-btn w-inline-block" />
          <div className="nav-label red">General Mental Ability</div>
        </div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="test-example">
          <div className="col-wrapper">
            <div className="ex-header-labels">
              <div>Sub-test 1/4</div>
              <div>Time limit:</div>
            </div>
            <div className="ex-header">
              <div className="title">Sequences</div>
              <div className="time">
                <img src="images/icon_time.png" width="12" alt="" className="time-icon blue" />
                <div className="time-txt blue">3 minutes</div>
              </div>
            </div>
            <p className="body-txt">
              In the first subtest, you’ll be presented with an incomplete, progressive series.
              <br />
              <br />
              Your task is to select, from among the choices provided, the answer which best
              continues the series. This is a measure of sequential thinking.
            </p>
            <div className="test-tips example mob">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">What comes next?</div>
              <img src="images/tests/sequences_ex.png" width="264" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/sequences_ex_answers.png" width="240" alt="" />
            </div>
            <Link to="/tests/gma/section/1/run" className="button outline w-inline-block">
              <div>Begin the sub-test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="test-tips example">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">What comes next?</div>
              <img src="images/tests/sequences_ex.png" width="264" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/sequences_ex_answers.png" width="240" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
