import actions from '../constants/actions';

export default (state = {
  loading: false,
  allowTransitions: true,
  error: null,
  data: null,
  answers: null,
  groupAnswers: null,
  currentQuestionIndex: null,
  currentSectionIndex: null,
}, action) => {
  switch (action.type) {
    case actions.LOAD_TEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
        answers: null,
        currentQuestionIndex: null,
        currentSectionIndex: null,
      };

    case actions.LOAD_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
        answers: [],
        currentQuestionIndex: 0,
        currentSectionIndex: 0,
      };

    case actions.LOAD_TEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      };

    case actions.SET_SECTION:
      if (action.sectionIndex === state.currentSectionIndex) {
        return state;
      }
      return {
        ...state,
        currentSectionIndex: action.sectionIndex,
        allowTransitions: false,
        currentQuestionIndex: 0,
      };

    case actions.POST_ANSWER: {
      const newAnswers = state.answers ? [...state.answers] : [];
      const groupAnswers = state.groupAnswers ? [...state.groupAnswers] : [];
      let newCurrentQuestionAnswers = newAnswers[state.currentQuestionIndex] || [];

      if (action.value) {
        newCurrentQuestionAnswers = [{ value: action.value }];
      } else if (typeof action.answerId === 'string') {
        if (
          !newCurrentQuestionAnswers.find(a => a === action.answerId)
        ) {
          newCurrentQuestionAnswers.push(action.answerId);
        } else {
          newCurrentQuestionAnswers = newCurrentQuestionAnswers.filter(a => a !== action.answerId);
        }
      } else if (Array.isArray(action.answerId)) {
        newCurrentQuestionAnswers = action.answerId;
      }

      newAnswers[state.currentQuestionIndex] = [...newCurrentQuestionAnswers];

      if (action.isGroup) {
        const groupIndex = action.groupIndex.toString();
        const qIndex = state.currentQuestionIndex.toString();

        if (!groupAnswers[groupIndex]) {
          groupAnswers[groupIndex] = [];
        }
        groupAnswers[groupIndex][qIndex] = [...newCurrentQuestionAnswers];
      }

      return {
        ...state,
        answers: newAnswers,
        groupAnswers,
      };
    }

    case actions.POST_ANSWER_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case actions.SET_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestionIndex:
          action.index,
      };

    case actions.PREV_QUESTION:
      return {
        ...state,
        currentQuestionIndex:
          state.currentQuestionIndex === 0 ? 0 : state.currentQuestionIndex - 1,
      };

    case actions.NEXT_QUESTION:
      return {
        ...state,
        currentQuestionIndex:
          state.currentQuestionIndex
            === (state.data.sections[state.currentSectionIndex].questions.length - 1)
            ? state.currentQuestionIndex : state.currentQuestionIndex + 1,
      };

    case actions.CLOSE_TEST:
      return {
        loading: false,
        error: null,
        data: null,
        answers: null,
        currentQuestionIndex: null,
        currentSectionIndex: null,
      };

    case actions.ALLOW_TRANSITIONS:
      return {
        ...state,
        allowTransitions: true,
      };

    case actions.PREVENT_TRANSITIONS:
      return {
        ...state,
        allowTransitions: false,
      };

    default:
      return state;
  }
};
