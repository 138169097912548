import React from 'react';

export default ({ narrow }) => (
  <div className="footer">
    <div className={`footer-wrapper ${narrow ? 'narrow' : ''}`}>
      <div className="footer-txt">© 2020 Telepat</div>
      <div className="footer-contact">
        <img src="images/footer_contact.png" width="20" alt="" className="footer-contact-icon" />
        <a href="mailto:founders@telepat.io" className="footer-link">founders@telepat.io</a>
      </div>
    </div>

  </div>
);
