import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import actions from '../../../constants/actions';
import { getUserError } from '../../../selectors/user';

export default (props) => {
  const {
    signupOpen,
    onHideSignup,
    onSwitchToLogin,
    handleGithubRequest,
    history,
  } = props;
  const [captchaToken, setCaptchaToken] = useState('');
  const authError = useSelector(getUserError);
  const dispatch = useDispatch();

  const signupEmailRef = useRef(null);
  const signupPasswordRef = useRef(null);

  const onRequestSignup = (e) => {
    e.preventDefault();
    const email = signupEmailRef.current.value;
    const password = signupPasswordRef.current.value;
    dispatch({
      type: actions.SIGNUP, email, password, history, captchaToken,
    });
  };
  const onCaptchaChanged = (param) => {
    setCaptchaToken(param);
  };

  return (
    <div className={`sign-up-modal ${signupOpen ? 'shown' : 'hidden'}`}>
      <div className="reg-card">
        <div className="reg-header">
          <div className="reg-card-title">Join the Club</div>
          <button
            data-w-id="a7292314-4091-4db9-f016-6101af869d48"
            type="button"
            onClick={onHideSignup}
            className="mob-menu-close reg w-inline-block"
          />
        </div>
        <div className="reg-div" />
        <div className="reg-git">
          <button type="button" onClick={handleGithubRequest} className="git-group">
            <img src="images/lp/icon_git.svg" alt="" className="git-icon" />
            <div className="reg-header-txt git">SIGN UP with GITHUB</div>
          </button>
          <button
            type="button"
            onClick={handleGithubRequest}
            className="reg-btn w-inline-block"
          >
            <div>Quick Sign Up</div>
            <img
              src="images/lp/double_arrows.png"
              width="15"
              alt=""
              className="quick-arrows"
            />
          </button>
        </div>
        <form className="email-reg" onSubmit={onRequestSignup}>
          <div className="reg-header-txt email">OR USE YOUR email</div>
          {authError ? (
            <div className="reg-notification">
              <div className="reg-notification-txt">{authError}</div>
            </div>
          ) : null}
          <div className="reg-input-wrapper">
            <div className="reg-input">
              <div className="reg-input-label">
                <div className="reg-label-txt">Email</div>
              </div>
              <div className="reg-input-field">
                <input
                  type="text"
                  name="email"
                  ref={signupEmailRef}
                  placeholder="Enter email"
                  className="reg-input-txt"
                  autoComplete="username"
                />
              </div>
            </div>
            <div className="reg-input pass">
              <div className="reg-input-label">
                <div className="reg-label-txt">Password</div>
              </div>
              <div className="reg-input-field pass">
                <input
                  type="password"
                  name="password"
                  ref={signupPasswordRef}
                  placeholder="Enter password"
                  className="reg-input-txt"
                  autoComplete="new-password"
                />
              </div>
            </div>
          </div>
          <div className="reg-btn-wrapper">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChanged}
            />
            <div className="reg-btn-group signup-btn-group">
              <button
                type="submit"
                onClick={onRequestSignup}
                className="reg-btn outline w-inline-block"
              >
                <div>Sign Up</div>
              </button>
              <div className="reg-options">
                <div className="reg-label">Already have an account?</div>
                <button
                  type="button"
                  onClick={onSwitchToLogin}
                  className="reg-link"
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
