module.exports = {
  CODE_SHOWCASE: 'CODE_SHOWCASE',
  INTRO_VIDEO: 'INTRO_VIDEO',
  WORKSHOP_ATTENDANCE: 'WORKSHOP_ATTENDANCE',
  ASSESSMENT: 'ASSESSMENT',
  OSS_PROJECT: 'OSS_PROJECT',
  OSS_CONTRIBUTION: 'OSS_CONTRIBUTION',
  CONTENT: 'CONTENT',
  STACK_OVERFLOW: 'STACK_OVERFLOW',
};
