import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ReactLoading from 'react-loading';
import * as values from 'object.values';

import TestGroupFooter from './TestGroupFooter';
import Question from './GroupQuestion';
import actions from '../../constants/actions';
import { getCurrentSection, getAllQuestions, getAllowTransitions } from '../../selectors/test';

import * as allTestData from '../../constants/testData';

export default ({ match: { params: { type, section, groupStartIndex } } }) => {
  const loading = useSelector(state => state.user.loading || state.test.loading);
  const error = useSelector(state => state.test.error);
  const testData = useSelector(state => state.test.data);
  const tests = useSelector(state => state.tests.data);
  const sectionData = useSelector(getCurrentSection);
  const allQuestions = useSelector(getAllQuestions);
  const allowTransitions = useSelector(getAllowTransitions);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();

  const dispatch = useDispatch();

  if (!Object.values) {
    values.shim();
  }

  dispatch({ type: actions.SET_SECTION, sectionIndex: parseInt(section, 10) - 1 });

  useEffect(() => {
    const loadTest = testType => dispatch({ type: actions.LOAD_TEST, testType });

    if (tests) {
      loadTest(
        tests
          .find(
            test => test.type === Object.values(allTestData).find(t => t.slug === type).type,
          )
          ._id,
      );
    } else {
      dispatch({ type: actions.LOAD_TESTS });
    }
    // TODO check for better practices
    // eslint-disable-next-line
  }, [type, tests]);

  if (
    sectionData
    && sectionData.questionGroups
    && sectionData.questionGroups.length
    && !questionGroups.length
  ) {
    setQuestionGroups(sectionData.questionGroups.map((g, index) => ({
      ...g,
      index,
    })));
  }

  let nextGroup;

  if (questionGroups
        && groupQuestions
        && allQuestions
        && questionGroups.length && allQuestions.length && !groupQuestions.length) {
    const startIndex = parseInt(groupStartIndex, 10);
    let endIndex;

    const group = questionGroups.find(g => g.startIndex === startIndex);
    if (group) {
      // setQuestionGroups(questionGroups.filter(g => g.startIndex !== group.startIndex));
      // setQuestionGroupCardOpen({ title: group.title, index: group.index + 1 });
      nextGroup = questionGroups[group.index + 1];
      if (nextGroup) {
        endIndex = nextGroup.startIndex;
        group.groupLength = endIndex - startIndex;
      } else {
        endIndex = allQuestions.length;
        group.groupLength = endIndex - startIndex;
      }

      setCurrentGroup(group);
      setGroupQuestions(allQuestions.slice(startIndex - 1, endIndex - 1));
    }
  }

  if (loading || !testData || !currentGroup) {
    return (
      <div className="full-bg">
        <div className="content-wrapper">
          <ReactLoading type="bars" color="#95A2B8" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  const questions = groupQuestions.map(question => (
    <Question
      key={question.index}
      index={question.index - currentGroup.startIndex + 1}
      groupIndex={currentGroup.startIndex}
      question={question}
    />
  ));

  return (
    <div>
      <div className="nav-bar">
        <div className="nav-bar-back exp">
          <div className="nav-label">{currentGroup.title}</div>
        </div>
      </div>
      <div className="page-wrapper exp-test">
        <div className="page-content">
          { questions }
        </div>
      </div>
      <TestGroupFooter
        groupLength={currentGroup.groupLength}
        startIndex={currentGroup.startIndex}
        section={section}
      />
      <Prompt
        when={!allowTransitions}
        message="Leaving this page will reset your progress. Are you sure?"
      />
    </div>
  );
};
