import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserData } from '../../selectors/user';
import Settings from './Settings';

export default () => {
  const userData = useSelector(getUserData);
  const [modalView, setModalView] = useState(false);

  return (
    <div className="nav-bar">
      {modalView && (
        <div className="modal-overlay about">
          <div className="modal">
            <div className="modal-header">
              <div className="modal-title">{modalView.title}</div>
              <button
                type="button"
                onClick={() => setModalView(false)}
                className="modal-close w-inline-block"
              />
            </div>
            <div className="modal-content">
              {modalView.content}
            </div>
          </div>
        </div>
      )}
      <div className="nav-bar-logo">
        <Link
          to="/tests"
          className="nav-logo-box"
        >
          <div className="logo-telepat w-inline-block w--current" />
          <div className="logo-divider" />
        </Link>
        {userData && (
          <button
            type="button"
            onClick={() => setModalView({
              title: 'Account settings',
              content: <Settings />,
            })}
            className="nav-settings-btn w-inline-block"
          />
        )}
      </div>
    </div>
  );
};
