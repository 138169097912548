/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default class API {
  static track() {
    const utmSource = getParameterByName('tlpt_source') || getParameterByName('utm_source');
    const utmCampaign = getParameterByName('tlpt_campaign') || getParameterByName('utm_campaign');
    const utmMedium = getParameterByName('tlpt_medium') || getParameterByName('utm_medium');
    const gclid = getParameterByName('gclid');
    const referrer = getParameterByName('_p');

    if (utmSource) {
      cookies.set('utm_source', utmSource, { path: '/' });
    }

    if (utmCampaign) {
      cookies.set('utm_campaign', utmCampaign, { path: '/' });
    }

    if (utmMedium) {
      cookies.set('utm_medium', utmMedium, { path: '/' });
    }

    if (gclid) {
      cookies.set('gclid', gclid, { path: '/' });
    }

    if (referrer) {
      cookies.set('referrer', referrer, { path: '/' });
    }
  }

  static get() {
    const utmSource = cookies.get('utm_source');
    const utmCampaign = cookies.get('utm_campaign');
    const utmMedium = cookies.get('utm_medium');
    const gclid = cookies.get('gclid');
    const referrer = cookies.get('referrer');

    return {
      utmSource, utmCampaign, utmMedium, gclid, referrer,
    };
  }
}
