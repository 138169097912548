import React from 'react';

import OrgCard from '../../components/Tests/Culture/Card';
import OrgWhyModal from '../../components/Tests/Culture/WhyModal';
import OrgInfo from '../../components/Tests/Culture/Info';
import OrgSectionIntro from '../../components/Tests/Culture/SectionIntro';
import OrgDone from '../../components/Tests/Culture/Done';
import OrgResult from '../../components/Tests/Culture/Result';

import { SCHNEIDER } from '../testTypes';

export default {
  type: SCHNEIDER,
  slug: 'org',
  title: 'Organizational Culture',
  color: 'pink',
  iconSmall: 'images/test_icon_04.svg',
  iconLarge: 'images/test_icon_xl_04.svg',
  shortDescription: (
    <span>
      The test asserts
      {' '}
      <strong>4 dimensions of culture</strong>
      : reality-oriented vs possibility oriented, people-oriented vs company-oriented.
      This helps you ask the right questions about who you want to work for.
    </span>
  ),
  description: (
    <span>
      For any company, culture is basically the answer to “how we do things around
      here in order to succeed”.
      <br />
      <br />
      This test asserts 4 dimensions of culture (reality-oriented vs possibility oriented,
      people-oriented vs company-oriented) and can help you ask the right questions about
      the company you want to work for.
    </span>
  ),
  resultInfo: (nickname, resultDate, nextDate) => (
    (
      <div className="body-txt">
        We assessed&nbsp;
        {nickname}
        ’s organisational cultural preferences on&nbsp;
        {resultDate}
        , using the Schneider model, where a competing values matrix model defines 4
        different type cultures: Collaboration, Control, Cultivation, Competence.
        <br />
        <br />
        This test can be taken again starting on&nbsp;
        {nextDate}
        .
      </div>
    )
  ),
  completionTime: 8,
  tips: [
    'Pick a comfortable, quiet place to maximize your focus.',
    'You can take this test as many times you want.',
  ],
  studies: [
    {
      text: (
        <span>
          The results indicate that job satisfaction levels varied across corporate
          cultural typology.
        </span>),
      url: 'https://www.emeraldinsight.com/doi/abs/10.1108/0885862031047313',
    },
    {
      text: (
        <span>
          Leadership behavior was found to be significantly related to organizational
          commitment, and organizational culture played an important role in moderating
          this relationship.
        </span>
      ),
      url: 'https://www.emeraldinsight.com/doi/abs/10.1108/01437730910927106',
    },
    {
      text: (
        <span>
          In terms of the impact of the Person‐Org fit on job satisfaction and organizational
          commitment, the results indicate that the degree of (cultural) fit plays an important
          role in all of the types of organizational culture studied.
          <br />
          <br />
          The better the fit the higher the levels of (job satisfaction and organizational
          commitment).
        </span>
      ),
      url: 'https://www.emeraldinsight.com/doi/abs/10.1108/01437730410561477',
    },
    {
      text: (
        <span>
          Organizational cultures were significantly (positively) correlated with
          leadership behavior and job satisfaction, and leadership behavior was
          significantly (positively) correlated with job satisfaction.
        </span>
      ),
      url: 'https://bmchealthservres.biomedcentral.com/articles/10.1186/1472-6963-11-98',
    },
  ],
  breakdown: {
    control: [
      'The prototype of this culture is the military.',
      'The leadership style is dominant.',
      'The more the actions of other people can be influenced and controlled, the better.',
      'The more subordinates, the better.',
      'Markets are being taken by acquiring, not through innovation.',
      'The system is more important than people.',
      'Any person is “… like a hand in a bucket of water - when the hand is removed, the water closes in, and there is no trace.” [Schneider 2000:31]',
    ],
    collaboration: [
      'The prototype of the collaboration culture is the family.',
      'In competitive situations, it´s about our team against their team.',
      'Synergy is vital, and 2 plus 2 sums up to 5 when people are working together and support each other towards a shared goal.',
      'Positive and mutual emphasizing relationships propel the team.',
      'People feel connected and obliged to their organization - and vice versa.',
      'Attitude is “together we win, and alone we lose.”',
      'There is not much distance between top management and the workforce.',
      'If the organization is having commercial success, all people participate in financial wealth.',
      'The collaboration culture is more egalitarian than other cultures.',
      'Like an orchestra, all individuals try to behave at their best for the optimal overarching result.',
      'Excessive egoism or diva-attitude will not be tolerated.',
    ],
    competence: [
      'The prototype of the competence culture is the university.',
      'Important are expertise and advancement of knowledge.',
      'The motives are excellence, mastery, and achievement of goals.',
      'One has to be better than others, has to compete.',
      'Authority inside the organization can only be gained through expertise.',
      'Competence cultures supply their customers with the best product at a high price.',
    ],
    cultivation: [
      'The prototype of the cultivation culture is the religious community.',
      'Personal development, the exploration of own potentials, is the central motif.',
      'Values give orientation.',
      'There are not many explicit rules, but ethical behavior is essential.',
      'The functioning of the organization is ensured through mutual commitments to shared values.',
      'A member who is not trustworthy or not dedicated to the values of the organization will have to leave.',
      'On the other hand, if someone is recognized as being trustworthy but delivered a bad performance, a second chance will sure be given.',
      'Possibly in another, better-suited function with better conditions to grow.',
      'In the cultivation culture, people are oriented towards a higher level.',
      'It´s being asked for a purpose. Future is an adventure.',
      'Relationships are personal, cooperative and interactive.',
      'Communication style is open and direct.',
      'Ideas of others are enriching own ideas.',
    ],
  },
  card: OrgCard,
  whyModal: OrgWhyModal,
  infoComponent: OrgInfo,
  doneComponent: OrgDone,
  resultCard: OrgResult,
  sections: [OrgSectionIntro],
};
