import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '../../constants/actions';

export default () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.data);
  const permissions = userData ? (userData.permissions || {}) : {};

  return (
    <div>
      <div className="email-options">
        <div className="settings-title">Email preferences</div>
        <div
          className="email-check"
          role="button"
          tabIndex="0"
          onClick={() => dispatch({
            type: actions.USER_SET_PERMISSIONS,
            profileUpdatesComm: !permissions.profileUpdatesComm,
            opportunitiesComm: permissions.opportunitiesComm,
          })}
          onKeyDown={() => {}}
        >
          <div className="setting-group">
            <div className="settings-dot" />
            <div className="body-txt">Updates to my profile</div>
          </div>
          <img
            src={`images/check${permissions.profileUpdatesComm ? '_active' : ''}.png`}
            width="20"
            alt=""
            className={`check-${permissions.profileUpdatesComm ? 'on' : 'off'} settings`}
          />
        </div>
        <div
          className="email-check"
          role="button"
          tabIndex="0"
          onClick={() => dispatch({
            type: actions.USER_SET_PERMISSIONS,
            profileUpdatesComm: permissions.profileUpdatesComm,
            opportunitiesComm: !permissions.opportunitiesComm,
          })}
          onKeyDown={() => { }}
        >
          <div className="setting-group">
            <div className="settings-dot" />
            <div className="body-txt">Career opportunities</div>
          </div>
          <img
            src={`images/check${permissions.opportunitiesComm ? '_active' : ''}.png`}
            width="20"
            alt=""
            className={`check-${permissions.opportunitiesComm ? 'on' : 'off'} settings`}
          />
        </div>
      </div>
      <div className="divider" />
      <div className="settings-item">
        <div className="settings-title">Export your data</div>
        <a
          href={
            `mailto:gabi@telepat.io?subject=Please%20send%20me%20my%20data&body=User%20id%3A${userData._id}`
          }
          className="settings-export w-inline-block"
        >
          &nbsp;
        </a>
      </div>
      <div className="divider" />
      <div className="settings-item">
        <div className="settings-title">Log out</div>
        <button
          type="button"
          onClick={() => dispatch({ type: actions.LOGOUT })}
          className="settings-log-out w-inline-block"
        />
      </div>
      <div className="divider" />
      <div className="settings-item">
        <div className="settings-title">Delete account</div>
        <a
          href={
            `mailto:gabi@telepat.io?subject=Please%20delete%20my%20account&body=User%20id%3A${userData._id}`
          }
          className="settings-delete w-inline-block"
        >
          &nbsp;
        </a>
      </div>
    </div>
  );
};
