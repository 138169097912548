import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import ReactTooltip from 'react-tooltip';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

import Footer from './Footer';
import Header from './Header';
import CodeShowcase from '../Achievements/CodeShowcase';
import IntroVideo from '../Achievements/IntroVideo';
import SkillAssessments from '../Achievements/SkillAssessments';
import Achievements from '../Achievements/Achievements';

import * as allTestData from '../../constants/testData';
import actions from '../../constants/actions';
import { getNickname } from '../../selectors/profile';
import { getUserData } from '../../selectors/user';
import { nextTestTakeDate } from '../../constants/testManagement';
import {
  CCFIT, SREIS, SCHNEIDER, IT_EXPERIENCE,
} from '../../constants/testTypes';

export default (props) => {
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { match: { params: { nickname } } } = props;
  const { loading, data: profileData, error } = useSelector(state => state.profile);
  const {
    loading: testsLoading,
    data: testData,
    error: testError,
  } = useSelector(state => state.tests);
  const displayNickname = useSelector(getNickname);
  const userData = useSelector(getUserData);
  const profileIsLoggedInUser = userData && userData.nickname === nickname;
  const copyLink = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 2000);
  };

  useEffect(() => {
    if (
      !error
      && !loading
      && (!profileData || profileData.nickname !== nickname)
    ) {
      dispatch({ type: actions.LOAD_PROFILE, nickname });
    }
    if (
      !testError
      && !testData
      && !testsLoading
    ) {
      dispatch({ type: actions.LOAD_TESTS });
    }
  });

  if (!profileData || !testData) {
    return (
      <div className="full-bg">
        <div className="content-wrapper">
          <ReactLoading type="bars" color="#95A2B8" />
        </div>
      </div>
    );
  }

  const results = testData.map((td) => {
    const reactTestData = Object.values(allTestData).find(t => t.type === td.type);

    return {
      data: profileData.recentResults.find(r => r.type === td.type),
      card: reactTestData.resultCard,
      type: td.type,
      title: reactTestData.title,
      modal: reactTestData.whyModal,
      resultInfo: reactTestData.resultInfo,
      repeatAfterDays: td.repeatAfterDays,
    };
  });

  const displayTestResult = result => (
    <result.card
      key={`${result.type}_d`}
      result={result.data}
      nickname={displayNickname}
      onShowInfo={() => setModalOpen({
        title: result.title,
        content: (
          <result.modal
            result={{
              content: result.data ? result.resultInfo(
                displayNickname,
                moment(result.data.createdAt)
                  .format('MMM Do, YYYY, HH:mm A'),
                moment(
                  nextTestTakeDate(result.data.createdAt, result.repeatAfterDays),
                )
                  .format('MMM Do, YYYY'),
              ) : null,
              duration: result.data ? result.data.duration : null,
            }}
          />
        ),
      })}
    />
  );

  return (
    <div>
      {tooltipVisible && (
        <div className="link-copied-alert">
          <img src="images/check_icon_white.svg" alt="" className="copy-link-check" />
          <div>Link Copied</div>
        </div>
      )}
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <div className="modal-title">{modalOpen.title}</div>
              <button type="button" onClick={() => setModalOpen(false)} className="modal-close w-inline-block" />
            </div>
            {modalOpen.content}
          </div>
        </div>
      )}
      <Header />
      <div className="page-wrapper gray">
        <div className="page-content">
          <div className="username-header preview">
            <div className="username-group">
              <img
                src={`images/avatars/avatar_${profileData.avatar[0]}.png`}
                alt=""
                style={{
                  width: 90,
                  backgroundColor: profileData.avatar[1],
                  borderRadius: 45,
                }}
              />
              <div className="username-info">
                <div className="username-txt">{displayNickname}</div>
                {profileData.flairs && (
                  <div className="username-flairs">
                    {profileData.flairs.map(flair => (
                      <div className="username-flair" key={flair}>{flair}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {profileIsLoggedInUser && (
              <div className="username-actions">
                <textarea
                  ref={textAreaRef}
                  style={{ position: 'absolute', left: -1000, top: -1000 }}
                  readOnly
                  value={`${process.env.REACT_APP_FRONTEND_URL}/#/p/${nickname}`}
                />
                <div className="share-btn-group">
                  <div className="share-link-label">Share profile link</div>
                  <button type="button" className="copy-link-btn w-inline-block" onClick={copyLink} />
                </div>
                <button type="button" className="share-btn-mob w-inline-block" onClick={copyLink}>
                  <div>Share profile link</div>
                </button>
              </div>
            )}
          </div>
          {profileData.bio && (
            <div>
              <div className="username-bio-bar-top" />
              <div className="username-bio">
                <div className="username-bio-bar" />
                <ReactMarkdown>
                  {profileData.bio}
                </ReactMarkdown>
              </div>
            </div>
          )}
          <div>
            <IntroVideo bottomSeparator publicProfile />
            <CodeShowcase bottomSeparator publicProfile />
            {displayTestResult(results.find(r => r.type === IT_EXPERIENCE))}
            <div className="profile-section-divider" />
            <div className="section-header">
              <div className="section-title">North 360 Tests</div>
              <div className="section-body-txt">
                Derived from academic and scientific scales, the North 360 Tests
                provide a wide snapshot of cognitive and emotional ability,
                as well as preferred work culture and values.
              </div>
            </div>
            {displayTestResult(results.find(r => r.type === CCFIT))}
            {displayTestResult(results.find(r => r.type === SREIS))}
            {displayTestResult(results.find(r => r.type === SCHNEIDER))}
            <SkillAssessments publicProfile bottomSeparator />
            <Achievements publicProfile />
          </div>
        </div>
      </div>
      <Footer />
      <ReactTooltip place="top" effect="solid" />
    </div>
  );
};
