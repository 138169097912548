import React from 'react';

import { SREIS } from '../../../constants/testTypes';

export default ({
  answers, currentAnswer, setAnswer, scrollRef, color, test,
}) => (
  <div className={`test-content ${test === SREIS ? '' : 'check'}`} ref={scrollRef}>
    <div className={test === SREIS ? 'ei-options' : 'exp-options org'}>
      {answers.map(answer => (
        <div
          key={answer._id}
          className={
            `${test === SREIS ? 'ei-btn' : 'test-option org'} w-inline-block ${currentAnswer[0] === answer._id ? 'selected' : ''}`
          }
          onClick={() => setAnswer([answer._id])}
          onKeyDown={() => setAnswer([answer._id])}
          role="button"
          tabIndex="0"
        >
          <img
            src={`images/icon_radio${currentAnswer[0] === answer._id ? `_active_${color}.svg` : '.png'}`}
            width="20"
            alt=""
            className={currentAnswer[0] === answer._id ? 'radio-on' : 'radio-off'}
          />
          <div>{answer.text}</div>
        </div>
      ))}
    </div>
  </div>
);
