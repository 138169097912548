import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentQuestion } from '../../selectors/test';

export default () => {
  const testData = useSelector(state => state.test.data);

  if (!testData) {
    return (<div />);
  }

  const {
    questionSupertext, questionText,
    questionSubtext, questionImage,
  } = useSelector(getCurrentQuestion);

  return (
    <div className="test-header">
      <div className="question-wrapper">
        {questionSupertext && (
          <div className="question-title top">{questionSupertext}</div>
        )}
        <div className={`question-title ${questionSupertext ? 'bottom' : ''}`}>
          {questionText}
        </div>
        {questionSubtext && (
          <div className="question-title tag">{questionSubtext}</div>
        )}
        {questionImage && (
          <div className="question-image">
            <img src={`images/tests/${questionImage}`} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
