import * as values from 'object.values';

import { nextTestTakeDate, nextTestTakeDuration } from '../constants/testManagement';

export const getTestsData = state => state.tests.data || [];

export const getTestsAvailability = state => (state.tests.data || []).reduce((a, test) => {
  const result = state.user.data.recentResults.find(res => res.type === test.type);
  Object.assign(a, {
    [test.type]: (
      !result
      || result.open
    ),
  });

  return a;
}, {});

export const getTestsAvailabilityDates = state => (state.tests.data || []).reduce((a, test) => {
  const result = state.user.data.recentResults.find(res => res.type === test.type);
  if (!result) {
    Object.assign(a, {
      [test.type]: {
        lastStarted: null,
      },
    });
  } else {
    const nextTestDate = nextTestTakeDate(result.createdAt, test.repeatAfterDays);
    Object.assign(a, {
      [test.type]: {
        lastStarted: result.createdAt,
        repeatInterval: test.repeatAfterDays,
        nextTestDuration: nextTestTakeDuration(nextTestDate),
        nextTestDate,
      },
    });
  }
  return a;
}, {});

export const getTestsAvailabilityCount = (state) => {
  if (!Object.values) {
    values.shim();
  }
  return Object.values(
    getTestsAvailability(state),
  ).filter(a => !!a).length;
};
