import React from 'react';

import EICard from '../../components/Tests/EI/Card';
import EIWhyModal from '../../components/Tests/EI/WhyModal';
import EIInfo from '../../components/Tests/EI/Info';
import EISectionIntro from '../../components/Tests/EI/SectionIntro';
import EIDone from '../../components/Tests/EI/Done';
import EIResult from '../../components/Tests/EI/Result';

import { SREIS } from '../testTypes';

export default {
  type: SREIS,
  slug: 'ei',
  title: 'Emotional Intelligence',
  color: 'purple',
  iconSmall: 'images/test_icon_02.svg',
  iconLarge: 'images/test_icon_xl_02.svg',
  shortDescription: (
    <span>
      The test breaks down emotional intelligence into the following
      {' '}
      <strong>5 competencies</strong>
      : Perceiving emotion, Use of Emotion, Understanding Emotion,
      Managing Emotion in the Self and Social Management.
    </span>
  ),
  description: (
    <span>
      Higher emotional intelligence / competence is associated with benefits in four personal
      areas - better psychological &amp; physical health, higher quality relationships, and
      greater occupational success.
      <br />
      <br />
      This test breaks down emotional intelligence into 5 competencies - perceiving emotion,
      use of emotion, understanding emotion, managing emotion in the self and social management.
    </span>
  ),
  resultInfo: (nickname, resultDate, nextDate) => (
    (
      <div className="body-txt">
        We tested&nbsp;
        {nickname}
        &nbsp;for Emotional Intelligence on&nbsp;
        {resultDate}
        , with a test derived from the Self-Rated Emotional Intelligence
        Scale, a tool developed at Yale.
        <br />
        <br />
        This test can be taken again starting on&nbsp;
        {nextDate}
        .
      </div>
    )
  ),
  completionTime: 10,
  tips: [
    'Pick a comfortable, quiet place to maximize your focus.',
    'You can take this test once a year.',
  ],
  studies: [
    {
      text: (
        <span>
          Self-reported / mixed E.I. is one of the best known predictors
          of job performance.
        </span>),
      url: 'https://psycnet.apa.org/record/2014-39897-001',
    },
    {
      text: (
        <span>
          The three streams of emotional intelligence research, ability measures,
          self-and peer-report measures, and mixed-models, all predict job performance
          equally well.
          <br />
          <br />
          Moreover, all three methods of measuring emotional intelligence increment
          cognitive ability and personality measures in the prediction of job performance.
        </span>
      ),
      url: 'https://onlinelibrary.wiley.com/doi/abs/10.1002/job.714',
    },
    {
      text: (
        <span>
          Our results show that emotional intelligence is an important predictor
          of task performance and OCBO because of its interactive effect with cognitive
          intelligence.
          <br />
          <br />
          Our results also reveal that using cognitive intelligence tests alone to predict
          performance entails risk, because employees with low cognitive intelligence can
          perform effectively if they have high emotional intelligence.
        </span>
      ),
      url: 'https://journals.sagepub.com/doi/10.2189/asqu.51.1.1',
    },
  ],
  card: EICard,
  whyModal: EIWhyModal,
  infoComponent: EIInfo,
  doneComponent: EIDone,
  resultCard: EIResult,
  sections: [EISectionIntro],
};
