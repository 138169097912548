import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddAchievementModal from '../360/Modals/AddAchievementModal';
import ContentAchievementModal from '../360/Modals/ContentAchievementModal';
import OSSCreatorAchievementModal from '../360/Modals/OSSCreatorAchievementModal';
import OSSContributorAchievementModal from '../360/Modals/OSSContributorAchievementModal';
import SOAchievementModal from '../360/Modals/SOAchievementModal';
import actions from '../../constants/actions';
import achievementTypes from '../../constants/achievementTypes';
import { getAchievements } from '../../selectors/achievements';
import AchievementCard from './Cards/AchievementCard';
import { getNickname as getUserNickname } from '../../selectors/user';
import { getNickname as getProfileNickname } from '../../selectors/profile';
import StackOverflowCard from './Cards/StackOverflowCard';

export default ({ publicProfile = false }) => {
  const [achievementTypeModalVisible, setAchievementTypeModalVisible] = useState(false);
  const [stackOverflowModalVisible, setStackOverflowModalVisible] = useState(false);
  const [contentModalVisible, setContentModalVisible] = useState(false);
  const [ossCreatorModalVisible, setOssCreatorModalVisible] = useState(false);
  const [ossContributorModalVisible, setOssContributorModalVisible] = useState(false);
  const currentAchievements = useSelector(getAchievements(publicProfile))
    .filter(a => !publicProfile || a.status === 'validated');
  const stackOverflowAchievement = currentAchievements
    .find(a => a.type === achievementTypes.STACK_OVERFLOW);
  const generalAchievements = currentAchievements
    .filter(a => a.type !== achievementTypes.STACK_OVERFLOW);
  const nickname = useSelector(publicProfile ? getProfileNickname : getUserNickname);

  if (publicProfile && currentAchievements.length === 0) {
    return [];
  }

  const dispatch = useDispatch();

  const submitAchievement = (achievement) => {
    setStackOverflowModalVisible(false);
    setContentModalVisible(false);
    setOssCreatorModalVisible(false);
    setOssContributorModalVisible(false);
    dispatch({
      type: actions.POST_ACHIEVEMENT,
      achievement,
    });
  };

  return (
    <div className="achievements">
      {achievementTypeModalVisible && (
        <AddAchievementModal
          setAchievementTypeModalVisible={setAchievementTypeModalVisible}
          setStackOverflowModalVisible={setStackOverflowModalVisible}
          setContentModalVisible={setContentModalVisible}
          setOssCreatorModalVisible={setOssCreatorModalVisible}
          setOssContributorModalVisible={setOssContributorModalVisible}
        />
      )}
      {contentModalVisible && (
        <ContentAchievementModal
          setContentModalVisible={setContentModalVisible}
          submitAchievement={submitAchievement}
        />
      )}
      {ossCreatorModalVisible && (
        <OSSCreatorAchievementModal
          setOssCreatorModalVisible={setOssCreatorModalVisible}
          submitAchievement={submitAchievement}
        />
      )}
      {ossContributorModalVisible && (
        <OSSContributorAchievementModal
          setOssContributorModalVisible={setOssContributorModalVisible}
          submitAchievement={submitAchievement}
        />
      )}
      {stackOverflowModalVisible && (
        <SOAchievementModal
          setStackOverflowModalVisible={setStackOverflowModalVisible}
          submitAchievement={submitAchievement}
        />
      )}
      <div className="section-header">
        <div className="section-title-wrapper">
          <div className="section-title">Achievements</div>
          {!publicProfile && (
            <button
              type="button"
              className="card-btn add w-inline-block"
              onClick={() => setAchievementTypeModalVisible(true)}
            >
              <div>Add Achievement</div>
            </button>
          )}
        </div>
        <div className="section-body-txt">
          Professional&nbsp;
          <strong>achievements</strong>
          &nbsp;obtained by&nbsp;
          {nickname}
          , verified by North.
        </div>
        {!publicProfile && (
          <button
            type="button"
            className="card-btn add mob w-inline-block"
            onClick={() => setAchievementTypeModalVisible(true)}
          >
            <div>Add Achievement</div>
          </button>
        )}
      </div>
      {currentAchievements.length === 0 && (
        <div className="empty-card slim">
          <div>No achievements added yet.</div>
        </div>
      )}
      {stackOverflowAchievement && stackOverflowAchievement.data
        && <StackOverflowCard data={stackOverflowAchievement.data} />}
      {generalAchievements.length > 0 && (
        <div className="skills-wrapper">
          <div className="card-cols w-row">
            {generalAchievements.map((achievement, index) => (
              <AchievementCard achievement={achievement} index={index} key={achievement._id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
