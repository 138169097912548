const profOptions = [
  { value: 'student', label: 'A student' },
  { value: 'freelancer', label: 'Freelancing as a developer' },
  { value: 'developer', label: 'Working as a developer' },
  { value: 'non-developer', label: 'Working in a different industry' },
];

const northObjectiveOptions = [
  { value: 'community', label: 'Be part of a community' },
  { value: 'better-professional', label: 'Become a better professional' },
  { value: 'grow-and-career', label: 'Grow and get career opportunities' },
  { value: 'job', label: 'Find a better job' },
];

export {
  profOptions,
  northObjectiveOptions,
};
