import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back">
        <div className="nav-back-box">
          <Link to="/tests" className="nav-back-btn w-inline-block" />
          <div className="nav-label">Emotional Intelligence</div>
        </div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="intro-header">
          <div className="intro-bar purple" />
          <div className="title">Intro</div>
        </div>
        <div className="intro-content">
          <div className="col-wrapper">
            <div className="intro-txt-left">
              <p className="body-txt">
                The following set of items pertains to your insight into emotions.
                Describe yourself as you generally are now, not as you wish to be in the future.
                <br />
                <br />
                Answer the questions as you honestly see yourself, in relation to other people
                you know of the same sex as you are, and roughly your same age.
              </p>
              <div className="intro-txt-right mob">
                <p className="body-txt">
                  Please read each statement carefully and select the option that corresponds
                  to how inaccurately or accurately each statement describes you.
                  <br />
                  <br />
                  There are five possible responses to each statement ranging from &#x27;Very
                  inaccurate&#x27; to &#x27;Very accurate&#x27;.
                </p>
              </div>
            </div>
            <Link to="/tests/ei/section/1/run" className="button outline w-inline-block">
              <div>Begin the test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="intro-txt-right">
              <p className="body-txt">
                Please read each statement carefully and select the option that corresponds to
                how inaccurately or accurately each statement describes you.
                <br />
                <br />
                There are five possible responses to each statement ranging from &#x27;Very
                inaccurate&#x27; to &#x27;Very accurate&#x27;.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
