import React from 'react';

export default ({ index }) => (
  <div className={`section-card pending ${index % 2 ? 'right' : 'left'}`}>
    <div className="card-content-pending">
      <img src="images/placeholder__kill.svg" alt="" />
      <div className="pending-title">Hang Tight!</div>
      <div className="pending-txt">We’re evaluating your submission and we’ll update this section ASAP.</div>
      <div className="pending-tag">PENDING</div>
    </div>
  </div>
);
