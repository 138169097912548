import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../constants/actions';
import { getUserError } from '../../../selectors/user';

export default (props) => {
  const {
    loginOpen,
    onHideLogin,
    onSwitchToSignUp,
    handleGithubRequest,
    history,
  } = props;
  const authError = useSelector(getUserError);
  const dispatch = useDispatch();

  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);

  const onRequestLogin = (e) => {
    e.preventDefault();
    const email = loginEmailRef.current.value;
    const password = loginPasswordRef.current.value;
    dispatch({
      type: actions.LOGIN_EMAIL, email, password, history,
    });
  };

  return (
    <div className={`log-in-modal ${loginOpen ? 'shown' : 'hidden'}`}>
      <div className="reg-card">
        <div className="reg-header">
          <div className="reg-card-title">Welcome back</div>
          <button
            data-w-id="0856f0c3-6130-5075-9a0c-0eb0d56dae4e"
            type="button"
            onClick={onHideLogin}
            className="mob-menu-close reg w-inline-block"
          />
        </div>
        <div className="reg-div" />
        <div className="reg-git">
          <button type="button" onClick={handleGithubRequest} className="git-group">
            <img src="images/lp/icon_git.svg" alt="" className="git-icon" />
            <div className="reg-header-txt git">LOG IN WITH GITHUB</div>
          </button>
          <button type="button" onClick={handleGithubRequest} className="reg-btn w-inline-block">
            <div>Quick Log In</div>
            <img
              src="images/lp/double_arrows.png"
              width="15"
              alt=""
              className="quick-arrows"
            />
          </button>
        </div>
        <form className="email-reg" onSubmit={onRequestLogin}>
          <div className="reg-header-txt email">OR USE YOUR email</div>
          { authError ? (
            <div className="reg-notification">
              <div className="reg-notification-txt">{authError}</div>
            </div>
          ) : null }
          <div className="reg-input-wrapper">
            <div className="reg-input">
              <div className="reg-input-label">
                <div className="reg-label-txt">Email</div>
              </div>
              <div className="reg-input-field">
                <input
                  type="text"
                  name="email"
                  ref={loginEmailRef}
                  placeholder="Enter email"
                  className="reg-input-txt"
                  autoComplete="username"
                />
              </div>
            </div>
            <div className="reg-input pass">
              <div className="reg-input-label">
                <div className="reg-label-txt">Password</div>
              </div>
              <div className="reg-input-field pass">
                <input
                  type="password"
                  name="password"
                  ref={loginPasswordRef}
                  placeholder="Enter password"
                  className="reg-input-txt"
                  autoComplete="current-password"
                />
              </div>
            </div>
          </div>
          <div className="reg-btn-wrapper">
            <div className="forgot-wrapper">
              {/* <a href="forgot-pass.html" className="reg-link forgot">
                Forgot password?
              </a> */}
            </div>
            <div className="reg-btn-group">
              <button type="submit" onClick={onRequestLogin} className="reg-btn outline w-inline-block">
                <div>Log In</div>
              </button>
              <div className="reg-options">
                <div className="reg-label">Don&#x27;t have an account?</div>
                <button type="button" onClick={onSwitchToSignUp} className="reg-link">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
