import React from 'react';

import TestCard from '../TestCard';
import { GMAData } from '../../../constants/testData';

export default params => (
  <TestCard
    title={GMAData.title}
    iconSmall={GMAData.iconSmall}
    iconLarge={GMAData.iconLarge}
    shortDescription={GMAData.shortDescription}
    completionTime={GMAData.completionTime}
    slug={GMAData.slug}
    color={GMAData.color}
    {...params}
  />
);
