import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { onRequestGithubLogin } from '../../constants/utils';
import actions from '../../constants/actions';
import { getUserError } from '../../selectors/user';

export default withRouter((props) => {
  window.scrollTo(0, 0);
  const { history } = props;

  const dispatch = useDispatch();
  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);

  const authError = useSelector(getUserError);

  const onRequestLogin = (e) => {
    e.preventDefault();
    const email = loginEmailRef.current.value;
    const password = loginPasswordRef.current.value;
    dispatch({
      type: actions.LOGIN_EMAIL, email, password, history,
    });
  };

  return (
    <div id="lp">
      <div className="back-bar no-bg reg">
        <div className="back-wrapper w-container">
          <Link to="/" className="back-btn w-inline-block">
            <div>BACK</div>
          </Link>
        </div>
      </div>
      <div className="reg-content">
        <div className="reg-header">
          <div className="reg-card-title">Welcome back</div>
        </div>
        <div className="reg-git">
          <button type="button" onClick={onRequestGithubLogin} className="git-group">
            <img src="images/lp/icon_git.svg" alt="" className="git-icon" />
            <div className="reg-header-txt git">LOG IN with GITHUB</div>
          </button>
          <button type="button" onClick={onRequestGithubLogin} className="reg-btn w-inline-block">
            <div>Quick Log In</div>
            <img
              src="images/lp/double_arrows.png"
              width="15"
              alt=""
              className="quick-arrows"
            />
          </button>
        </div>
        <form className="email-reg" onSubmit={onRequestLogin}>
          <div className="reg-header-txt email">OR USE YOUR email</div>
          { authError ? (
            <div className="reg-notification">
              <div className="reg-notification-txt">{authError}</div>
            </div>
          ) : null }
          <div className="reg-input-wrapper">
            <div className="reg-input">
              <div className="reg-input-label">
                <div className="reg-label-txt">Email</div>
              </div>
              <div className="reg-input-field">
                <input
                  type="text"
                  name="email"
                  ref={loginEmailRef}
                  placeholder="Enter email"
                  className="reg-input-txt"
                  autoComplete="username"
                />
              </div>
            </div>
            <div className="reg-input pass">
              <div className="reg-input-label">
                <div className="reg-label-txt">Password</div>
              </div>
              <div className="reg-input-field pass">
                <input
                  type="password"
                  name="password"
                  ref={loginPasswordRef}
                  placeholder="Enter password"
                  className="reg-input-txt"
                  autoComplete="current-password"
                />
              </div>
            </div>
          </div>
          {/* <a href="forgot-pass.html" className="reg-link forgot">
            Forgot password?
          </a> */}
          <div className="reg-btn-wrapper">
            <div className="reg-btn-group login">
              <button type="submit" onClick={onRequestLogin} className="reg-btn outline w-inline-block">
                <div>Log In</div>
              </button>
              <div className="reg-options">
                <div className="reg-label">Don&#x27;t have an account?</div>
                <Link to="/signup-mb" className="reg-link">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
