import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  window.scrollTo(0, 0);

  return (
    <div id="lp">
      <div className="code-hero">
        <div className="back-bar">
          <div className="back-wrapper w-container">
            <Link to="/" className="back-btn w-inline-block">
              <div>BACK</div>
            </Link>
          </div>
        </div>
        <div className="code-hero-wrapper w-container">
          <div className="code-hero-columns w-row">
            <div className="code-hero-col-left w-col w-col-6 w-col-stack">
              <div className="code-hero-title">
                The Telepat North Ethical, Professional and Personal Code for
                Extraordinary Coders
              </div>
            </div>
            <div className="code-hero-col-right w-col w-col-6 w-col-stack">
              <div className="code-hero-txt">
                This Code is honored &amp; maintained by members of Telepat
                North, but is open to anyone who wishes to commit to its
                content. Contributions and forks are welcome.
              </div>
              <a
                href="https://github.com/telepat-io/code"
                target="_blank"
                rel="noreferrer noopener"
                className="code-hero-link"
              >
                https://github.com/telepat-io/code
              </a>
              <img src="images/lp/north_icon_code.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="principles-section ">
        <div className="principles-wrapper w-container">
          <div className="code-title-col w-row">
            <div className="code-title-col-left  w-col w-col-6 w-col-stack">
              <div className="code-title ">1. Ethical Principles</div>
            </div>
            <div className="code-title-col-right w-col w-col-6 w-col-stack">
              <div className="section-divider principles" />
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title ">1.1 Make the world better.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Use your skills for the benefit of society, its members, and
                  the environment.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Promote fundamental human rights.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Minimize the negative consequences of technology, including
                  threats to health, safety, personal security, and privacy.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Give attention and priority to the needs of those less
                  advantaged.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Actively contribute to society.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Promote environmental sustainability both locally and
                  globally.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title ">1.2 Avoid harm.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  In this document, &quotharm&quot means negative consequences,
                  especially when those consequences are significant and unjust.
                  Examples of harm include unjustified physical or mental
                  injury, unjustified destruction or disclosure of information,
                  and unjustified damage to property, reputation, and the
                  environment. This list is not exhaustive.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Ensure that all harm is minimized.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Carefully consider consequences on all those affected by your
                  decisions.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  When the harm is unintended, those responsible are obliged to
                  undo or mitigate the harm as much as possible.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  When harm is an intentional part of the system, those
                  responsible are obligated to ensure that the harm is ethically
                  justified.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Report and address any signs of system risks that might result
                  in harm.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title ">1.3 Be trustworthy.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Provide full disclosure of all pertinent system capabilities,
                  limitations, and potential problems to the appropriate
                  parties.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t offer or accept bribes.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Be honest about qualifications and limitations in competence.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Be forthright about any possible conflicts of interest or
                  situations that might undermine the independence of your
                  judgment.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">Honor commitments.</div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t misrepresent an organization&apos;s policies or procedures,
                  don’t speak on behalf of an organization unless authorized to
                  do so.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title ">1.4 Be fair.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Value equality, tolerance, respect for others, and justice.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Foster fair participation of all people.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Harassment, including sexual harassment, bullying, and other
                  abuses of power and authority, is a form of discrimination and
                  is not tolerated.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Make technologies and practices as inclusive and accessible as
                  possible.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t create systems or technologies that disenfranchise or
                  oppress people.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title ">
                1.5 Respect the work of others.
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Credit the creators of ideas, inventions, patterns, frameworks
                  or any work you incorporate.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Respect copyrights, patents, trade secrets, license
                  agreements, and other methods of protecting authors&apos; works.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t unduly oppose reasonable uses of your intellectual
                  works.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Contribute to free and open-source software and put your work
                  into the public domain.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t claim private ownership of work that others have shared
                  as public resources.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title ">1.6 Respect privacy.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">Put people before data.</div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Only use PII for legitimate ends and without violating the
                  rights of individuals and groups.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Understand the rights and responsibilities associated with the
                  collection and use of PII.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Establish transparent policies and procedures that allow
                  individuals to be in total control of their PII.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Only collect the minimum amount of PII necessary.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  The retention and disposal periods for PII should be clearly
                  defined, enforced, and communicated to data subjects.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  PII gathered for a specific purpose should not be used for
                  other purposes without the person&apos;s consent.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Protect confidentiality except in cases where it is evidence
                  of the violation of the law, of organizational regulations, or
                  the Code.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title ">
                1.7 Respond to illegal or unethical situations.
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Work with colleagues and leaders to correct illegal or
                  unethical situations.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  If you cannot stop these practices, exercise your rights and
                  responsibilities to speak out publicly and engage in
                  responsible whistleblowing without endangering users.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  If you have the authority to do so, use all available legal
                  defenses to stop these practices. If you do not have such
                  authority, and your organizations force you to engage in such
                  misuse, resign from your positions rather than comply.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="principles-section dark">
        <div className="principles-wrapper w-container">
          <div className="code-title-col w-row">
            <div className="code-title-col-left pro w-col w-col-6 w-col-stack">
              <div className="code-title light">2. Professional Principles</div>
            </div>
            <div className="code-title-col-right w-col w-col-6 w-col-stack">
              <div className="section-divider principles" />
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.1 Elevate standards for work processes and products.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Expect and support high-quality work from yourself and
                  colleagues.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Let the code that you produce always be your best work.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Be aware of all the negative consequences that may result from
                  poor quality work.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Be diligent and take pride in your work. Take your work
                  seriously, not yourself.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Make frequent, small, releases so that you do not impede the
                  progress of others.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Fearlessly and relentlessly improve your creations at every
                  opportunity.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Benchmark your code quality against industry standards and
                  implement best practices. Enforce code style.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Demonstrate consistently strong performance so colleagues can
                  rely upon you.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Accomplish amazing amounts of important work.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.2 Elevate standards for competence, conduct, and ethical
                practice.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Take personal responsibility for acquiring and maintaining
                  professional competence.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Upgrading skills is an ongoing process and includes
                  independent study, attending conferences or seminars, and
                  other informal or formal education.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Ask for help. Don’t be ashamed to say &quot;I don&apos;t know&quot;.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Provide constructive, critical reviews of others&apos; work.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Be humble. Recognize that everyone will make mistakes.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Do all that you can to keep the productivity of yourself, and
                  others, as high as possible.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Continuously ensure that others can cover for you and that you
                  can cover for them.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Understand, mitigate and communicate the presence of bias in
                  algorithms.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title light">2.3 Know the rules.</div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  &quot;Rules&quot; here include local, regional, national, and
                  international laws and regulations, as well as any policies
                  and procedures of the organizations to which the professional
                  belongs.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Abide by these rules unless there is a compelling ethical
                  justification to do otherwise.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Rules that are judged unethical should be challenged. A rule
                  may be unethical when it has an inadequate moral basis or
                  causes recognizable harm. Consider challenging the rule
                  through existing channels before violating the rule. When
                  violating a rule, consider potential consequences and accept
                  responsibility for that action.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.4 Give proper evaluations.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Produce objective, credible estimates that are honest both in
                  magnitude and precision.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Strive to be perceptive, thorough, and objective when
                  evaluating, recommending, and presenting descriptions and
                  alternatives.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Reassess risk as often as necessary. Make all stakeholders
                  aware of updates.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Any issues that might result in risk must be reported to
                  appropriate parties.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  If at any point you identify a lack of necessary personal
                  expertise, disclose this to the employer or client.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.5 Design and implement systems that are robustly and usably
                secure.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Make security a permanent consideration since day zero.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Secure resources against accidental and intentional misuse,
                  modification, and denial of service.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Integrate mitigation techniques and policies, such as
                  monitoring, patching, and vulnerability reporting.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Ensure parties affected by data breaches are notified in a
                  timely and clear manner, providing appropriate guidance and
                  remediation.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Security features should be designed to be as intuitive and
                  easy to use as possible.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  When misuse or harm are predictable or unavoidable, the best
                  option may be to not implement the system.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Advocate for security and privacy practices within your
                  organizations.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.6 Secure your workspace.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Activate hard-disk encryption on your devices.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Leverage VPNs and encrypt your DNS.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Use anti-virus software.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Use strong passwords (https://xkcd.com/936/).
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Always enable MFA if available.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Use biometrics, or have a password/pin set to unlock your
                  devices.
                </div>
              </div>
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.7 Do the hard work in research, documentation and writing
                requirements.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Do the research, read the docs and become an expert in matters
                  related to your task.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Identify root causes, and get beyond treating symptoms.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Track and document all of your work, noting any details that
                  might be relevant to another developer in the future. Write
                  detailed documentation and comments for your code and tickets.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Be actively involved in creating and improving specifications.
                  Don’t blindly implement a poor requirement.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Challenge prevailing assumptions, and suggest better
                  approaches.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Re-conceptualize issues to discover solutions to hard
                  problems.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title light">
                2.8 Adequately and efficiently communicate.
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Be concise and articulate in speech and writing.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Approach discussions intentionally and think through all
                  angles.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Share information openly and proactively. Make time to help
                  colleagues.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Listen first and then listen more: seeking first to
                  understand, then to be understood.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot light" />
                <div className="code-item-txt light">
                  Adapt your communication style to work well with people from
                  around the world who may not share your native language.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="principles-section ">
        <div className="principles-wrapper w-container">
          <div className="code-title-col w-row">
            <div className="code-title-col-left  w-col w-col-6 w-col-stack">
              <div className="code-title ">3. Personal Principles</div>
            </div>
            <div className="code-title-col-right w-col w-col-6 w-col-stack">
              <div className="section-divider principles" />
            </div>
          </div>
          <div className="code-col w-row">
            <div className="code-col-left w-col w-col-6 w-col-stack">
              <div className="code-item-title ">
                3.1 Make your health a priority.
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Exercise regularly. Spend time outside. Balance your diet.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Get the amount of sleep you need to be productive daily, in
                  the long run.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">Get regular health checks.</div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Discuss workplace precautions for health and wellbeing with
                  your organizations.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Have friends and family evaluate your burnout level.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Practice a hobby or stress-reducing activities.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Take holidays and use the time off to pursue your passions,
                  disconnect, find perspective, spend time with loved ones and
                  recharge. Be fully engaged in your work and activities, and
                  fully disconnected when you’re not working.
                </div>
              </div>
            </div>
            <div className="code-col-right w-col w-col-6 w-col-stack">
              <div className="code-item-title ">3.2 Become a better you.</div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">Give first.</div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Be self-motivated and persistent in pursuing your education
                  and professional advancement.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Hold yourself accountable – not just for meeting your
                  obligations, but for fulfilling your true potential.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Have a higher expectation of yourself and your work than
                  others have of you.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Act like the person you want to become.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Seek balance by taking into account multiple perspectives and
                  listening deeply.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Refrain from blaming other people for your circumstances.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Don’t run from your fears. Challenge yourself and routinely
                  step outside your comfort zone.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Each day, strive to accomplish the one thing that will move
                  the needle most.
                </div>
              </div>
              <div className="code-item">
                <div className="code-item-dot " />
                <div className="code-item-txt ">
                  Pause to celebrate your achievements, give yourself credit and
                  show gratitude for your circumstances.
                </div>
              </div>
            </div>
          </div>

          <div className="resources">
            <div className="divider principles" />
            <div className="res-title">RESOURCES</div>
            <div className="res-txt">
              This Code largely draws inspiration from the amazing
                ACM Code of Ethics and Professional Conduct
              , alongside a list of other great resources: Nick Johnstone’s
              Programmer’s Oath
            </div>
            <div className="res-col w-row">
              <div className="w-col w-col-4">
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://www.ieee.org/about/corporate/governance/p7-8.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The IEEE Code of Ethics
                  </a>
                </div>
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://github.com/Data4Democracy/ethics-resources"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The Global Data Ethics Pledge
                  </a>
                </div>
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://neveragain.tech/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The Never Again Pledge
                  </a>
                </div>
              </div>
              <div className="w-col w-col-4">
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="http://blog.cleancoder.com/uncle-bob/2015/11/18/TheProgrammersOath.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    Robert Martin’s Programmer’s Oath
                  </a>
                </div>
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://github.com/Widdershin/programmers-oath"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    Nick Johnstone’s Programmer’s Oath
                  </a>
                </div>
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://buffer.com/about#values"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The Buffer Values
                  </a>
                </div>
              </div>
              <div className="w-col w-col-4">
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://jobs.netflix.com/culture"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The Netflix Culture
                  </a>
                </div>
                <div className="res-link-item">
                  <div className="code-item-dot res" />
                  <a
                    href="https://www.techstars.com/code-of-conduct/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="res-link"
                  >
                    The Techstars Code of Conduct
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="text-block">© Telepat North 2020</div>
      </div>
    </div>
  );
};
