import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  window.scrollTo(0, 0);

  return (
    <div id="lp">
      <div className="back-bar no-bg">
        <div className="back-wrapper w-container">
          <Link to="/" className="back-btn w-inline-block">
            <div>BACK</div>
          </Link>
        </div>
      </div>
      <div className="handbook-bg faq">
        <div className="title-section">
          <div className="title-wrapper w-container">
            <div className="title-group">
              <div className="page-title">FAQ</div>
              <img src="images/lp/menu_mob_logo.svg" alt="" className="title-icon" />
            </div>
            <div className="section-divider title" />
          </div>
        </div>
        <div className="handbook-section">
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">What’s Telepat North about?</div>
            <div className="handbook-col w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  Telepat North is an exclusive community for coders everywhere
                  focused on continuous learning and professional development.
                  Membership is free and invite-based, with batches of ten to
                  twenty invites being issued monthly.
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  The club provides members with free access to books, courses
                  or any personalized learning resources they need to grow as
                  technology professionals.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper question w-container">
            <div className="hbook-title">Who can become a member?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  We’re open to bright minds everywhere focused on continuous
                  learning and professional development.
                </div>
              </div>
            </div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">Our members:</div>
              </div>
            </div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-items">
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      have a great story about why they do what they do, and
                      about where they’re going.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      aim to become globally-impacting top professionals in
                      their fields within years.
                    </div>
                  </div>
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    communicate efficiently and are proficient in English.
                  </div>
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    look to best practices for guidance when it comes to craft,
                    methodology or security.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">Does it cost to be a member?</div>
            <div className="handbook-col w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  No. North membership is forever free.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">What benefits do members get?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-items">
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      A subscription to the yearly North Mistery Box.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Access to the Career Advancement Program.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Access to member-only North events and all the North
                      community.
                    </div>
                  </div>
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  The standard benefits package contains:
                  <br />
                  <br />
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    One book of choice every two months, paid by the club.
                  </div>
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    One paid course of choice, on any learning platform, every
                    three months.
                  </div>
                </div>
                <div className="hbook-txt">
                  We&apos;re also open to custom benefits packages, tailored together
                  with each member to fit their individual needs and our budget.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper question w-container">
            <div className="hbook-title">What are members committing to?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-items">
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Honouring, maintaining and advocating the Code.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Openly sharing their knowledge and experience.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Contributing more to open source.
                    </div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">
                      Improving their communication/language skills.
                    </div>
                  </div>
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">Improving their coding skills.</div>
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">Reading more, writing more.</div>
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    Going to more professional events and gatherings.
                  </div>
                </div>
                <div className="hbook-item">
                  <div className="code-item-dot" />
                  <div className="hbook-txt">
                    Encouraging their peers to take the same approach towards
                    excellence.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">How long does it last?</div>
            <div className="handbook-col w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  Membership is lifetime. Members have perpetual access to the
                  community, and it is our aim for them to have perpetual access
                  to all benefits as well. Currently, we&apos;re able to offer 6
                  months of active benefit access for all members.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper question w-container">
            <div className="hbook-title">What’s “the Code”?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  The Code is short for Telepat North’s Ethical, Professional
                  and Personal Code, and consists of a set of commitments that
                  every North member makes to himself and his peers.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">Why North?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  Your career is your responsibility. It is not your employer’s
                  responsibility to make sure you are marketable. It is not your
                  employer’s responsibility to train you, or to send you to
                  conferences, or to buy you books. These things are your
                  responsibility. Woe to the software developer who entrusts his
                  career to his employer. (Robert C. Martin - The Clean Coder)
                </div>
                <div className="hbook-txt">
                  Telepat North&apos;s members are driven by the knowledge that
                  professional growth is a personal responsability.
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  The club provides resources and support to members on their
                  continuous growth path, and aims to emulate the dynamics of a
                  startup accelerator within a community of exceptional coders,
                  by investing in the accumulation and distribution of
                  knowledge.
                </div>
              </div>
            </div>
          </div>
          <div className="handbook-section-wrapper w-container">
            <div className="hbook-title">How do I join?</div>
            <div className="handbook-col answer w-row">
              <div className="hbook-col-left w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  Candidates need to receive an invitation from an existing
                  North member and jump on a 30-minute call with a group of
                  senior crew members.
                  <br />
                  <br />
                  The talk will revolve around:
                </div>
                <div className="hbook-items answer">
                  <div className="hbook-item first">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">Drive, motivations &amp; goals.</div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">Vision on the Code.</div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">Professional skills.</div>
                  </div>
                  <div className="hbook-item">
                    <div className="code-item-dot" />
                    <div className="hbook-txt">Communication skills.</div>
                  </div>
                </div>
              </div>
              <div className="hbook-col-right w-col w-col-6 w-col-stack">
                <div className="hbook-txt">
                  Candidates can fast-track to the final stage of our evaluation
                  by creating a Telepat North 360 profile:
                </div>
                <Link
                  to="/"
                  className="btn-fill test faq w-inline-block"
                >
                  <div>Create profile</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="text-block">© Telepat North 2020</div>
      </div>
    </div>
  );
};
