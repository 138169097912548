const moment = require('moment');

function nextTestTakeDate(lastStartedOn, repeatAfterDays = 0) {
  let repeatAfter = repeatAfterDays;

  if (process.env.NODE_ENV !== 'production') {
    repeatAfter = process.env.REACT_APP_DEV_MODE_INTERVAL;
  }

  return moment(lastStartedOn)
    .add(repeatAfter, 'days')
    .toDate();
}

function nextTestTakeDuration(nextDate) {
  const today = moment();
  const nextTestDate = moment(nextDate);
  const duration = Math.ceil(moment.duration(nextTestDate.diff(today)).as('days'));
  return duration > 0 ? duration : 0;
}

function canTakeTest(lastStartedOn, repeatAfterDays = 0) {
  return moment(
    nextTestTakeDate(lastStartedOn, repeatAfterDays),
  ).isBefore(moment());
}

module.exports = {
  nextTestTakeDate,
  canTakeTest,
  nextTestTakeDuration,
};
