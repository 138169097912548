import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getNickname as getUserNickname } from '../../selectors/user';
import { getNickname as getProfileNickname } from '../../selectors/profile';
import { getCodeShowcaseSessions } from '../../selectors/achievements';

export default ({ bottomSeparator = false, publicProfile = false }) => {
  const currentCodeShowcaseSession = useSelector(getCodeShowcaseSessions(publicProfile))[0];
  const nickname = useSelector(publicProfile ? getProfileNickname : getUserNickname);

  if (publicProfile && (!currentCodeShowcaseSession || currentCodeShowcaseSession.status !== 'validated')) {
    return [];
  }

  return (
    <div className="showcase">
      <div className="section-header">
        <div className="section-title">Code Showcase</div>
        <div className="section-body-txt">
          This recorded session, moderated by a North senior, provides a reference
          for&nbsp;
          {nickname}
          &apos;s&nbsp;
          <strong>coding proficiency</strong>
          , as well as&nbsp;
          <strong>reasoning abilities</strong>
          &nbsp;and&nbsp;
          <strong>language skill</strong>
          .
        </div>
      </div>
      {(currentCodeShowcaseSession && currentCodeShowcaseSession.status === 'validated') && (
        <div className="recorded-time">
          <img src="images/icon_time.svg" alt="" className="recorded-time-icon" />
          <div className="recorded-time-label">Recorded on:</div>
          <div className="recorded-time-txt">
            {moment(currentCodeShowcaseSession.achievedAt || currentCodeShowcaseSession.createdAt)
              .format('MMMM Do YYYY')}
          </div>
        </div>
      )}
      <div className="vid-wrapper">
        {(!currentCodeShowcaseSession || currentCodeShowcaseSession.status === 'rejected') && (
          <div className="empty-card">
            <div className="placeholder-content">
              <img src="images/placeholder_showcase.svg" alt="" />
              <div className="placeholder-title">Schedule your Session</div>
              <a
                href="https://calendly.com/gabidobocan/north-code-showcase"
                className="placeholder-btn w-inline-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>SCHEDULE NOW</div>
              </a>
            </div>
          </div>
        )}
        {(currentCodeShowcaseSession && currentCodeShowcaseSession.status === 'pending') && (
          <div className="empty-card pending">
            <div className="card-content-pending video">
              <img src="images/placeholder_showcase.svg" alt="" />
              <div className="pending-title">Hang Tight!</div>
              <div className="pending-txt">After your session we’ll update this section with our evaluation and feedback.</div>
              <div className="pending-tag">PENDING</div>
            </div>
          </div>
        )}
        {(currentCodeShowcaseSession && currentCodeShowcaseSession.status === 'validated') && (
          <iframe
            title="Code Showcase"
            className="showcase-img code-showcase-video"
            width="901"
            height="507"
            src={`https://www.youtube.com/embed/${currentCodeShowcaseSession.urls[0]}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      {bottomSeparator && (<div className="profile-section-divider" />)}
    </div>
  );
};
