export const getCurrentQuestion = state => state.test.data
  .sections[state.test.currentSectionIndex]
  .questions[state.test.currentQuestionIndex];

export const getAllQuestions = state => (
  (state.test.data && state.test.data.sections) ? state.test.data
    .sections[state.test.currentSectionIndex]
    .questions : null);

export const getCurrentAnswer = state => state.test.answers[state.test.currentQuestionIndex];

export const getCurrentSection = state => state.test.data && state.test.data
  .sections[state.test.currentSectionIndex];

export const getCurrentTestId = state => (state.test.data ? state.test.data._id : null);

export const getAllowTransitions = state => (state.test.allowTransitions);
