import React from 'react';

import TestCard from '../TestCard';
import { OrgData } from '../../../constants/testData';

export default params => (
  <TestCard
    title={OrgData.title}
    iconSmall={OrgData.iconSmall}
    iconLarge={OrgData.iconLarge}
    shortDescription={OrgData.shortDescription}
    completionTime={OrgData.completionTime}
    slug={OrgData.slug}
    color={OrgData.color}
    {...params}
  />
);
