import {
  put, takeEvery, all, call, select,
} from 'redux-saga/effects';

import { reloadUser } from './user';
import actions from '../constants/actions';
import API from '../services/api';

export function* postAchievement(action) {
  try {
    const jwt = yield select(state => state.user.jwt);

    const { data } = yield call(API.postAchievement, action.achievement, jwt);
    yield put({ type: actions.POST_ACHIEVEMENT_SUCCESS, data });
    yield reloadUser();
  } catch (error) {
    yield put({ type: actions.POST_ACHIEVEMENT_ERROR, error: error.message });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.POST_ACHIEVEMENT, postAchievement),
  ]);
}
