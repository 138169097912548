/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

export default ({ closeModal }) => (
  <div className="modals-rank-info">
    <div className="modal-overlay rank-aspirant">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Rank info</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-content">
          <div className="rank-wrapper">
            <div className="rank-info-group">
              <div className="rank-badge-group">
                <div className="username-badge small aspirant" />
                <div className="rank-type">
                  <div className="rank-name-group">
                    <div className="rank-label">Rank - </div>
                    <div className="rank-name">Aspirant</div>
                  </div>
                  <a
                    href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="source-btn rank w-inline-block"
                  >
                    <div>Handbook</div>
                  </a>
                </div>
              </div>
              <a
                href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                target="_blank"
                rel="noopener noreferrer"
                className="handbook-link w-inline-block"
              />
            </div>
            <div className="rank-divider" />
            <div className="body-txt">
              Aspirants are coders that are new to the field,
              don’t have extensive experience, or need to expand
              or improve their professional skills to advance in rank.
              <br />
              <br />
              Aspirants can strive to reach the
              {' '}
              <strong>Cadet Rank</strong>
              {' '}
              and will generally be expected to impress by improving and
              extending their professional experience.
            </div>
            <div className="benefits-label">ASPIRANT&nbsp;BENEFITS:</div>
            <div className="info-list">
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">Access to the North community.</div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">Access to Workshops and Special Events.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
