import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextSelect from './Answers/TextSelect';
import ImageSelect from './Answers/ImageSelect';
import ExperienceSelect from './Answers/ExperienceSelect';
import TextInput from './Answers/TextInput';
import TextMultiselect from './Answers/TextMultiselect';

import actions from '../../constants/actions';
import { getCurrentQuestion, getCurrentAnswer } from '../../selectors/test';

export default (props) => {
  const dispatch = useDispatch();
  const testData = useSelector(state => state.test.data);

  if (!testData) {
    return (<div />);
  }

  const {
    isGroup, parentQuestion, groupIndex, scrollRef,
  } = props;

  let currentQuestion;
  let currentAnswer;

  if (!isGroup) {
    currentQuestion = useSelector(getCurrentQuestion);
    currentAnswer = useSelector(getCurrentAnswer);
  } else {
    currentQuestion = parentQuestion;
    currentAnswer = useSelector((state) => {
      if (!state.test.groupAnswers || !state.test.groupAnswers[groupIndex]) return null;
      return state.test.groupAnswers[groupIndex][parentQuestion.index - 1];
    });
  }

  let AnswerComponent = React.Component;

  switch (currentQuestion.type) {
    case 'text_select':
      AnswerComponent = TextSelect;
      break;
    case 'image_select':
      AnswerComponent = ImageSelect;
      break;
    case 'experience_select':
      AnswerComponent = ExperienceSelect;
      break;
    case 'text_input':
      AnswerComponent = TextInput;
      break;
    case 'text_multi_select':
      AnswerComponent = TextMultiselect;
      break;
    default:
      break;
  }

  const { index } = currentQuestion;

  return (
    <AnswerComponent
      answers={currentQuestion.answers}
      currentAnswer={currentAnswer || []}
      scrollRef={scrollRef}
      color={testData.color || ''}
      test={testData.type}
      setAnswer={(answerId, value = false) => {
        if (isGroup) {
          dispatch(
            { type: actions.SET_CURRENT_QUESTION_INDEX, index: index - 1 },
          );
        }
        dispatch(
          {
            type: actions.POST_ANSWER,
            answerId,
            value,
            isGroup,
            groupIndex,
          },
        );
      }}
    />
  );
};
