/* eslint-disable import/no-cycle */
import React from 'react';

import TestInfo from '../TestInfo';
import { OrgData } from '../../../constants/testData';

export default () => (
  <TestInfo
    title={OrgData.title}
    icon={OrgData.iconSmall}
    description={OrgData.description}
    completionTime={`${OrgData.completionTime} minutes`}
    tips={OrgData.tips}
    modal={OrgData.whyModal}
    slug={OrgData.slug}
    color={OrgData.color}
  />
);
