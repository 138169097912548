import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASSESSMENT } from '../../constants/achievementTypes';
import { getSkillAssessments } from '../../selectors/achievements';
import { getNickname as getUserNickname } from '../../selectors/user';
import { getNickname as getProfileNickname } from '../../selectors/profile';
import actions from '../../constants/actions';
import AssessmentCard from './Cards/AssessmentCard';

export default ({ publicProfile = false, bottomSeparator = false }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [link, setLink] = useState('');
  const [comments, setComments] = useState('');
  const nickname = useSelector(publicProfile ? getProfileNickname : getUserNickname);

  const dispatch = useDispatch();

  const currentAssessments = useSelector(getSkillAssessments(publicProfile))
    .filter(a => !publicProfile || a.status === 'validated');

  if (publicProfile && currentAssessments.length === 0) {
    return [];
  }

  return (
    <div className="skills">
      {modalVisible && (
        <div className="modal-overlay add-skill">
          <div className="modal no-scroll narrow">
            <div className="modal-header">
              <div className="modal-title">Skill Assessment</div>
              <button
                type="button"
                className="modal-close w-inline-block"
                onClick={() => setModalVisible(false)}
              />
            </div>
            <div className="modal-content no-scroll">
              <div className="modal-input-wrapper">
                <div className="body-txt">Send us a public link where we can verify your assessment results.</div>
                <div className="input-area skill">
                  <div className="input-group">
                    <div className="input-label">Assessment link</div>
                    <input
                      type="text"
                      placeholder="eg. https://linkedin.com/python-test"
                      className="modal-input"
                      value={link}
                      onChange={event => setLink(event.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-label">Comments</div>
                    <textarea
                      placeholder="Enter your comments"
                      className="modal-input long"
                      value={comments}
                      onChange={event => setComments(event.target.value)}
                    />
                  </div>
                </div>
                <button
                  className="modal-btn w-inline-block"
                  type="button"
                  onClick={() => {
                    setModalVisible(false);
                    dispatch({
                      type: actions.POST_ACHIEVEMENT,
                      achievement: {
                        type: ASSESSMENT,
                        urls: [link],
                        userComments: comments,
                      },
                    });
                  }}
                >
                  <div>Submit Assessment</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-header">
        <div className="section-title-wrapper">
          <div className="section-title">Skill Assessments</div>
          {!publicProfile && (
            <button
              type="button"
              className="card-btn add w-inline-block"
              onClick={() => setModalVisible(true)}
            >
              <div>Add Assessment</div>
            </button>
          )}
        </div>
        <div className="section-body-txt">
          Professional&nbsp;
          <strong>skill assessments and certifications</strong>
          &nbsp;obtained by&nbsp;
          {nickname}
          , verified by North.
        </div>
      </div>
      {!publicProfile && (
        <button
          type="button"
          onClick={() => setModalVisible(true)}
          className="card-btn add mob w-inline-block"
        >
          <div>Add Assessment</div>
        </button>
      )}
      {currentAssessments.length === 0 && (
        <div className="empty-card slim">
          <div>No assessments added yet.</div>
        </div>
      )}
      {currentAssessments.length > 0 && (
        <div className="skills-wrapper">
          <div className="card-cols w-row">
            {currentAssessments.map((assessment, index) => (
              <AssessmentCard assessment={assessment} index={index} key={assessment._id} />
            ))}
          </div>
        </div>
      )}
      {bottomSeparator && (<div className="profile-section-divider" />)}
    </div>
  );
};
