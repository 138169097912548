/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { ExpData } from '../../../constants/testData';

export default (props) => {
  const { result, onShowInfo } = props;
  const testTaken = result && !result.open;
  const [modalData, setModalData] = useState(null);
  const [breakdownVisible, setBreakdownVisible] = useState(true);
  const groups = (result && result.qualifiedExpDomains) || [];
  const expBreakdown = groups.reduce((agg, group) => ({
    pro: agg.pro.concat(group.pro),
    experienced: agg.experienced.concat(group.experienced),
    // beginner: agg.beginner.concat(group.beginner),
  }), { pro: [], experienced: [], beginner: [] });
  const featuredSkills = expBreakdown.pro.map(skill => ({ data: skill, level: 'pro' }))
    .concat(expBreakdown.experienced.map(skill => ({ data: skill, level: 'experienced' })))
    // .concat(expBreakdown.beginner.map(skill => ({ data: skill, level: 'beginner' })))
    // .slice(0, 12)
    .map((skill) => {
      if (typeof skill.data === 'string') {
        return {
          ...skill,
          data: {
            name: skill.data,
            ...ExpData.legacySkillMetadata[skill.data],
          } || { name: skill.data },
        };
      }

      return skill;
    });
  const chunk = (input, chunkSize) => input.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  const legacySupportMap = (skill) => typeof skill === 'object' ? skill.name : skill;

  return (
    <div className="exp-section">
      {modalData && (
        <div className="modal-overlay exp-details">
          <div className="modal">
            <div className="modal-header">
              <div className="modal-title">Experience details</div>
              <button type="button" className="modal-close w-inline-block" onClick={() => setModalData(null)} />
            </div>
            <div className="modal-content">
              <div className="exp-details-wrapper">
                <div className="exp-list-item">
                  <div className="exp-list-header">
                    <div className="results-subtitle details">{modalData.title}</div>
                  </div>
                  <div className="exp-list-progress">
                    <div
                      className="exp-list-progress-bar"
                      style={{
                        width: `${modalData.points * 100 / modalData.totalPointsAvailable}%`,
                      }}
                    />
                  </div>
                  <div className="exp-points">
                    {modalData.points}
                    {' '}
                    /
                    {' '}
                    {modalData.totalPointsAvailable}
                    {' '}
                    points
                  </div>
                </div>
                {modalData.pro.length > 0 && (
                  <div className="rating-item">
                    <div className="rating-divider" />
                    <div className="rating-item-header">
                      <div className="rating-type">
                        <div className="rating-dot" />
                        <div className="rating-item-txt">Pro</div>
                      </div>
                      <div className="exp-sum-stars small">
                        <div className="exp-sum-star small" />
                        <div className="exp-sum-star small" />
                        <div className="exp-sum-star small" />
                      </div>
                    </div>
                    <div className="body-txt rating">{
                      modalData.pro
                        .map(legacySupportMap)
                        .join(', ')
                    }</div>
                  </div>
                )}
                {modalData.experienced.length > 0 && (
                  <div className="rating-item">
                    <div className="rating-divider" />
                    <div className="rating-item-header">
                      <div className="rating-type">
                        <div className="rating-dot" />
                        <div className="rating-item-txt">Experienced</div>
                      </div>
                      <div className="exp-sum-stars small">
                        <div className="exp-sum-star small" />
                        <div className="exp-sum-star small" />
                        <div className="exp-sum-star small disabled" />
                      </div>
                    </div>
                    <div className="body-txt rating">{
                      modalData.experienced
                        .map(legacySupportMap)
                        .join(', ')
                    }</div>
                  </div>
                )}
                {modalData.beginner.length > 0 && (
                  <div className="rating-item">
                    <div className="rating-divider" />
                    <div className="rating-item-header">
                      <div className="rating-type">
                        <div className="rating-dot" />
                        <div className="rating-item-txt">Beginner</div>
                      </div>
                      <div className="exp-sum-stars small">
                        <div className="exp-sum-star small" />
                        <div className="exp-sum-star small disabled" />
                        <div className="exp-sum-star small disabled" />
                      </div>
                    </div>
                    <div className="body-txt rating">{
                      modalData.beginner
                        .map(legacySupportMap)
                        .join(', ')
                    }</div>
                  </div>
                )}
                {modalData.other.length > 0 && (
                  <div className="rating-item">
                    <div className="rating-divider" />
                    <div className="rating-item-header">
                      <div className="rating-type other">
                        <div className="rating-dot" />
                        <div className="rating-item-txt">Other</div>
                      </div>
                    </div>
                    <div className="body-txt rating">{
                      modalData.other
                        .map(legacySupportMap)
                        .join(', ')
                    }</div>
                  </div>
                )}
                {modalData.checklist.length > 0 && (
                  <div className="rating-item">
                    <div className="rating-divider" />
                    <div className="rating-item-header">
                      <div className="rating-type">
                        <div className="rating-dot" />
                        <div className="rating-item-txt">Experience checklist</div>
                      </div>
                    </div>
                    <div className="rating-check-list-group">
                      {modalData.checklist.map(t => (
                        <div key={t} className="rating-check-list">
                          <img src="images/check_list_rating.svg" alt="" />
                          <div className="body-txt rating">{t}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-header">
        <div className="section-title-wrapper exp-preview">
          <div className="section-title">Experience &amp; Practices</div>
          <button
            type="button"
            onClick={onShowInfo}
            className="card-btn exp-more-info w-inline-block"
          >
            <div>More info</div>
          </button>
        </div>
        <div className="section-body-txt">
          A&nbsp;
          <strong>technical self-assessment</strong>
          &nbsp;that outlines expertise across several relevant work areas like: Databases,
          Frontend and Backend Frameworks, APIs, AI Tools or Big Data Services.
        </div>
      </div>
      {!testTaken && (
        <div className="empty-card slim"><div>Test not taken yet.</div></div>
      )}
      {testTaken && (
        <div id="exp-results" className="exp-results preview">
          <div className="exp-summary">
            {chunk(featuredSkills, 4).map((skills, index) => (
              <div key={index} className={`exp-sum-cols w-row ${(index !== chunk(featuredSkills, 4).length - 1) ? 'top' : ''}`}>
                {skills.map(skill => (
                  <div
                    key={skill.data.name}
                    className="exp-sum-col-01 w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-6"
                  >
                    <div className="exp-summary-card">
                      <div className="exp-sum-name">{skill.data.name}</div>
                      {skill.data.logo && <img src={`images/tests/${skill.data.logo}`} alt="" />}
                      {!skill.data.logo && <img src="images/experience__card_check.png" className="no-logo" alt="" />}
                      <div className="exp-sum-stars">
                        <div className="exp-sum-star" />
                        <div className={`exp-sum-star ${skill.level === 'beginner' ? 'disabled' : ''}`} />
                        <div className={`exp-sum-star ${skill.level === 'pro' ? '' : 'disabled'}`} />
                      </div>
                      <div className="exp-sum-rating">{skill.level}</div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {breakdownVisible && (
            <div className="exp-breakdown">
              <div className="exp-breakdown-title">Experience Breakdown</div>
              <div className="exp-breakdown-divider" />
              {chunk(groups, 3).map((groupsChunk, index) => (
                <div key={index} className={`exp-list-cols w-row ${index === (chunk(groups, 3).length - 1) ? 'last' : ''}`}>
                  {groupsChunk.map(group => (
                    <div key={group.title} className="exp-list-col-01 w-col w-col-4 w-col-medium-4">
                      <div className="exp-list-item">
                        <div className="exp-list-header">
                          <div className={`results-subtitle ${group.points === 0 ? 'low-score' : ''}`}>{group.title}</div>
                          <button type="button" className="exp-info w-inline-block" onClick={() => setModalData(group)} />
                        </div>
                        <div className="exp-list-progress">
                          <div
                            className="exp-list-progress-bar"
                            style={{
                              width: `${parseInt(group.points * 100 / group.totalPointsAvailable, 10)}%`,
                            }}
                          />
                        </div>
                        <div className="exp-points">
                          {group.points}
                          {' '}
                          /
                          {' '}
                          {group.totalPointsAvailable}
                          {' '}
                          points
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          {!breakdownVisible && (
            <button type="button" className="exp-breakdown-btn w-inline-block" onClick={() => setBreakdownVisible(true)}>
              <div>SHOW EXPERIENCE BREAKDOWN</div>
            </button>
          )}
          {breakdownVisible && (
            <button type="button" className="exp-breakdown-btn-hide w-inline-block" onClick={() => setBreakdownVisible(false)}>
              <div>HIDE EXPERIENCE BREAKDOWN</div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
