import actions from '../constants/actions';

export default (state = {
  loading: false,
  loadingNickname: false,
  logged: false,
  jwt: null,
  error: null,
  data: null,
  users: null,
  processingInvite: false,
}, action) => {
  switch (action.type) {
    case actions.JWT_LOGIN:
      return {
        loading: true,
        logged: false,
        jwt: null,
        error: null,
        data: null,
      };

    case actions.JWT_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true,
        error: null,
        data: action.data,
        jwt: action.jwt,
      };

    case actions.JWT_LOGIN_ERROR:
    case actions.RELOAD_USER_ERROR:
      return {
        ...state,
        loading: false,
        logged: false,
        error: action.error,
        data: null,
        jwt: null,
      };

    case actions.RELOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true,
        error: null,
        data: action.data,
      };

    case actions.SIGNUP:
      return {
        loading: true,
        logged: false,
        error: null,
      };

    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case actions.SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.LOGIN_EMAIL:
      return {
        loading: true,
        logged: false,
        error: null,
      };

    case actions.LOGIN_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case actions.LOGIN_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };

    case actions.INVITE_USER:
    case actions.REMIND_USER:
      return {
        ...state,
        processingInvite: true,
      };

    case actions.INVITE_FINISHED:
      return {
        ...state,
        processingInvite: false,
      };

    case actions.LOGOUT:
      return {
        loading: false,
        loadingNickname: false,
        logged: false,
        jwt: null,
        error: null,
        data: null,
      };

    case actions.REGENERATE_NICKNAME: {
      return {
        ...state,
        loadingNickname: true,
        error: null,
      };
    }

    case actions.REGENERATE_NICKNAME_SUCCESS: {
      return {
        ...state,
        loadingNickname: false,
        error: null,
        data: {
          ...state.data,
          nickname: action.nickname,
        },
      };
    }

    case actions.REGENERATE_NICKNAME_ERROR: {
      return {
        ...state,
        loadingNickname: false,
        error: action.error,
      };
    }

    case actions.PICK_NICKNAME: {
      return {
        ...state,
        data: {
          ...state.data,
          pickedNickname: true,
        },
      };
    }

    case actions.QUALIFY_LEAD_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          leadQualified: true,
        },
      };
    }

    case actions.REFRESH_RESULTS: {
      const newResults = action.data;
      const newRecentResults = state.data.recentResults.filter(r => r.type !== newResults.type);

      newRecentResults.push(newResults);

      return {
        ...state,
        data: {
          ...state.data,
          recentResults: newRecentResults,
        },
      };
    }

    case actions.USER_SET_PERMISSIONS:
      return {
        ...state,
        data: {
          ...state.data,
          permissions: {
            profileUpdatesComm: action.profileUpdatesComm,
            opportunitiesComm: action.opportunitiesComm,
          },
        },
      };

    default:
      return state;
  }
};
