import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default (props) => {
  const {
    onTopScrollChanged,
  } = props;

  return (
    <div id="home-hero" className="home-hero">
      <div className="slide-content">
        <div className="section-arrow">
          <div className="scroll-txt">LEARN MORE</div>
          <img
            src="images/lp/slide_arrow.png"
            width="14"
            style={{
              WebkitTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              MozTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              msTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              transform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
            }}
            alt=""
            className="slider-arrow"
          />
        </div>
        <div
          data-poster-url="videos/home_intro_final-poster-00001.jpg"
          data-video-urls="videos/home_intro_final-transcode.mp4,videos/home_intro_final-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          className="home-vid w-background-video w-background-video-atom"
        >
          <video
            autoPlay
            loop
            style={{ backgroundImage: 'url("videos/home_intro_final-poster-00001.jpg")' }}
            muted
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
          >
            <source
              src="videos/home_intro_final-transcode.mp4"
              data-wf-ignore="true"
            />
            <source
              src="videos/home_intro_final-transcode.webm"
              data-wf-ignore="true"
            />
          </video>
          <div className="home-copy">
            <img
              className="home-logo"
              src="images/lp/logo_home.png"
              width="520"
              alt=""
              style={{
                WebkitTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                MozTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                msTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                transform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                opacity: 1,
              }}
              sizes="(max-width: 479px) 320px, (max-width: 767px) 100vw, 520px"
              data-ix="display-nav"
              data-w-id="c01262d2-d0a8-6189-57eb-27c930040d37"
              srcSet="images/lp/logo_home-p-500.png 500w, images/lp/logo_home-p-800.png 800w, images/lp/logo_home.png 1040w"
            />
            <VisibilitySensor onChange={onTopScrollChanged}>
              <div
                data-w-id="7dd95d89-3749-c8e7-c8f4-49d2fe27c08f"
                style={{
                  WebkitTransform:
                    'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  MozTransform:
                    'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  msTransform:
                    'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  transform:
                    'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  opacity: 1,
                }}
                className="section-divider home"
              />
            </VisibilitySensor>
            <div
              data-w-id="aabec7a7-c0bc-6617-e3ea-a48c0efd7c94"
              style={{
                WebkitTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                MozTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                msTransform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                transform:
                  'translate3d(0, 50PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                opacity: 1,
              }}
              className="hero-txt home"
            >
              <span>P</span>
              rivate Coders Club for bright minds focused on
              continuous learning and personal development.
            </div>
            <div
              data-w-id="df303f61-cfde-01a7-f915-ecb919809294"
              className="batch-card mob"
            >
              <div className="batch-month">
                {new Date().toLocaleString('default', { month: 'long' })}
                {' '}
                {new Date().getFullYear()}
              </div>
              <div className="batch-title">BATCH OPEN NOW!</div>
              <div className="batch-slots">10 slots available</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
