import React from 'react';

export default ({
  setAchievementTypeModalVisible,
  setStackOverflowModalVisible,
  setContentModalVisible,
  setOssCreatorModalVisible,
  setOssContributorModalVisible,
}) => (
  <div className="modal-overlay select-achievement">
    <div className="modal achievements">
      <div className="modal-header">
        <div className="modal-title">Achievement Types</div>
        <button
          type="button"
          className="modal-close w-inline-block"
          onClick={() => setAchievementTypeModalVisible(false)}
        />
      </div>
      <div className="achievement-btns">
        <div className="achievement-btns-wrapper">
          <button
            type="button"
            className="achievement-btn stack left w-inline-block"
            onClick={() => {
              setAchievementTypeModalVisible(false);
              setStackOverflowModalVisible(true);
            }}
          >
            <div className="achievement-modal-img stack" />
            <div>Stack Overflow Stats</div>
          </button>
          <button
            type="button"
            className="achievement-btn w-inline-block"
            onClick={() => {
              setAchievementTypeModalVisible(false);
              setContentModalVisible(true);
            }}
          >
            <div className="achievement-modal-img creator" />
            <div>Active Content Creator</div>
          </button>
        </div>
        <div className="achievement-btns-wrapper bottom">
          <button
            type="button"
            className="achievement-btn left w-inline-block"
            onClick={() => {
              setAchievementTypeModalVisible(false);
              setOssCreatorModalVisible(true);
            }}
          >
            <div className="achievement-modal-img oss-creator" />
            <div>Open Source Creator</div>
          </button>
          <button
            type="button"
            className="achievement-btn w-inline-block"
            onClick={() => {
              setAchievementTypeModalVisible(false);
              setOssContributorModalVisible(true);
            }}
          >
            <div className="achievement-modal-img oss-contributor" />
            <div>Open Source Contributor</div>
          </button>
        </div>
      </div>
    </div>
  </div>
);
