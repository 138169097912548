import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  const {
    insOutsRef,
  } = props;

  return (
    <div id="ins-outs" ref={insOutsRef} className="info-wrapper">
      <div className="info-content">
        <div className="info-top">
          <div className="info-prompt">
            <div className="main-title">INS &amp; OUTS</div>
            <div className="div-title-wrapper info">
              <div className="title-div" />
              <div className="info-prompt-txt">
                Before you start, these 3 things are important.
              </div>
            </div>
            <div className="info-prompt-label">Please read them!</div>
            <div className="info-arrow" />
          </div>
          <div className="code-group">
            <div className="section-divider gray" />
            <div className="info-card-title">THE CODE</div>
            <div className="card-label info">
              A set of commitments that every North member makes to himself
              and his peers.
            </div>
            <Link
              to="/code"
              className="btn-outline dark w-inline-block"
            >
              <div>Read the Code</div>
            </Link>
          </div>
        </div>
        <div className="info-bottom">
          <div className="handbook-group">
            <div className="section-divider blue" />
            <div className="info-card-title">THE HANDBOOK</div>
            <div className="card-label info">
              A complete guide on how Telepat North works and what to expect
              from the program.
            </div>
            <Link
              to="/handbook"
              className="btn-outline dark w-inline-block"
            >
              <div>Read the Handbook</div>
            </Link>
          </div>
          <div className="faq-group">
            <div className="section-divider faq" />
            <div className="info-card-title light">FAQ</div>
            <div className="card-label info light">
              Answers to the most common questions about the Telepat North
              experience.
            </div>
            <Link to="/faq" className="btn-outline light w-inline-block">
              <div>Learn more</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
