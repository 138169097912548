import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back text-only">
        <div className="nav-label red">General Mental Ability</div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="test-example">
          <div className="col-wrapper">
            <div className="ex-header-labels">
              <div>Sub-test 2/4</div>
              <div>Time limit:</div>
            </div>
            <div className="ex-header">
              <div className="title">Classification</div>
              <div className="time">
                <img src="images/icon_time.png" width="12" alt="" className="time-icon blue" />
                <div className="time-txt blue">4 min</div>
              </div>
            </div>
            <p className="body-txt">
              In the Classification sub-test, you will be presented with 5 figures.
              You must select one which is different from the other four.
              <br />
              <br />
              This measures generalization and discrimination skills.
            </p>
            <div className="test-tips example mob">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image is different than the others?</div>
              <div className="example-subtitle alt">Correct answer:</div>
              <img src="images/tests/classification_ex.png" width="240" alt="" />
            </div>
            <Link to="/tests/gma/section/2/run" className="button outline w-inline-block">
              <div>Begin the sub-test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="test-tips example">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image is different than the others?</div>
              <div className="example-subtitle alt">Correct answer:</div>
              <img src="images/tests/classification_ex.png" width="240" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
