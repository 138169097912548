import React from 'react';

import GMACard from '../../components/Tests/GMA/Card';
import GMAWhyModal from '../../components/Tests/GMA/WhyModal';
import GMAInfo from '../../components/Tests/GMA/Info';
import GMASectionOneIntro from '../../components/Tests/GMA/SectionOneIntro';
import GMASectionTwoIntro from '../../components/Tests/GMA/SectionTwoIntro';
import GMASectionThreeIntro from '../../components/Tests/GMA/SectionThreeIntro';
import GMASectionFourIntro from '../../components/Tests/GMA/SectionFourIntro';
import GMADone from '../../components/Tests/GMA/Done';
import GMAResult from '../../components/Tests/GMA/Result';

import { CCFIT } from '../testTypes';

export default {
  type: CCFIT,
  slug: 'gma',
  title: 'General Mental Ability',
  color: 'blue',
  iconSmall: 'images/test_icon_01.svg',
  iconLarge: 'images/test_icon_xl_01.svg',
  shortDescription: (
    <span>
      This is a
      {' '}
      <strong>logical test</strong>
      {' '}
      designed to measure individual intelligence,
      while reducing the influence of verbal fluency, cultural climate,
      and educational level. The test is derived from the Cattell Culture Fair scale.
    </span>
  ),
  description: (
    <span>
      This is a
      {' '}
      <strong>logical test</strong>
      {' '}
      designed to measure individual intelligence, while reducing the
      influence of verbal fluency, cultural climate, and educational level.
      <br />
      <br />
      The test contains four sub-tests, involving different perceptual tasks.
      There is a time limit for each sub-test.
    </span>
  ),
  resultInfo: (nickname, resultDate, nextDate) => (
    (
      <div className="body-txt">
        We tested&nbsp;
        {nickname}
        &nbsp;for General Mental Ability on&nbsp;
        {resultDate}
        , with a test derived from the Cattell Culture Fair scale.
        <br />
        <br />
        This test can be taken again starting on&nbsp;
        {nextDate}
        .
      </div>
    )
  ),
  completionTime: 12,
  tips: [
    'Pick a comfortable, quiet place to maximize your focus.',
    'When the dedicated time for one of the sub-tests ends you will be automatically redirected to the next sub-test.',
    'You can take this test once a year.',
  ],
  studies: [
    {
      text: (
        <span>
          GMA predicts both occupational level attained and performance within one&#39;s
          chosen occupation and does so better than any other ability, trait, or disposition
          and better than job experience.
        </span>),
      url: 'https://psycnet.apa.org/record/2003-11198-011',
    },
    {
      text: (
        <span>
          GMA and specific ability tests are valid predictors of both job performance
          and training success, with operational validities in the magnitude of .5 - .6.
        </span>
      ),
      url: 'https://psycnet.apa.org/record/2006-03538-006',
    },
    {
      text: (
        <span>
          GMA measures showed that there is validity generalization and large operational
          validities for job performance and training success in 11 occupational groups.
        </span>
      ),
      url: 'https://www.ncbi.nlm.nih.gov/pubmed/14640817',
    },
    {
      text: (
        <span>
          Results indicate strong support for the validity of GMA measures in personnel selection.
        </span>
      ),
      url: 'https://www.tandfonline.com/doi/abs/10.1080/13594320244000292',
    },
    {
      text: (
        <span>
          General mental ability was a better predictor of performance for new employees, whereas
          conscientiousness was a better predictor of performance for experienced employees.
        </span>
      ),
      url: 'https://journals.sagepub.com/doi/abs/10.1177/0010880407302048',
    },
  ],
  card: GMACard,
  whyModal: GMAWhyModal,
  infoComponent: GMAInfo,
  doneComponent: GMADone,
  resultCard: GMAResult,
  sections: [
    GMASectionOneIntro,
    GMASectionTwoIntro,
    GMASectionThreeIntro,
    GMASectionFourIntro,
  ],
};
