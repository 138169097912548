import React, { useState } from 'react';
import RadarChart from 'react-svg-radar-chart';
import * as values from 'object.values';

import 'react-svg-radar-chart/build/css/index.css';
import { OrgData } from '../../../constants/testData';

export default (props) => {
  const { result, nickname, onShowInfo } = props;
  const testTaken = result && !result.open;
  const { breakdown } = OrgData;
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  if (!Object.values) {
    values.shim();
  }
  const maxScore = (result && result.orgCultBreakdown)
    ? Math.max(...Object.values(result.orgCultBreakdown)) * 1.2
    : 1;
  const Chart = (size = 300) => (result && result.orgCultBreakdown) && (
    <RadarChart
      captions={{
        collaboration: 'Collaboration',
        competence: 'Competence',
        control: 'Control',
        cultivation: 'Cultivation',
      }}
      data={[
        {
          data: {
            collaboration: result.orgCultBreakdown.Collaboration / maxScore,
            competence: result.orgCultBreakdown.Competence / maxScore,
            control: result.orgCultBreakdown.Control / maxScore,
            cultivation: result.orgCultBreakdown.Cultivation / maxScore,
          },
          meta: { color: '#d07bea' },
        },
      ]}
      options={{
        captionMargin: 32,
        captionProps: () => ({
          textAnchor: 'middle',
          fontSize: 14,
        }),
      }}
      size={size}
    />
  );

  return (
    <div id="org-results" className="org-results">
      {breakdownOpen && (
        <div className="modal-overlay org-breakdown">
          <div className="modal">
            <div className="modal-header">
              <div className="modal-title">Breakdown</div>
              <button type="button" className="modal-close w-inline-block" onClick={() => setBreakdownOpen(false)} />
            </div>
            <div className="modal-content">
              <div className="title">
                {result.scoreText}
                &nbsp;Culture
              </div>
              <div className="culture-list">
                {breakdown[result.scoreText.toLowerCase()].map(t => (
                  <div key={t} className="list-item info">
                    <div className="dot-360 pink" />
                    <div className="body-txt">{t}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="result-card">
        <div className="card-header">
          <img src="images/test_icon_04.svg" alt="" className="org-icon" />
          <div className="card-title result">Organizational Culture</div>
        </div>
        <div className="result-card-div" />
        <div className="header-col-right">
          {testTaken && <div className="top-txt pink">{result.scoreText}</div>}
          {!testTaken && <div className="top-txt">{(result && result.open) ? 'In progress' : 'Not taken'}</div>}
          <button
            type="button"
            onClick={onShowInfo}
            className="results-more-btn pink w-inline-block"
          >
            <div>More info</div>
          </button>
        </div>
      </div>
      {testTaken && (
        <div className="org-details">
          <div className="org-card left">
            <div className="org-card-content">
              <div className="org-card-info">
                {nickname}
                &nbsp;prefers companies and organizations where:
              </div>
              <div className="org-card-divider" />
              <div className="org-card-grade">
                {result.scoreText}
                {' '}
                Culture is Dominant
              </div>
              <div className="org-card-tag">
                &quot;
                {result.scoreText === 'Cultivation' && 'Life is fascinating'}
                {result.scoreText === 'Control' && 'The system is most important, and big size counts'}
                {result.scoreText === 'Collaboration' && 'We are great, and the team comes first'}
                {result.scoreText === 'Competence' && 'We are excellent, and we can prove it'}
                &quot;
              </div>
              <div className="ei-chart-mob">{Chart(240)}</div>
              <button type="button" className="org-card-btn w-inline-block" onClick={() => setBreakdownOpen(true)}>
                <div>SEE BREAKDOWN</div>
              </button>
            </div>
          </div>
          <div className="org-card right">
            {Chart()}
          </div>
        </div>
      )}
    </div>
  );
};
