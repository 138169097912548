import React from 'react';

export default () => (
  <div
    data-w-id="54d22d6b-7662-e97a-80b9-161bd88c7fae"
    className="batch-card"
  >
    <div className="batch-month">
      {new Date().toLocaleString('default', { month: 'long' })}
      {' '}
      {new Date().getFullYear()}
    </div>
    <div className="batch-title">
      NORTH BATCH
      <br />
      OPEN NOW!
    </div>
    <div className="batch-slots">
      10 new membership
      <br />
      slots available.
    </div>
  </div>
);
