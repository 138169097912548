import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import AboutContent from './AboutContent';
import actions from '../../constants/actions';
import { isGhUser } from '../../selectors/user';

export default () => {
  const dispatch = useDispatch();
  const [tosPpChecked, setTosPpChecked] = useState(true);
  const ghUser = useSelector(isGhUser);

  useEffect(() => {
    if (ghUser) {
      ReactGA.event({
        category: 'lp-events',
        action: 'signup',
        label: 'signup',
      });
      // eslint-disable-next-line no-undef
      rdt('track', 'SignUp');
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
      ReactGA.event({
        category: 'lp-events',
        action: 'github-signup',
        label: 'github-signup',
      });
    }
    ReactGA.event({
      category: 'lp-events',
      action: 'qualified-signup',
      label: 'qualified-signup',
    });
  }, [ghUser]);

  return (
    <div>
      <div className="nav-bar">
        <div className="nav-bar-logo">
          <div className="nav-logo-box">
            <div className="logo-telepat w-inline-block" />
            <div className="logo-divider" />
          </div>
        </div>
      </div>
      <div className="page-wrapper welcome">
        <div className="page-content narrow">
          <AboutContent />
          <div className="terms-box">
            <div className="col-wrapper">
              <div className="terms-item">
                <button
                  type="button"
                  onClick={() => setTosPpChecked(!tosPpChecked)}
                >
                  <img
                    src={`images/check${tosPpChecked ? '_active' : ''}.png`}
                    width="20"
                    alt=""
                    className={`check-${tosPpChecked ? 'on' : 'off'}`}
                  />
                </button>
                <div className="body-txt">
                  I agree to the plain language&nbsp;
                  <a
                    href="https://app.termly.io/document/terms-of-use-for-website/c50d38cb-6021-4f5d-bb41-427943174a98"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-link"
                  >
                    TOS
                  </a>
                    &nbsp;&amp;&nbsp;
                  <a
                    href="https://app.termly.io/document/privacy-notice/f26ad3fe-eb29-4046-9d66-5239d3cfb2e5"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-link"
                  >
                    PP
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
          <div className="continue-box">
            <div className="divider" />
            <div className="continue-btns">
              <div className="continue-wrapper">
                {tosPpChecked && (
                  <Link
                    onClick={() => dispatch({
                      type: actions.USER_SET_PERMISSIONS,
                      profileUpdatesComm: false,
                      opportunitiesComm: false,
                    })}
                    to="/choose-nickname"
                    className="button skip w-inline-block"
                  >
                    <div>SKIP NOTIFICATIONS</div>
                  </Link>
                )}
                {!tosPpChecked && (
                  <div className="button skip inactive w-inline-block">
                    <div>SKIP NOTIFICATIONS</div>
                  </div>
                )}
                <div className="body-txt center">Don&#x27;t send me profile updates &amp; opportunities.</div>
              </div>
              <div className="continue-wrapper">
                {tosPpChecked && (
                  <Link
                    onClick={() => dispatch({
                      type: actions.USER_SET_PERMISSIONS,
                      profileUpdatesComm: true,
                      opportunitiesComm: true,
                    })}
                    to="/choose-nickname"
                    className="button continue w-inline-block"
                  >
                    <div>CONTINUE</div>
                  </Link>
                )}
                {!tosPpChecked && (
                  <div className="button continue inactive w-inline-block">
                    <div>CONTINUE</div>
                  </div>
                )}
                <div className="body-txt center">Send me profile updates &amp; opportunities.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
