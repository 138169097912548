import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import * as LpComponents from './partials/index';
import * as utils from '../../constants/utils';
import { getUserLogged, getUserLoading } from '../../selectors/user';

export default withRouter((props) => {
  const { history } = props;
  const { onRequestGithubLogin } = utils;

  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [particlesVisible, setParticlesVisible] = useState(false);

  const whyRef = useRef(null);
  const highlightsRef = useRef(null);
  const insOutsRef = useRef(null);

  const userData = useSelector(getUserLogged);
  const loading = useSelector(getUserLoading);

  if (userData && !loading) {
    history.replace('/tests');
  }

  const onShowLogin = () => {
    setLoginOpen(true);
  };
  const onHideLogin = () => {
    setLoginOpen(false);
  };
  const onShowSignup = () => {
    window.scrollTo(0, 0);
    setSignupOpen(true);
  };
  const onHideSignup = () => {
    setSignupOpen(false);
  };
  const onShowMenu = () => {
    setMenuOpen(true);
  };
  const onHideMenu = () => {
    setMenuOpen(false);
  };
  const onSwitchToLogin = () => {
    onHideSignup();
    onShowLogin();
  };
  const onSwitchToSignUp = () => {
    onHideLogin();
    onShowSignup();
  };
  const handleGithubRequest = () => {
    onRequestGithubLogin();
    onHideSignup();
    onHideLogin();
  };

  const onTopScrollChanged = (isVisible) => {
    setNavbarVisible(!isVisible);
  };

  const onParticlesChanged = (isVisible) => {
    setParticlesVisible(isVisible);
  };

  return (
    <div id="lp">
      <LpComponents.LoginModal
        loginOpen={loginOpen}
        onHideLogin={onHideLogin}
        onSwitchToSignUp={onSwitchToSignUp}
        handleGithubRequest={handleGithubRequest}
        history={history}
      />
      <LpComponents.SignupModal
        signupOpen={signupOpen}
        onHideSignup={onHideSignup}
        onSwitchToLogin={onSwitchToLogin}
        handleGithubRequest={handleGithubRequest}
        history={history}
      />
      <LpComponents.MobileMenu
        menuOpen={menuOpen}
        onHideMenu={onHideMenu}
        whyRef={whyRef}
        highlightsRef={highlightsRef}
        insOutsRef={insOutsRef}
      />
      <LpComponents.NavBarMenu
        navbarVisible={navbarVisible}
        onShowSignup={onShowSignup}
        onShowLogin={onShowLogin}
        onShowMenu={onShowMenu}
        onHideMenu={onHideMenu}
        whyRef={whyRef}
        highlightsRef={highlightsRef}
        insOutsRef={insOutsRef}
      />
      <button
        type="button"
        onClick={onShowMenu}
        className="nav-menu w-inline-block"
        data-ix="mob-show"
      />
      <LpComponents.BatchCard />
      <LpComponents.RegistrationButtons
        onShowSignup={onShowSignup}
        onShowLogin={onShowLogin}
      />
      <LpComponents.HomeHero onTopScrollChanged={onTopScrollChanged} />
      <LpComponents.WhyNorth
        whyRef={whyRef}
        onShowSignup={onShowSignup}
      />
      <LpComponents.Highlights
        highlightsRef={highlightsRef}
        onParticlesChanged={onParticlesChanged}
      />
      <LpComponents.NorthMatch />
      <LpComponents.InsOuts insOutsRef={insOutsRef} />
      <LpComponents.Join onShowSignup={onShowSignup} />
      <LpComponents.ParticlesBg
        isVisible={particlesVisible}
      />
      <VisibilitySensor onChange={onParticlesChanged} partialVisibility>
        <LpComponents.Footer />
      </VisibilitySensor>
    </div>
  );
});
