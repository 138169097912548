import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getTestsAvailabilityCount } from '../../selectors/tests';
import { getCurrentTestId } from '../../selectors/test';
import actions from '../../constants/actions';

export default ({ icon, type, color }) => {
  const dispatch = useDispatch();
  const availabilityCount = useSelector(getTestsAvailabilityCount);
  const testId = useSelector(getCurrentTestId);

  useEffect(() => {
    dispatch({
      type: actions.CLOSE_TEST,
      testId,
    });
    if (window.Intercom) {
      window.Intercom('trackEvent', `finished-${type}-test`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: 'Tests',
      action: 'completed-test',
      label: 'Completed Test',
    });
  }, []);

  return (
    <div className="full-bg">
      <div className="intro-card">
        <div className="content-wrapper">
          <img src={icon} width="84" alt="" />
          <div className="done-title">Done!</div>
          <div className="done-subtitle">
            {availabilityCount === 0 && (
              <div>
                Check out your
                <br />
                updated profile!
              </div>
            )}
            {availabilityCount > 0 && (
              <div>
                Complete the remaining&nbsp;
                {availabilityCount}
                &nbsp;tests
                <br />
                to determine your North Rank.
              </div>
            )}
          </div>
          <Link to="/tests" className={`done-btn w-inline-block ${color}`}>
            <img src="images/done_icon.png" width="22" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};
