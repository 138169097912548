import React from 'react';

export default () => (
  <div>
    <div className="home-header">
      <div className="welcome-title">Hello, fellow coder!</div>
      <p className="body-txt subtitle">We’ve been on a lifelong mission to enable people to build better software faster, by using science, automation and smarts.</p>
      <div className="founders-img" />
      <p className="body-txt">If you’ve done CVs, coding challenges, or blackboard interviews before, you know they often fail to capture the actual value that an individual can add to a specific team. So why don’t we try something quick and relevant for a change?</p>
    </div>
    <div className="process">
      <div className="body-txt subtitle med">Here’s what’s at the heart of our certification process:</div>
      <div className="process-col w-row">
        <div className="process-col-1 w-col w-col-6">
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">We mine scientific research for indicators of work performance.</div>
            </div>
          </div>
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">
                We curate a set of
                {' '}
                <strong>4 tests</strong>
                {' '}
                based on those indicators.
              </div>
            </div>
          </div>
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">We create your anonymous certification page based on your test results.</div>
            </div>
          </div>
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">You decide how much of your profile to share with the world.</div>
            </div>
          </div>
        </div>
        <div className="process-col-2 w-col w-col-6">
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">We send any contact / collaboration opportunities your way.</div>
            </div>
          </div>
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">We never misuse your data.</div>
            </div>
          </div>
          <div className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">At the end of each year, we crunch all the anonymous data &amp; publish a free research paper outlying a snapshot and identifying trends of the software dev landscape, for the benefit of the community.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="north-note">
      <div className="note-header">
        <div className="note-logo" />
      </div>
      <div className="note-content">
        <div className="note-title">The North 360 Tests</div>
        <div className="body-txt">
          Completing all four tests will get you on the fast-track queue for access to&nbsp;
          <strong>Telepat North</strong>
          &nbsp;- an exclusive club offering learning perks and professional guidance
          to extraordinary coders.
        </div>
      </div>
    </div>
    <div className="feedback-box">
      <div className="body-txt subtitle med">We’re always here for you and we’re listening to your feedback.</div>
      <div className="body-txt feedback">
        Please drop us a note at&nbsp;
        <a href="mailto:founders@telepat.io" className="inline-link">founders@telepat.io</a>
        &nbsp;or start a direct chat with us using the Intercom widget in the bottom right.
      </div>
    </div>
  </div>
);
