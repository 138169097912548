import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back text-only">
        <div className="nav-label red">General Mental Ability</div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="test-example">
          <div className="col-wrapper">
            <div className="ex-header-labels">
              <div>Sub-test 3/4</div>
              <div>Time limit:</div>
            </div>
            <div className="ex-header">
              <div className="title">Matrices</div>
              <div className="time">
                <img src="images/icon_time.png" width="12" alt="" className="time-icon blue" />
                <div className="time-txt blue">3 min</div>
              </div>
            </div>
            <p className="body-txt">
              In the Matrices sub-test, the task is to correctly complete the design
              or matrix presented at the left of each row.
              <br />
              <br />
              This measures generalization and discrimination skills.
            </p>
            <div className="test-tips example mob">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image completes the sequence?</div>
              <img src="images/tests/matrices_ex.png" width="130" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/matrices_ex_answers.png" width="240" alt="" />
            </div>
            <Link to="/tests/gma/section/3/run" className="button outline w-inline-block">
              <div>Begin the sub-test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="test-tips example">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image completes the sequence?</div>
              <img src="images/tests/matrices_ex.png" width="130" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/matrices_ex_answers.png" width="240" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
