import achievementTypes from '../constants/achievementTypes';

export const getAllAchievements = (fromProfile = false) => state => (
  (fromProfile ? state.profile.data : state.user.data) || {}
).achievements || [];

export const getCodeShowcaseSessions = (fromProfile = false) => state => getAllAchievements(
  fromProfile,
)(state).filter(a => a.type === achievementTypes.CODE_SHOWCASE);

export const getIntroVideos = (fromProfile = false) => state => getAllAchievements(
  fromProfile,
)(state).filter(a => a.type === achievementTypes.INTRO_VIDEO);

export const getSkillAssessments = (fromProfile = false) => state => getAllAchievements(
  fromProfile,
)(state).filter(a => a.type === achievementTypes.ASSESSMENT);

export const getAchievements = (fromProfile = false) => state => getAllAchievements(
  fromProfile,
)(state)
  .filter(
    a => a.type === achievementTypes.STACK_OVERFLOW
      || a.type === achievementTypes.CONTENT
      || a.type === achievementTypes.OSS_CONTRIBUTION
      || a.type === achievementTypes.OSS_PROJECT,
  );
