import React from 'react';

import ExpCard from '../../components/Tests/Experience/Card';
import ExpWhyModal from '../../components/Tests/Experience/WhyModal';
import ExpInfo from '../../components/Tests/Experience/Info';
import ExpSectionIntro from '../../components/Tests/Experience/SectionIntro';
import ExpDone from '../../components/Tests/Experience/Done';
import ExpResult from '../../components/Tests/Experience/Result';

import { IT_EXPERIENCE } from '../testTypes';

export default {
  type: IT_EXPERIENCE,
  slug: 'exp',
  title: 'Experience & Practices',
  color: 'green',
  iconSmall: 'images/test_icon_03.svg',
  iconLarge: 'images/test_icon_xl_03.svg',
  shortDescription: (
    <span>
      A
      {' '}
      <strong>technical self-assessment</strong>
      {' '}
      that will allow you to evaluate your expertise across several relevant
      work areas like: Databases, Frontend and Backend Frameworks, APIs,
      AI Tools or Big Data Services.
    </span>
  ),
  description: (
    <span>
      This is a technical self-assessment that will allow you to evaluate your expertise
      across several relevant work areas like: Databases, Frontend and Backend Frameworks,
      APIs, AI Tools, or Big Data Services.
    </span>
  ),
  resultInfo: (nickname, resultDate, nextDate) => (
    (
      <div className="body-txt">
        {nickname}
        &nbsp;self-assessed work abilities using Telepat’s
        Tech Capabilities questionnaire on&nbsp;
        {resultDate}
        .
        <br />
        <br />
        This test can be taken again starting on&nbsp;
        {nextDate}
        .
      </div>
    )
  ),
  completionTime: 10,
  tips: [
    'Pick a comfortable, quiet place to maximize your focus.',
    'You can take this test as many times you want.',
  ],
  studies: [
    {
      text: (
        <span>
          We find that task-relevant knowledge and skill mediates the relationship between
          prior related experience and job performance and that it acts as suppressing
          mediator of a negative direct relationship between prior related experience
          and current job performance.
        </span>),
      url: 'https://pubsonline.informs.org/doi/abs/10.1287/orsc.1080.0357',
    },
    {
      text: (
        <span>
          The results showed that amount and task‐level measures of work experience
          had the highest correlations with measures of job performance. Moreover,
          all three methods of measuring emotional intelligence increment cognitive
          ability and personality measures in the prediction of job performance.
        </span>
      ),
      url: 'https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1744-6570.1995.tb01785.x',
    },
  ],
  card: ExpCard,
  whyModal: ExpWhyModal,
  infoComponent: ExpInfo,
  doneComponent: ExpDone,
  resultCard: ExpResult,
  sections: [ExpSectionIntro],
  legacySkillMetadata: {
    Python: { logo: '01_exp_python.png' },
    Java: { logo: '02_exp_java.png' },
    JavaScript: { logo: '03_exp_javascript.png' },
    'C#': { logo: '04_exp_c_.png' },
    PHP: { logo: '05_exp_php.png' },
    'C / C++': { logo: '06_exp_c.png' },
    'Objective C / Swift': { logo: '07_exp_obj.png' },
    Ruby: { logo: '08_exp_ruby.png' },
    Go: { logo: '09_exp_go.png' },
    Kotlin: { logo: '10_exp_kotlin.png' },
    Optional: { logo: '00_exp_other.png' },
    React: { logo: '11_exp_react.png' },
    Angular: { logo: '12_exp_angular.png' },
    'Vue.js': { logo: '13_exp_vue.png' },
    MeteorJS: { logo: '14_exp_meteor.png' },
    Ember: { logo: '15_exp_ember.png' },
    Django: { logo: '16_exp_django.png' },
    Pyramid: { logo: '17_exp_pyramid.png' },
    'Ruby on Rails': { logo: '18_exp_rails.png' },
    Laravel: { logo: '19_exp_laravel.png' },
    CodeIgniter: { logo: '20_exp_igniter.png' },
    Symfony: { logo: '21_exp_symfony.png' },
    CakePHP: { logo: '22_exp_cake.png' },
    Yii: { logo: '23_exp_yii.png' },
    Wordpress: { logo: '24_exp_wp.png' },
    'Other preferred frontend frameworks?': { logo: '00_exp_other.png' },
    'Express.js': { logo: '25_exp_express.png' },
    Koa: { logo: '26_exp_koa.png' },
    'Sails.js': { logo: '27_exp_sails.png' },
    NestJS: { logo: '28_exp_nest.png' },
    LoopBack: { logo: '29_exp_lb.png' },
    'Hapi.js': { logo: '30_exp_hapi.png' },
    Spring: { logo: '31_exp_spring.png' },
    Grails: { logo: '32_exp_grails.png' },
    Bottle: { logo: '33_exp_bottle.png' },
    Flask: { logo: '34_exp_flask.png' },
    'Other preferred backend frameworks?': { logo: '00_exp_other.png' },
    Postman: { logo: '35_exp_postman.png' },
    Swagger: { logo: '36_exp_swagger.png' },
    'Networking using Curl': { logo: '37_exp_curl.png' },
    Curl: { logo: '37_exp_curl.png' },
    Nginx: { logo: '38_exp_nginx.png' },
    HAProxy: { logo: '39_exp_hp.png' },
    Traefik: { logo: '40_exp_traefik.png' },
    'Apache HTTP Server': { logo: '41_exp_apache.png' },
    Kong: { logo: '42_exp_kong.png' },
    MySQL: { logo: '43_exp_sql.png' },
    PostgreSQL: { logo: '44_exp_pg.png' },
    MongoDB: { logo: '45_exp_mongo.png' },
    Redis: { logo: '46_exp_redis.png' },
    Elasticsearch: { logo: '47_exp_elastic.png' },
    MariaDB: { logo: '48_exp_maria.png' },
    'Firebase Realtime Database / Firestore': { logo: '49_exp_fire_store.png' },
    'Amazon Redshift': { logo: '50_exp_redshift.png' },
    'Other preferred database systems?': { logo: '00_exp_other.png' },
    'The Google Maps API': { logo: '51_exp_gmaps.png' },
    'Google Maps': { logo: '51_exp_gmaps.png' },
    'The Facebook Graph API': { logo: '52_exp_fb_graph.png' },
    'Facebook Graph': { logo: '52_exp_fb_graph.png' },
    'The Twitter API': { logo: '53_exp_twitter.png' },
    Twitter: { logo: '53_exp_twitter.png' },
    'The Twilio API': { logo: '54_exp_twillio.png' },
    Twilio: { logo: '54_exp_twillio.png' },
    'The AWS API': { logo: '55_exp_aws.png' },
    'The Stripe API': { logo: '56_exp_stripe.png' },
    Stripe: { logo: '56_exp_stripe.png' },
    'The Segment API': { logo: '57_exp_segment.png' },
    Segment: { logo: '57_exp_segment.png' },
    'The Google Analytics API': { logo: '58_exp_ganalytics.png' },
    'Google Analytics': { logo: '58_exp_ganalytics.png' },
    'The Firebase API': { logo: '59_exp_firebase.png' },
    Firebase: { logo: '59_exp_firebase.png' },
    'The Mailchimp API': { logo: '60_exp_mailchimp.png' },
    Mailchimp: { logo: '60_exp_mailchimp.png' },
    'The Sendgrid API': { logo: '61_exp_sendgrid.png' },
    Sendgrid: { logo: '61_exp_sendgrid.png' },
    "Other popular APIs you've implemented:": { logo: '00_exp_other.png' },
    Jenkins: { logo: '62_exp_jenkins.png' },
    'Circle CI': { logo: '63_exp_circle.png' },
    'Gitlab CI': { logo: '64_exp_gitlab.png' },
    Travis: { logo: '65_exp_travis.png' },
    CodeShip: { logo: '66_exp_cship.png' },
    TeamCity: { logo: '67_exp_team_city.png' },
    'Other preferred CI systems?': { logo: '00_exp_other.png' },
    'React Native': { logo: '68_exp_react_native.png' },
    Ionic: { logo: '69_exp_ionic.png' },
    'Framework 7': { logo: '70_exp_f7.png' },
    'PhoneGap / Cordova': { logo: '71_exp_cordova.png' },
    'Onsen UI': { logo: '72_exp_osen.png' },
    Xamarin: { logo: '73_exp_xam.png' },
    Flutter: { logo: '74_esp_flutter.png' },
    'Apache Hadoop': { logo: '75_exp_hadoop.png' },
    'Apache Storm': { logo: '76_exp_storm.png' },
    'Apache Spark': { logo: '77_exp_spark.png' },
    'Apache Cassandra': { logo: '78_exp_cass.png' },
    MapReduce: { logo: '79_exp_mr.png' },
    'AWS Aurora/Glue/EMR': { logo: '80_exp_aurora.png' },
    'Google BigQuery': { logo: '81_exp_bigq.png' },
    Hive: { logo: '82_exp_hive.png' },
    Neo4j: { logo: '83_exp_neo.png' },
    TensorFlow: { logo: '84_exp_tf.png' },
    'Amazon Lex': { logo: '85_exp_lex.png' },
    'Microsoft Cognitive Services': { logo: '86_exp_mcs.png' },
    Keras: { logo: '87_exp_keras.png' },
    PyTorch: { logo: '88_exp_pytorch.png' },
    'Other preferred AI tools/services?': { logo: '00_exp_other.png' },
    Docker: { logo: '89_exp_docker.png' },
    Kubernetes: { logo: '90_exp_kubernetes.png' },
    Chef: { logo: '91_exp_chef.png' },
    Puppet: { logo: '92_exp_puppet.png' },
    Bamboo: { logo: '93_exp_bamboo.png' },
    Vagrant: { logo: '94_exp_vagrant.png' },
    PagerDuty: { logo: '95_exp_pd.png' },
    Nagios: { logo: '96_exp_nagios.png' },
    Consul: { logo: '97_exp_consul.png' },
    'New Relic': { logo: '98_exp_nr.png' },
    'Other preferred DevOps tools?': { logo: '00_exp_other.png' },
    AWS: { logo: '99_exp_aws.png' },
    'Google Cloud': { logo: '100_exp_gcloud.png' },
    'Microsoft Azure': { logo: '101_exp_azure.png' },
    Heroku: { logo: '102_exp_heroku.png' },
    'Your Preferred CSS framework': { name: 'CSS Frameworks' },
    'Leveraging Chrome DevTools & Similar Tools': { name: 'Chrome DevTools' },
    'Chrome DevTools & similar': { name: 'Chrome DevTools' },
    'Rate your Git experience': { name: 'Git', logo: '104_exp_git.png' },
    'Module Bundlers (like Webpack, Parcel, etc.)': { name: 'JS Module Bundlers' },
    'your preferred bundler (Webpack, Rollbar, etc.)': { name: 'JS Module Bundlers' },
    'Manipulating the DOM directly via JS': { name: 'DOM Manipulation' },
    jQuery: { logo: '103_exp_jquery.png' },
  },
};
