import React from 'react';

export default () => (
  <div className="main-tabs">
    <div className="tests-tab">
      <div className="tab-number" />
      <div className="tab-title active">NORTH&nbsp;360 TESTS</div>
    </div>
    <div className="xp-tab">
      <div className="tab-name">
        <div className="tab-number _2-locked" />
        <div className="tab-title gray">NORTH EXPERIENCE</div>
      </div>
      <div className="xp-group">
        <div className="xp-number">
          <div className="xp-value">0</div>
          <div className="xp-tag">XP</div>
        </div>
        <div className="xp-bar">
          <div className="xp-dot left-inactive" />
          <div className="xp-bar-bg _30" />
          <div className="unlock-btn">
            <img src="images/xp_icon_lock.svg" alt="" className="xp-lock-icon" />
            <div className="xp-dot locked" />
            <div className="xp-unlock-no">30</div>
          </div>
          <div className="xp-bar-bg _20" />
          <div className="unlock-btn">
            <img src="images/xp_icon_lock.svg" alt="" className="xp-lock-icon" />
            <div className="xp-dot locked" />
            <div className="xp-unlock-no">50</div>
          </div>
          <div className="xp-bar-bg _20" />
          <div className="xp-dot right-inactive" />
        </div>
        <div className="xp-number">
          <div className="xp-value">70</div>
          <div className="xp-tag">XP</div>
        </div>
      </div>
    </div>
  </div>
);
