import React, { useState } from 'react';

import { STACK_OVERFLOW } from '../../../constants/achievementTypes';

export default ({
  setStackOverflowModalVisible,
  submitAchievement,
}) => {
  const [link, setLink] = useState('');

  return (
    <div className="modal-overlay stack">
      <div className="modal no-scroll narrow">
        <div className="modal-header">
          <div className="modal-title">Stack Overflow Stats</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => setStackOverflowModalVisible(false)}
          />
        </div>
        <div className="modal-content no-scroll">
          <div className="modal-input-wrapper">
            <div className="body-txt">Send us a link to your Stack Overflow profile.</div>
            <div className="input-area skill">
              <div className="input-group">
                <div className="input-label">Stack Overflow link</div>
                <input
                  type="text"
                  placeholder="eg. http://stackoverflow.com/user"
                  className="modal-input"
                  value={link}
                  onChange={event => setLink(event.target.value)}
                />
              </div>
            </div>
            <button
              className="modal-btn w-inline-block"
              type="button"
              onClick={() => submitAchievement({
                type: STACK_OVERFLOW,
                urls: [link],
              })}
            >
              <div>Submit Achievement</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
