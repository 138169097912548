import React from 'react';

import TestCard from '../TestCard';
import { EIData } from '../../../constants/testData';

export default params => (
  <TestCard
    title={EIData.title}
    iconSmall={EIData.iconSmall}
    iconLarge={EIData.iconLarge}
    shortDescription={EIData.shortDescription}
    completionTime={EIData.completionTime}
    slug={EIData.slug}
    color={EIData.color}
    {...params}
  />
);
