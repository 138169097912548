import React from 'react';

export default ({ mobile = false, points = 0 }) => (
  <div className={`xp-group ${mobile ? 'mob' : ''}`}>
    <div className="xp-number">
      <div className="xp-value active">
        {points}
      </div>
      <div className="xp-tag active">XP</div>
    </div>
    <div className="xp-bar">
      <div className="xp-dot zero-dot active left-active" />
      <div className="xp-bar-bg _30">
        <div
          className="active-xp-bar"
          style={{
            width: `${(points < 30 ? points : 30) * (3 + 1 / 3)}%`,
          }}
        />
      </div>
      <div className="unlock-dot w-inline-block">
        <div className="tooltip-group-30">
          <div className="tooltip">
            <div className="tooltip-wrapper">
              <div className="tooltip-line" />
              <div className="tooltip-txt-360">Access to books, courses &amp; certifications via the O’Reilly Learning Platform.</div>
            </div>
          </div>
          <img src="images/tooltip_arrow.svg" alt="" />
        </div>
        <div className="unlock-btn">
          {points < 30 && (<img src="images/xp_icon_lock.svg" alt="" className="xp-lock-icon" />)}
          {points < 30 && (<img src="images/xp_icon_lock_hover.svg" alt="" className="xp-lock-icon-hover" />)}
          {points >= 30 && (<img src="images/xp_check_icon.svg" alt="" className="xp-bar-check-hover" />)}
          {points >= 30 && (<img src="images/xp_check_icon_gray.svg" alt="" className="xp-bar-check" />)}
          <div className={`xp-dot ${points >= 30 ? 'center-active' : ''}`} />
          <div className={`xp-unlock-no ${points >= 30 ? 'active' : ''}`}>30</div>
        </div>
      </div>
      <div className="xp-bar-bg _20">
        <div
          className="active-xp-bar"
          style={{
            width: `${
              points < 30
                ? 0
                : ((points - 30) < 20 ? (points - 30) : 20) * 5
            }%`,
          }}
        />
      </div>
      <div className="unlock-dot w-inline-block">
        <div className="tooltip-group-50">
          <div className="tooltip _50">
            <div className="tooltip-wrapper">
              <div className="tooltip-line" />
              <div className="tooltip-txt-360">Two one-to-one professional coaching sessions with our senior members.</div>
            </div>
          </div>
          <img src="images/tooltip_arrow.svg" alt="" />
        </div>
        <div className="unlock-btn">
          {points < 50 && (<img src="images/xp_icon_lock.svg" alt="" className="xp-lock-icon" />)}
          {points < 50 && (<img src="images/xp_icon_lock_hover.svg" alt="" className="xp-lock-icon-hover" />)}
          {points >= 50 && (<img src="images/xp_check_icon.svg" alt="" className="xp-bar-check-hover" />)}
          {points >= 50 && (<img src="images/xp_check_icon_gray.svg" alt="" className="xp-bar-check" />)}
          <div className={`xp-dot ${points >= 50 ? 'center-active' : ''}`} />
          <div className={`xp-unlock-no ${points >= 50 ? 'active' : ''}`}>50</div>
        </div>
      </div>
      <div className="xp-bar-bg _20">
        <div
          className="active-xp-bar"
          style={{
            width: `${
              points < 50
                ? 0
                : ((points - 50) < 20 ? (points - 50) : 20) * 5
            }%`,
          }}
        />
      </div>
      <div className={`xp-dot right-inactive ${points >= 70 ? 'active ' : ''}`} />
    </div>
    <div className="xp-number">
      <div className={`xp-value ${points >= 70 ? 'active ' : ''}`}>70</div>
      <div className={`xp-tag ${points >= 70 ? 'active ' : ''}`}>XP</div>
    </div>
  </div>
);
