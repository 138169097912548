import React from 'react';

import TestCard from '../TestCard';
import { ExpData } from '../../../constants/testData';

export default params => (
  <TestCard
    title={ExpData.title}
    iconSmall={ExpData.iconSmall}
    iconLarge={ExpData.iconLarge}
    shortDescription={ExpData.shortDescription}
    completionTime={ExpData.completionTime}
    slug={ExpData.slug}
    color={ExpData.color}
    {...params}
  />
);
