import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back text-only">
        <div className="nav-label red">General Mental Ability</div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="test-example">
          <div className="col-wrapper">
            <div className="ex-header-labels">
              <div>Sub-test 4/4</div>
              <div>Time limit:</div>
            </div>
            <div className="ex-header">
              <div className="title">Conditions</div>
              <div className="time">
                <img src="images/icon_time.png" width="12" alt="" className="time-icon blue" />
                <div className="time-txt blue">2.5 min</div>
              </div>
            </div>
            <p className="body-txt">
              The final sub-test, Conditions (or Topology), requires you to select, from the
              five choices, the one which duplicates the conditions given in the first box.
              <br />
              <br />
              Spatial perception and mental rotation are tested here.
            </p>
            <div className="test-tips example mob">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image would allow you to place a dot in a similar way?</div>
              <img src="images/tests/conditions_ex.png" width="60" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/conditions_ex_answers.png" width="240" alt="" />
            </div>
            <Link to="/tests/gma/section/4/run" className="button outline w-inline-block">
              <div>Begin the sub-test</div>
            </Link>
          </div>
          <div className="col-wrapper right">
            <div className="test-tips example">
              <div className="example-title">EXAMPLE</div>
              <div className="example-subtitle">Which image would allow you to place a dot in a similar way?</div>
              <img src="images/tests/conditions_ex.png" width="60" alt="" />
              <div className="example-subtitle">Correct answer:</div>
              <img src="images/tests/conditions_ex_answers.png" width="240" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer narrow />
  </div>
);
