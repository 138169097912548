/* eslint-disable no-nested-ternary */
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default ({ answers, currentAnswer: [currentAnswer], setAnswer }) => (
  <VisibilitySensor
    onChange={(isVisible) => {
      if (isVisible && !currentAnswer) {
        setAnswer(answers[0]._id);
      }
    }}
  >
    <div className="rating-stars-wrapper">
      <div className="rating-stars">
        <button
          type="button"
          className={`rating-star-btn w-inline-block ${
            (currentAnswer && currentAnswer !== answers[0]._id) ? 'filled' : ''
          }`}
          onClick={() => setAnswer(
            currentAnswer === answers[1]._id ? [answers[0]._id] : [answers[1]._id],
          )}
        />
        <button
          type="button"
          className={`rating-star-btn w-inline-block ${
            (currentAnswer === answers[2]._id || currentAnswer === answers[3]._id) ? 'filled' : ''
          }`}
          onClick={() => setAnswer([answers[2]._id])}
        />
        <button
          type="button"
          className={`rating-star-btn w-inline-block ${
            currentAnswer === answers[3]._id ? 'filled' : ''
          }`}
          onClick={() => setAnswer([answers[3]._id])}
        />
      </div>
      <div className={`rating-txt ${(!currentAnswer || currentAnswer === answers[0]._id) ? 'no-exp' : ''}`}>
        {
          currentAnswer === answers[1]._id ? 'Beginner'
            : currentAnswer === answers[2]._id ? 'Experienced'
              : currentAnswer === answers[3]._id ? 'Pro'
                : 'No Experience'
        }
      </div>
    </div>
  </VisibilitySensor>
);
