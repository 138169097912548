import React, { useState } from 'react';

import { CONTENT } from '../../../constants/achievementTypes';

export default ({
  setContentModalVisible,
  submitAchievement,
}) => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  return (
    <div className="modal-overlay add-content">
      <div className="modal no-scroll narrow">
        <div className="modal-header">
          <div className="modal-title">Content Creator</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => setContentModalVisible(false)}
          />
        </div>
        <div className="modal-content no-scroll">
          <div className="modal-input-wrapper">
            <div className="input-area">
              <div className="input-group">
                <div className="input-label">Content title</div>
                <input
                  className="modal-input"
                  type="text"
                  placeholder="eg. The super Duper Project"
                  value={title}
                  onChange={event => setTitle(event.target.value)}
                />
              </div>
              <div className="input-group">
                <div className="input-label">Content link</div>
                <input
                  className="modal-input"
                  type="text"
                  placeholder="eg. http://github.com/project"
                  value={link}
                  onChange={event => setLink(event.target.value)}
                />
              </div>
            </div>
            <button
              className="modal-btn w-inline-block"
              type="button"
              onClick={() => submitAchievement({
                type: CONTENT,
                name: title,
                urls: [link],
              })}
            >
              <div>Submit Achievement</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
