import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as values from 'object.values';
import { hotjar } from 'react-hotjar';

import * as allTestData from '../../constants/testData';
import actions from '../../constants/actions';
import { getNickname, getUserData } from '../../selectors/user';
import { getTestsAvailability, getTestsAvailabilityDates, getTestsData } from '../../selectors/tests';

import Footer from './Footer';
import Header from './Header';
import TestsSubmittedModal from './Modals/TestsSubmittedModal';
import RanksInfoModal from './Modals/RanksInfoModal';
import AspirantInfoModal from './Modals/AspirantInfoModal';
import NoRankInfoBar from './InfoBars/NoRankInfoBar';
import RankPendingInfoBar from './InfoBars/RankPendingInfoBar';
import AspirantInfoBar from './InfoBars/AspirantInfoBar';
import CadetInfoBar from './InfoBars/CadetInfoBar';
import CadetInfoModal from './Modals/CadetInfoModal';
import StarInfoBar from './InfoBars/StarInfoBar';
import StarInfoModal from './Modals/StarInfoModal';
import SuperstarInfoBar from './InfoBars/SuperstarInfoBar';
import JourneyBarLocked from './InfoBars/JourneyBarLocked';
import JourneyBar from './InfoBars/JourneyBar';
import CodeShowcase from '../Achievements/CodeShowcase';
import IntroVideo from '../Achievements/IntroVideo';
import SkillAssessments from '../Achievements/SkillAssessments';
import Achievements from '../Achievements/Achievements';
import CadetProgress from './InfoBars/CadetProgress';

const NORTH_RANKS = ['cadet', 'star', 'superstar'];
const MODALS = {
  RANK: 'RANK',
  RANK_PENDING: 'RANK_PENDING',
  ASPIRANT_INFO: 'ASPIRANT_INFO',
  CADET_INFO: 'CADET_INFO',
  STAR_INFO: 'STAR_INFO',
};

export default () => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  if (!Object.values) {
    values.shim();
  }
  if (!window.hotjar_initialized) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
    window.hotjar_initialized = true;
  }

  useEffect(() => {
    dispatch({ type: actions.LOAD_TESTS });
    // eslint-disable-next-line
  }, []);

  const nickname = useSelector(getNickname);
  const {
    nickname: urlNickname,
    rank = 'undetermined',
    points = 0,
    avatar = [1, '#0099dd'],
  } = useSelector(getUserData);

  const testData = useSelector(getTestsData);
  const testAvailability = useSelector(getTestsAvailability);
  const testDates = useSelector(getTestsAvailabilityDates);
  const availability = Object.keys(testAvailability)
    .reduce((a, key) => ({
      ...a,
      [key]: {
        available: testAvailability[key],
        dates: testDates[key],
        mandatory: testData.find(t => t.type === key).mandatory,
        card: Object.values(allTestData).find(t => t.type === key).card,
      },
    }), {});

  const mandatoryTestsCount = testData.filter(a => a.mandatory).length;
  const mandatoryTestsDoneCount = Object.keys(availability).length > 0
    ? Object
      .values(availability)
      .filter(a => a.mandatory)
      .reduce((a, t) => a + (t.available ? 0 : 1), 0)
    : null;
  const mandatoryTestsDone = mandatoryTestsCount === mandatoryTestsDoneCount;

  const [visibleTab, setVisibleTab] = useState(NORTH_RANKS.includes(rank) ? 1 : 0);

  useEffect(() => {
    if (mandatoryTestsDoneCount !== null) {
      const testsDoneBefore = parseInt(localStorage.getItem('northTestsDone'), 10) || mandatoryTestsCount;
      if (
        testsDoneBefore < mandatoryTestsCount && mandatoryTestsDone
      ) {
        setModalVisible(MODALS.RANK_PENDING);
      }
      localStorage.setItem('northTestsDone', mandatoryTestsDoneCount);
    }
  }, [mandatoryTestsDoneCount, mandatoryTestsCount, mandatoryTestsDone]);

  const testElements = [];

  for (let i = 0; i < Object.keys(availability).length; i += 2) {
    const testOne = availability[Object.keys(availability)[i]];
    const testTwo = Object.keys(availability)[i + 1]
      && availability[Object.keys(availability)[i + 1]];

    testElements.push((
      <div key={`test${i}`} className={`test-cards ${i === 0 ? 'top' : ''}`}>
        {testOne.card({ done: !testOne.available, dates: testOne.dates, className: 'left' })}
        {testTwo && testTwo.card({ done: !testTwo.available, dates: testTwo.dates })}
      </div>
    ));
  }

  return (
    <div>
      <Header />
      {modalVisible === MODALS.RANK_PENDING && (
        <TestsSubmittedModal closeModal={() => setModalVisible(false)} />
      )}
      {modalVisible === MODALS.RANK && (
        <RanksInfoModal closeModal={() => setModalVisible(false)} />
      )}
      {modalVisible === MODALS.ASPIRANT_INFO && (
        <AspirantInfoModal closeModal={() => setModalVisible(false)} />
      )}
      {modalVisible === MODALS.CADET_INFO && (
        <CadetInfoModal closeModal={() => setModalVisible(false)} />
      )}
      {modalVisible === MODALS.STAR_INFO && (
        <StarInfoModal closeModal={() => setModalVisible(false)} />
      )}
      <div className="page-wrapper gray">
        <Link to={`/p/${urlNickname}`} className={`preview-btn-mob w-inline-block ${mandatoryTestsDone ? '' : 'locked'}`}>
          <div className="preview-btn-wrapper">
            <div>Preview your North Profile</div>
            {!mandatoryTestsDone && <img src="images/xp_icon_lock.svg" alt="" className="preview-lock" />}
            {mandatoryTestsDone && <img src="images/preview_icon.svg" alt="" className="preview-icon" />}
          </div>
        </Link>
        <div className="page-content">
          <div className="username-header">
            <div className="username-group">
              <img
                src={`images/avatars/avatar_${avatar[0]}.png`}
                alt=""
                style={{
                  width: 90,
                  backgroundColor: avatar[1],
                  borderRadius: 45,
                }}
              />
              <div className="username-info">
                <div className="username-txt">{nickname}</div>
                {rank === 'undetermined' && (
                  <div>
                    {!mandatoryTestsDone
                      && <NoRankInfoBar openModal={() => setModalVisible(MODALS.RANK)} />}
                    {mandatoryTestsDone && <RankPendingInfoBar />}
                  </div>
                )}
                {rank === 'aspirant'
                  && <AspirantInfoBar openModal={() => setModalVisible(MODALS.ASPIRANT_INFO)} />}
                {rank === 'cadet'
                  && <CadetInfoBar openModal={() => setModalVisible(MODALS.CADET_INFO)} />}
                {rank === 'star'
                  && <StarInfoBar openModal={() => setModalVisible(MODALS.STAR_INFO)} />}
                {rank === 'superstar'
                  && <SuperstarInfoBar />}
              </div>
            </div>
            <div className="username-actions">
              <Link
                to={`/p/${urlNickname}`}
                className="card-btn profile w-inline-block"
              >
                <div>Preview Profile</div>
              </Link>
            </div>
          </div>
          {!NORTH_RANKS.includes(rank) && <JourneyBarLocked />}
          {NORTH_RANKS.includes(rank) && (
            <JourneyBar
              visibleTab={visibleTab}
              setVisibleTab={setVisibleTab}
              rank={rank}
              points={points}
            />
          )}
          {visibleTab === 1 && rank === 'cadet' && (
            <div className="xp-steps-mob-wrapper">
              <div className="xp-steps-mob">
                <CadetProgress mobile points={points} />
              </div>
            </div>
          )}
          {visibleTab === 1 && (['star', 'superstar'].includes(rank)) && (
            <div className="xp-steps-mob-wrapper star">
              <div className="xp-steps-mob star">
                <div className="xp-total-txt">TOTAL&nbsp;EXPERIENCE</div>
                <div className="xp-number">
                  <div className="xp-value active">{points}</div>
                  <div className="xp-tag active">XP</div>
                </div>
              </div>
            </div>
          )}
          <div className="xp-div" />

          {visibleTab === 0 && (
            <div>
              <div className="section-header">
                <div className="section-title">North 360 Tests</div>
                <div className="section-body-txt">
                  Unlock the&nbsp;
                  <strong>North Experience</strong>
                  &nbsp; by completing these four tests in just&nbsp;
                  <strong>40-50 minutes</strong>
                  . We&apos;ll build you an anonymous profile based on a wide snapshot
                  of your professional experience, cognitive and emotional ability,
                  as well as your preferred work culture and values.
                </div>
              </div>
              {testElements}
            </div>
          )}
          {visibleTab === 1 && (
            <div>
              <CodeShowcase />
              <div className="section-divider-360" />
              <IntroVideo />
              <div className="section-divider-360" />
              <SkillAssessments />
              <div className="section-divider-360" />
              <Achievements />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
