import React from 'react';

export default () => (
  <div id="match" className="match-wrapper">
    <div className="match-content">
      <div className="div-title-wrapper">
        <div className="title-div" />
        <div className="col-header dark">Is Telepat North for you?</div>
      </div>
      <div className="col-list-title dark match">The answer is YES if:</div>
      <div className="columns match">
        <div className="col-left">
          <div className="stage-list">
            <div className="col-list-bullet dark" />
            <div className="stage-list-txt-wrapper">
              <div className="col-txt dark">
                You are a constant learner and committed to personal
                development.
              </div>
            </div>
          </div>
          <div className="stage-list">
            <div className="col-list-bullet dark" />
            <div className="stage-list-txt-wrapper">
              <div className="col-txt dark">
                You align as an individual to the values in our
                {' '}
                <a href="/#/code" className="col-txt-link dark">
                  Code
                </a>
                .
              </div>
            </div>
          </div>
          <div className="stage-list">
            <div className="col-list-bullet dark" />
            <div className="stage-list-txt-wrapper">
              <div className="col-txt dark">
                You want to better your skills in the areas of:
                communication, personal growth, etc..
              </div>
            </div>
          </div>
        </div>
        <div className="col-right match">
          <div className="stage-list">
            <div className="col-list-bullet dark" />
            <div className="stage-list-txt-wrapper">
              <div className="col-txt dark">
                You are willing to give back to the developer community.
              </div>
            </div>
          </div>
          <div className="stage-list last">
            <div className="col-list-bullet dark" />
            <div className="stage-list-txt-wrapper">
              <div className="col-txt dark">
                You believe in remote work and its benefits.
              </div>
            </div>
          </div>
          <div className="col-label match">
            You can find more answers to similar questions in our Handbook
            and FAQ section bellow.
          </div>
        </div>
      </div>
    </div>
  </div>
);
