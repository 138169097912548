import React from 'react';

export default ({ answers, currentAnswer, setAnswer }) => (
  <div className="exp-options">
    {answers.map(answer => (
      <button
        key={answer._id}
        className={`test-option w-inline-block ${currentAnswer.includes(answer._id) ? 'selected' : ''}`}
        onClick={() => setAnswer(answer._id)}
        type="button"
      >
        {currentAnswer.includes(answer._id) && (
          <img src="images/check_active.png" width="20" alt="" className="check-on" />
        )}
        {!currentAnswer.includes(answer._id) && (
          <img src="images/check.png" width="20" alt="" className="check-off" />
        )}
        <div>{answer.text}</div>
      </button>
    ))}
  </div>
);
