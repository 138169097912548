import React, { useState } from 'react';

export default (props) => {
  const { result, onShowInfo } = props;
  const testTaken = result && !result.open;
  const [modalData, setModalData] = useState(null);

  return (
    <div id="ei-results" className="ei-results">
      {modalData && (
        <div className="modal-overlay ei-details">
          <div className="modal no-scroll narrow">
            <div className="modal-header auto">
              <div className="modal-title ei-txt">{modalData.title}</div>
              <button type="button" className="modal-close w-inline-block" onClick={() => setModalData(null)} />
            </div>
            <div className="modal-content no-scroll">
              <div className="body-txt">{modalData.content}</div>
            </div>
          </div>
        </div>
      )}
      <div className="result-card">
        <div className="card-header">
          <img src="images/test_icon_02.svg" alt="" className="ei-icon" />
          <div className="card-title result">Emotional Intelligence</div>
        </div>
        <div className="result-card-div" />
        <div className="header-col-right">
          {testTaken && (<div className="top-txt purple">{result.percentileText}</div>)}
          {!testTaken && (<div className="top-txt">{(result && result.open) ? 'In progress' : 'Not taken'}</div>)}
          <button
            type="button"
            onClick={onShowInfo}
            className="results-more-btn w-inline-block purple"
          >
            <div>More info</div>
          </button>
        </div>
      </div>
      {testTaken && result.qualifiedEiDomains && (
        <div className="ei-breakdown">
          <div className="ei-cards-01">
            <div className="ei-card left">
              <div className="ei-card-title">
                Perceiving
                <br />
                Emotion
              </div>
              <img src="images/ei_icon_01.svg" alt="" />
              <div className="ei-card-grade">{result.qualifiedEiDomains.P.qualifiedValue}</div>
              <button
                type="button"
                className="ei-info-btn w-inline-block"
                onClick={() => setModalData({
                  title: 'Perceiving Emotion',
                  content: 'Perceiving emotion pertains to the ability to identify emotions in oneself and others, as well as in other stimuli, including voices, stories, music, and works of art.',
                })}
              />
            </div>
            <div className="ei-card right">
              <div className="ei-card-title">
                Use of
                <br />
                Emotion
              </div>
              <img src="images/ei_icon_02.svg" alt="" />
              <div className="ei-card-grade">{result.qualifiedEiDomains.U.qualifiedValue}</div>
              <button
                type="button"
                className="ei-info-btn w-inline-block"
                onClick={() => setModalData({
                  title: 'Use of Emotion',
                  content: 'Using emotion involves the ability to harness feelings that assist in certain cognitive enterprises, such as reasoning, problem solving, decision making, and interpersonal communication.',
                })}
              />
            </div>
          </div>
          <div className="ei-cards-02">
            <div className="ei-card left">
              <div className="ei-card-title">
                Understanding
                <br />
                Emotion
              </div>
              <img src="images/ei_icon_03.svg" alt="" />
              <div className="ei-card-grade">{result.qualifiedEiDomains.R.qualifiedValue}</div>
              <button
                type="button"
                className="ei-info-btn w-inline-block"
                onClick={() => setModalData({
                  title: 'Understanding Emotion',
                  content: 'This skill includes an understanding of the emotional lexicon; the manner in which emotions combine, progress, transition from one to the other; and the outcomes of emotional experiences.',
                })}
              />
            </div>
            <div className="ei-card right">
              <div className="ei-card-title">
                Managing
                <br />
                Self Emotion
              </div>
              <img src="images/ei_icon_04.svg" alt="" />
              <div className="ei-card-grade">{result.qualifiedEiDomains.M1.qualifiedValue}</div>
              <button
                type="button"
                className="ei-info-btn w-inline-block"
                onClick={() => setModalData({
                  title: 'Managing Self Emotion',
                  content: 'Managing self emotion pertains to the ability to reduce, enhance, or modify an emotional response in oneself as well as the ability to experience a range of emotions while also making decisions about the appropriateness or usefulness of the emotion in a given situation.',
                })}
              />
            </div>
          </div>
          <div className="ei-cards-03">
            <div className="ei-card left">
              <div className="ei-card-title">
                Social
                <br />
                Management
              </div>
              <img src="images/ei_icon_05.svg" alt="" />
              <div className="ei-card-grade">{result.qualifiedEiDomains.M2.qualifiedValue}</div>
              <button
                type="button"
                className="ei-info-btn w-inline-block"
                onClick={() => setModalData({
                  title: 'Social Management',
                  content: 'Social management pertains to the ability to reduce, enhance, or modify an emotional response in others as well as the ability to experience a range of emotions while also making decisions about the appropriateness or usefulness of the emotion in a given situation.',
                })}
              />
            </div>
            <div className="ei-card total-mob">
              <div className="ei-card-title total">
                Average
                <br />
                Score
              </div>
              <img src="images/ei_icon_06.svg" alt="" />
              <div className="ei-card-grade">{result.percentileText}</div>
              <div className="ei-total-divider" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
