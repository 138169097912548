import React from 'react';

import TestDone from '../TestDone';
import { OrgData } from '../../../constants/testData';

export default params => (
  <TestDone
    icon={OrgData.iconLarge}
    type={OrgData.slug}
    color={OrgData.color}
    {...params}
  />
);
