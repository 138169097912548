import {
  put, takeEvery, all, call, select,
} from 'redux-saga/effects';
import momentTimezone from 'moment-timezone';
import ReactGA from 'react-ga';

import actions from '../constants/actions';
import API from '../services/api';
import AttributionCookie from '../services/attributionCookie';
import { validateEmail } from '../constants/utils';

const JWT_LOCAL_STORAGE_KEY = 'telepat.certify.jwt';

export function* jwtLogin(action) {
  try {
    const { data } = yield call(API.getUser, action.jwt);
    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, action.jwt);
    yield put({ type: actions.JWT_LOGIN_SUCCESS, data, jwt: action.jwt });
  } catch (error) {
    yield put({ type: actions.JWT_LOGIN_ERROR, error: error.message });
  }
}

export function* reloadUser() {
  try {
    const jwt = yield select(state => state.user.jwt);
    const { data } = yield call(API.getUser, jwt);
    yield put({ type: actions.RELOAD_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.RELOAD_USER_ERROR, error: error.message });
  }
}

export function* emailSignup(action) {
  try {
    if (!action.captchaToken) {
      yield put({ type: actions.SIGNUP_ERROR, error: 'Please verify you are not a robot' });
      return;
    }
    if (!validateEmail(action.email)) {
      yield put({ type: actions.SIGNUP_ERROR, error: 'Please enter a valid email address' });
      return;
    }
    const attributionData = AttributionCookie.get();
    const { data } = yield call(API.emailSignup, action.email, action.password, attributionData);
    const { url, status, error } = data;
    if (status === 'ok') {
      // eslint-disable-next-line no-undef
      rdt('track', 'SignUp');
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
      ReactGA.event({
        category: 'lp-events',
        action: 'signup',
        label: 'signup',
      });
      yield put({ type: actions.SIGNUP_SUCCESS });
      action.history.replace(url);
    } else {
      yield put({ type: actions.SIGNUP_ERROR, error });
    }
  } catch (error) {
    yield put({ type: actions.SIGNUP_ERROR, error: error.message });
  }
}

export function* emailLogin(action) {
  try {
    if (!validateEmail(action.email)) {
      yield put({ type: actions.SIGNUP_ERROR, error: 'Please enter a valid email address' });
      return;
    }
    const { data } = yield call(API.emailLogin, action.email, action.password);
    const { url, status, error } = data;
    if (status === 'ok') {
      yield put({ type: actions.LOGIN_EMAIL_SUCCESS });
      action.history.replace(url);
    } else {
      yield put({ type: actions.LOGIN_EMAIL_ERROR, error });
    }
  } catch (error) {
    yield put({ type: actions.LOGIN_EMAIL_ERROR, error: error.message });
  }
}

export function* getUsers() {
  try {
    const jwt = yield select(state => state.user.jwt);

    const { data: { users } } = yield call(API.getUsers, jwt);

    yield put({ type: actions.LOAD_USERS_SUCCESS, users });
  } catch (error) {
    yield put({ type: actions.LOAD_USERS_ERROR, error: error.message });
  }
}

export function* inviteUser(action) {
  try {
    const jwt = yield select(state => state.user.jwt);
    yield call(API.inviteUser, action.userId, jwt);
    yield getUsers();
    yield put({ type: actions.INVITE_FINISHED });
  } catch (error) {
    yield put({ type: actions.LOAD_USERS_ERROR, error: error.message });
  }
}

export function* remindUser(action) {
  try {
    const jwt = yield select(state => state.user.jwt);
    yield call(API.remindUserInvite, action.userId, jwt);
    yield getUsers();
    yield put({ type: actions.INVITE_FINISHED });
  } catch (error) {
    yield put({ type: actions.LOAD_USERS_ERROR, error: error.message });
  }
}

// eslint-disable-next-line require-yield
export function* logout() {
  localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
}

function* regenerateNickname() {
  try {
    const jwt = yield select(state => state.user.jwt);
    const { data: { nickname } } = yield call(API.regenerateUserNickname, jwt);

    yield put({ type: actions.REGENERATE_NICKNAME_SUCCESS, nickname });
  } catch (error) {
    yield put({ type: actions.REGENERATE_NICKNAME_ERROR, error: error.message });
  }
}

function* pickNickname() {
  try {
    const jwt = yield select(state => state.user.jwt);
    const userTimezone = momentTimezone.tz.guess();

    yield call(API.pickUserNickname, jwt, userTimezone);
  } catch (error) {
    yield put({ type: actions.REGENERATE_NICKNAME_ERROR, error: error.message });
  }
}

function* qualifyLead(action) {
  try {
    const jwt = yield select(state => state.user.jwt);
    const { currentProfession, northObjective } = action;
    yield call(API.qualifyLead, jwt, currentProfession.value, northObjective.value);
    yield put({ type: actions.QUALIFY_LEAD_SUCCESS });
  } catch (error) {
    yield put({ type: actions.REGENERATE_NICKNAME_ERROR, error: error.message });
  }
}

function* setPermissions(action) {
  try {
    const jwt = yield select(state => state.user.jwt);

    yield call(API.setPermissions, action.profileUpdatesComm, action.opportunitiesComm, jwt);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.JWT_LOGIN, jwtLogin),
    yield takeEvery(actions.SIGNUP, emailSignup),
    yield takeEvery(actions.LOGIN_EMAIL, emailLogin),
    yield takeEvery(actions.LOGOUT, logout),
    yield takeEvery(actions.REGENERATE_NICKNAME, regenerateNickname),
    yield takeEvery(actions.PICK_NICKNAME, pickNickname),
    yield takeEvery(actions.QUALIFY_LEAD, qualifyLead),
    yield takeEvery(actions.USER_SET_PERMISSIONS, setPermissions),
    yield takeEvery(actions.LOAD_USERS, getUsers),
    yield takeEvery(actions.INVITE_USER, inviteUser),
    yield takeEvery(actions.REMIND_USER, remindUser),
    yield takeEvery(actions.RELOAD_USER, reloadUser),
  ]);

  const savedJwt = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);

  if (savedJwt) {
    yield put({ type: actions.JWT_LOGIN, jwt: savedJwt });
  }
}
