import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Timer from 'react-compound-timer';
import * as values from 'object.values';

import actions from '../../constants/actions';
import { getCurrentSection, getCurrentAnswer, getCurrentQuestion } from '../../selectors/test';
import * as allTestData from '../../constants/testData';

export default withRouter(({ history, onNext, color = '' }) => {
  const testData = useSelector(state => state.test.data);

  if (!testData) {
    return (<div />);
  }

  if (!Object.values) {
    values.shim();
  }

  const dispatch = useDispatch();

  const currentQuestionIndex = useSelector(state => state.test.currentQuestionIndex);
  const currentSectionIndex = useSelector(state => state.test.currentSectionIndex);
  const sectionData = useSelector(getCurrentSection);
  const lastQuestionInSection = (currentQuestionIndex + 1 === sectionData.questions.length);
  const moreSectionsAvailable = (currentSectionIndex + 1 < testData.sections.length);
  const { slug } = Object.values(allTestData).find(t => t.type === testData.type);
  const currentAnswer = useSelector(getCurrentAnswer);
  const currentQuestion = useSelector(getCurrentQuestion);
  const isNextInactive = (
    currentQuestion
    && ['text_select', 'image_select', 'experience_select'].includes(currentQuestion.type)
    && !currentAnswer
  );

  return (
    <div className="test-footer">
      <div className="test-footer-wrapper">
        <div className="test-footer-box">
          <button
            type="button"
            onClick={() => dispatch({ type: actions.PREV_QUESTION })}
            className="test-footer-back w-inline-block"
          />
          <div className="test-footer-info">
            <div className="test-section-txt">
              {sectionData.name}
              &nbsp;-&nbsp;
              <span className="question-number">
                {currentQuestionIndex + 1}
                &nbsp;of&nbsp;
                {sectionData.questions.length}
              </span>
            </div>
            {sectionData.timeLimit && (
              <Timer
                initialTime={sectionData.timeLimit * 1000}
                direction="backward"
                formatValue={value => (value < 10 ? `0${value}` : value)}
                checkpoints={[...Array(sectionData.timeLimit / 5).keys()].map(i => ({
                  time: i * 5 * 1000,
                  callback: () => {
                    dispatch({ type: actions.BUMP_TEST_DURATION });

                    if (i === 0) {
                      history.replace(moreSectionsAvailable
                        ? `/tests/${slug}/section/${currentSectionIndex + 2}`
                        : `/tests/${slug}/done`);
                    }
                  },
                }))}
              >
                <div className="time">
                  <img src="images/icon_time.png" width="12" alt="" className={`time-icon ${color}`} />
                  <div className={`time-txt ${color}`}>
                    <Timer.Minutes />
                    :
                    <Timer.Seconds />
                  </div>
                </div>
              </Timer>
            )}
          </div>
        </div>
        {isNextInactive && (
          <div className={`test-footer-next inactive w-inline-block ${color}`}>
            <img src="images/next_icon.png" width="10" alt="" />
          </div>
        )}
        {!isNextInactive && lastQuestionInSection && (
          <button
            type="button"
            onClick={
              () => {
                onNext();
                dispatch({ type: actions.ALLOW_TRANSITIONS });
                setTimeout(() => {
                  history.push(moreSectionsAvailable
                    ? `/tests/${slug}/section/${currentSectionIndex + 2}`
                    : `/tests/${slug}/done`);
                }, 300);
              }
            }
            className={`test-footer-next w-inline-block ${color}`}
          >
            <img src="images/next_icon.png" width="10" alt="" />
          </button>
        )}
        {!isNextInactive && !lastQuestionInSection && (
          <button
            type="button"
            onClick={() => {
              onNext();
              dispatch({ type: actions.NEXT_QUESTION });
            }}
            className={`test-footer-next w-inline-block ${color}`}
          >
            <img src="images/next_icon.png" width="10" alt="" />
          </button>
        )}
      </div>
    </div>
  );
});
