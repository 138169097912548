import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { getUserLogged, getUserLoading } from '../../selectors/user';

export default ComposedComponent => (props) => {
  const logged = useSelector(getUserLogged);
  const loading = useSelector(getUserLoading);

  if (loading) {
    return (
      <div className="full-bg">
        <div className="content-wrapper">
          <ReactLoading type="bars" color="#95A2B8" />
        </div>
      </div>
    );
  }

  if (logged) {
    return <ComposedComponent {...props} />;
  }

  return (<Redirect to="/" />);
};
