import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../360/Footer';

export default (params) => {
  const {
    title, icon, description, completionTime,
    tips, modal: Modal, slug, color,
  } = params;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <div className="modal-overlay" style={{ display: modalOpen ? 'flex' : 'none' }}>
        <div className="modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
            <button type="button" onClick={() => setModalOpen(false)} className="modal-close w-inline-block" />
          </div>
          <Modal />
        </div>
      </div>
      <div className="nav-bar">
        <div className="nav-bar-back">
          <div className="nav-back-box">
            <Link to="/tests" className="nav-back-btn w-inline-block" />
            <div className="nav-label">Test info</div>
          </div>
        </div>
      </div>
      <div className="page-wrapper">
        <div className="page-content narrow">
          <div className="test-info-header">
            <div className="test-name-box">
              <img src={icon} alt="" />
              <div className="test-title-box">
                <div className="test-title">{title}</div>
              </div>
            </div>
            <button
              type="button"
              className={`more-info-btn w-inline-block ${color}`}
              onClick={() => setModalOpen(true)}
            >
              <div>See Why</div>
            </button>
          </div>
          <div className="divider" />
          <div className="test-info">
            <div className="col-wrapper">
              <p className="body-txt">
                {description}
              </p>
              <div className="time-box-stack">
                <div className="time-label">Time to complete the entire test:</div>
                <div className="time">
                  <div className={`time-icon ${color}`} />
                  <div className={`time-txt ${color}`}>
                    ~
                    {completionTime}
                  </div>
                </div>
              </div>
              <div className="test-tips mob">
                <div className="tips-title">BEFORE YOU START</div>
                {tips.map(tip => (
                  <div key={tip.substring(0, 10)} className="list-item">
                    <div className={`dot-360 ${color}`} />
                    <div className="body-txt">{tip}</div>
                  </div>
                ))}
              </div>
              <Link to={`/tests/${slug}/section/1`} className={`button w-inline-block ${color}`}>
                <div>TAKE THE TEST</div>
              </Link>
            </div>
            <div className="col-wrapper right">
              <div className="test-tips">
                <div className="tips-title">BEFORE YOU START</div>
                {tips.map(tip => (
                  <div key={tip.substring(0, 10)} className="list-item">
                    <div className={`dot-360 ${color}`} />
                    <div className="body-txt">{tip}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer narrow />
    </div>
  );
};
