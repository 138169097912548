import React from 'react';

export default ({ closeModal }) => (
  <div className="modals-rank-info">
    <div className="modal-overlay rank-cadet">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Rank info</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-content">
          <div className="rank-wrapper">
            <div className="rank-info-group">
              <div className="rank-badge-group">
                <div className="username-badge small cadet" />
                <div className="rank-type">
                  <div className="rank-name-group">
                    <div className="rank-label">Rank - </div>
                    <div className="rank-name">Cadet</div>
                  </div>
                  <a
                    href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="source-btn rank w-inline-block"
                  >
                    <div>Handbook</div>
                  </a>
                </div>
              </div>
              <a
                href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                target="_blank"
                rel="noopener noreferrer"
                className="handbook-link w-inline-block"
              >
                {' '}
              </a>
            </div>
            <div className="rank-divider" />
            <div className="body-txt">
              Cadets are outstanding coders with substantial professional experience,
              good language skills, and an ambition to improve continually.
              <br />
              <br />
              Cadets will strive to reach
              {' '}
              <strong>Star Rank</strong>
              . To do this, they must accumulate at least
              {' '}
              <strong>70 XP</strong>
              {' '}
              over the course of
              {' '}
              <strong>40 days</strong>
              {' '}
              since onboarding.
            </div>
            <div className="benefits-label">CADET&nbsp;BENEFITS:</div>
            <div className="info-list">
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">Access to the North community.</div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">Access to Workshops and Special Events.</div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">
                    Access to books, courses and certifications via the
                    {' '}
                    <a
                      href="https://www.oreilly.com/online-learning/features.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-link list"
                    >
                      O’Reilly Learning Platform
                    </a>
                    {' '}
                    - unlocked when member reaches
                    {' '}
                    <strong>30 XP</strong>
                    .
                  </div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">
                    Two one-to-one professional coaching sessions with
                    our senior members - unlocked when member reaches
                    {' '}
                    <strong>50 XP</strong>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
