import React from 'react';

import TestDone from '../TestDone';
import { ExpData } from '../../../constants/testData';

export default params => (
  <TestDone
    icon={ExpData.iconLarge}
    type={ExpData.slug}
    color={ExpData.color}
    {...params}
  />
);
