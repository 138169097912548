import React from 'react';

export default (props) => {
  const {
    isVisible,
  } = props;

  if (!isVisible) {
    return (null);
  }
  return (
    <div>
      <div id="particles-bg" className="particles-bg" />
      <div style={{ display: 'none' }}>
        {setTimeout(() => {
          try {
            // eslint-disable-next-line no-undef
            particlesJS('particles-bg', {
              particles: {
                number: {
                  value: 20,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: '#646464',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: '#646464',
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'out',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'window',
                events: {
                  onhover: {
                    enable: false,
                    mode: 'repulse',
                  },
                  onclick: {
                    enable: false,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        }, 100)}
      </div>
    </div>
  );
};
