/* eslint-disable import/no-cycle */
import React from 'react';

import TestInfo from '../TestInfo';
import { GMAData } from '../../../constants/testData';

export default () => (
  <TestInfo
    title={GMAData.title}
    icon={GMAData.iconSmall}
    description={GMAData.description}
    completionTime={`${GMAData.completionTime} minutes`}
    tips={GMAData.tips}
    modal={GMAData.whyModal}
    slug={GMAData.slug}
    color={GMAData.color}
  />
);
