import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  const {
    menuOpen,
    onHideMenu,
    whyRef,
    highlightsRef,
    insOutsRef,
  } = props;

  const scrollToRef = ref => () => {
    onHideMenu();
    window.scrollTo(0, ref.current.offsetTop);
  };

  return (
    <div className={`menu-mob ${menuOpen ? 'shown' : 'hidden'}`}>
      <div className="menu-mob-card">
        <div className="mob-close-bar">
          <button
            type="button"
            onClick={onHideMenu}
            className="mob-menu-close w-inline-block"
            data-ix="mob-close"
          />
        </div>
        <div className="mob-menu-items">
          <button
            type="button"
            onClick={onHideMenu}
            className="mob-north-icon w-inline-block"
            data-ix="mob-close"
          />
          <button type="button" onClick={scrollToRef(whyRef)} className="nav-link mob" data-ix="mob-close">
            WHY NORTH
          </button>
          <button type="button" onClick={scrollToRef(highlightsRef)} className="nav-link mob" data-ix="mob-close">
            HIGHLIGHTS
          </button>
          <button type="button" onClick={scrollToRef(insOutsRef)} className="nav-link mob" data-ix="mob-close">
            Ins &amp; Outs
          </button>
          <Link to="/tests" className="nav-link mob" data-ix="mob-close">
            NORTH 360
          </Link>
          <div className="mob-reg-btns">
            <Link to="/signup-mb" className="nav-link register mob">
              Join Us
            </Link>
            <Link to="/login-mb" className="nav-link register login mob">
              Log In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
