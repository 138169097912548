import React from 'react';

import CadetProgress from './CadetProgress';

export default ({ visibleTab, setVisibleTab, rank, points }) => (
  <div className="main-tabs time-tag">
    <button type="button" className={`w-inline-block test-tab-btn ${visibleTab === 0 ? 'active' : ''}`} onClick={() => setVisibleTab(0)}>
      <div className={`tab-name ${visibleTab === 0 ? 'active' : ''}`}>
        <div className={`tab-number tab-one ${visibleTab === 0 ? 'active' : ''}`} />
        <div className={`tab-title ${visibleTab === 0 ? 'active' : ''}`}>NORTH&nbsp;360 TESTS</div>
      </div>
    </button>
    <button type="button" className={`w-inline-block xp-tab-btn ${visibleTab === 1 ? 'active' : ''}`} onClick={() => setVisibleTab(1)}>
      <div className="xp-tab-content">
        <div className="tab-name">
          <div className={`tab-number tab-two ${visibleTab === 1 ? 'active' : ''}`} />
          <div className={`tab-title ${visibleTab === 1 ? 'active' : ''}`}>NORTH EXPERIENCE</div>
        </div>
        {['star', 'superstar'].includes(rank) && (
          <div className="xp-number">
            <div className="xp-value active">
              {points}
            </div>
            <div className="xp-tag active">XP</div>
          </div>
        )}
        {rank === 'cadet' && <CadetProgress points={points} />}
      </div>
      {/* <div className="xp-time-tag">
          <img src="images/icon_time_white.svg" alt="" className="xp-time-icon" />
          <div className="xp-time-txt">40 days left</div>
        </div> */}
    </button>
  </div>
);
