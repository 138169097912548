import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

export default ({ closeModal }) => {
  useEffect(() => {
    ReactGA.event({
      category: 'Tests',
      action: 'completed-tests',
      label: 'Completed All Tests',
    });
  }, []);
  return (
    <div className="modal-rank-up">
      <div className="modal-overlay hang-tight">
        <div className="modal pending">
          <div className="check-pending" />
          <div className="pending-wrapper">
            <div className="pending-left">
              <div className="pending-thanks">Thanks for submitting all your results!</div>
              <div className="pending-modal-title">Hang Tight!</div>
              <div className="body-txt pending">We’re evaluating your submission and we’ll determine your rank ASAP.</div>
              <button
                type="button"
                className="rank-modal-btn w-inline-block"
                onClick={() => closeModal()}
              >
                <div>Great, got it!</div>
              </button>
            </div>
            <div className="pending-right">
              <img
                src="images/dev_img_mob.svg"
                data-w-id="c60d2c11-b336-fd3a-9f63-e0cb8c2715ae"
                alt=""
                className="dev"
              />
              <img
                src="images/dev_shadow_mob.svg"
                data-w-id="7a159bf3-dbfa-fa84-d945-c2789e7e1277"
                alt=""
                className="dev-shadow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
