import React from 'react';

export default ({ openModal }) => (
  <div className="rank-group">
    <div className="rank-header">RANK -</div>
    <div className="rank-txt">STAR</div>
    <button
      type="button"
      className="rank-info w-inline-block"
      onClick={() => openModal()}
    />
  </div>
);
