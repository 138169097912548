import {
  put, takeEvery, all, call,
} from 'redux-saga/effects';

import actions from '../constants/actions';
import API from '../services/api';

export function* loadProfile(action) {
  try {
    const { data } = yield call(API.getProfile, action.nickname);

    yield put({ type: actions.LOAD_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.LOAD_PROFILE_ERROR, error: error.message });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LOAD_PROFILE, loadProfile),
  ]);
}
