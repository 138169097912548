/* eslint-disable import/no-cycle */
import React from 'react';

import TestInfo from '../TestInfo';
import { EIData } from '../../../constants/testData';

export default () => (
  <TestInfo
    title={EIData.title}
    icon={EIData.iconSmall}
    description={EIData.description}
    completionTime={`${EIData.completionTime} minutes`}
    tips={EIData.tips}
    modal={EIData.whyModal}
    slug={EIData.slug}
    color={EIData.color}
  />
);
