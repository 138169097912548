import React from 'react';

export default ({ data }) => (
  <div className="stack-card">
    <div className="stack-card-content">
      <div className="stack-top">
        <div className="so-logo-wrapper"><img src="images/stack_logo.svg" alt="" className="so-logo" /></div>
        <div className="stack-divider mob" />
        <div className="stack-stats">
          <div className="reputation">
            <div className="rep-number">{data.reputation}</div>
            <div className="rep-label">REPUTATION</div>
          </div>
          <div className="stats-divider" />
          <div className="medals">
            {data.badge_counts.gold > 0 && (
              <div className="so-medal">
                <div className="medal-dot" />
                <div className="medal-txt">{data.badge_counts.gold}</div>
              </div>
            )}
            {data.badge_counts.silver > 0 && (
              <div className="so-medal silver">
                <div className="medal-dot silver" />
                <div className="medal-txt">{data.badge_counts.silver}</div>
              </div>
            )}
            {data.badge_counts.bronze > 0 && (
              <div className="so-medal bronze">
                <div className="medal-dot bronze" />
                <div className="medal-txt">{data.badge_counts.bronze}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {data.tags.length > 0 && (<div className="stack-divider" />)}
      {data.tags.length > 0 && (
        <div className="top-tag">
          <div className="tag-name">
            <div>{data.tags[0].tag_name}</div>
          </div>
          <div className="tag-stats">
            <div className="so-stats">
              <div className="so-score">
                <div className="so-label">SCORE</div>
                <div className="so-value">{data.tags[0].answer_score}</div>
              </div>
              <div className="so-posts">
                <div className="so-score">
                  <div className="so-label">POSTS</div>
                  <div className="so-value">{data.tags[0].answer_count}</div>
                </div>
              </div>
            </div>
            {/* <div className="so-percent">
              <div className="so-score">
                <div className="so-label">POSTS %</div>
                <div className="so-value">24</div>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {data.tags.length > 0 && (
        <div className="top-tag-wrapper">
          <div className="top-tag bottom left">
            <div className="tag-name">
              <div>{data.tags[1].tag_name}</div>
            </div>
            <div className="tag-stats">
              <div className="so-stats">
                <div className="so-score">
                  <div className="so-label">SCORE</div>
                  <div className="so-value">{data.tags[1].answer_score}</div>
                </div>
                <div className="so-posts">
                  <div className="so-score">
                    <div className="so-label">POSTS</div>
                    <div className="so-value">{data.tags[1].answer_count}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top-tag bottom right">
            <div className="tag-name">
              <div>{data.tags[2].tag_name}</div>
            </div>
            <div className="tag-stats">
              <div className="so-stats">
                <div className="so-score">
                  <div className="so-label">SCORE</div>
                  <div className="so-value">{data.tags[2].answer_score}</div>
                </div>
                <div className="so-posts">
                  <div className="so-score">
                    <div className="so-label">POSTS</div>
                    <div className="so-value">{data.tags[2].answer_count}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);
