import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isLeadQualified, getUserPickedNickname } from '../../selectors/user';

export default (WrappedComponent) => {
  const HOC = (props) => {
    const leadQualified = useSelector(isLeadQualified);
    const pickedNickname = useSelector(getUserPickedNickname);
    if (!pickedNickname) {
      return (<Redirect to="/welcome" />);
    }
    if (!leadQualified) {
      return (<Redirect to="/quick-intro" />);
    }

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
