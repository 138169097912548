/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

export default ({ closeModal }) => (
  <div className="modals-rank-info">
    <div className="modal-overlay rank-star">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Rank info</div>
          <button
            type="button"
            className="modal-close w-inline-block"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-content">
          <div className="rank-wrapper">
            <div className="rank-info-group">
              <div className="rank-badge-group">
                <div className="username-badge small star" />
                <div className="rank-type">
                  <div className="rank-name-group">
                    <div className="rank-label">Rank - </div>
                    <div className="rank-name">Star</div>
                  </div>
                  <a
                    href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="source-btn rank w-inline-block"
                  >
                    <div>Handbook</div>
                  </a>
                </div>
              </div>
              <a
                href="https://github.com/telepat-io/code/blob/master/HANDBOOK.md"
                target="_blank"
                className="handbook-link w-inline-block"
                rel="noopener noreferrer"
              />
            </div>
            <div className="rank-divider" />
            <div className="body-txt">Stars are outstanding coders that have proven their skills and determination to improve and grow.</div>
            <div className="benefits-label">STAR&nbsp;BENEFITS:</div>
            <div className="info-list">
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">All Cadet benefits.</div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">
                    Access to&nbsp;
                    <strong>top remote work opportunities</strong>
                    &nbsp;via the Telepat network.
                  </div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">
                    The North&nbsp;
                    <strong>Mystery Swag Box</strong>
                    , shipped internationally.
                  </div>
                </div>
              </div>
              <div className="list-item info">
                <div className="dot-360" />
                <div className="list-content">
                  <div className="body-txt">Access to mentorship sessions with industry professionals.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
