import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserData } from '../../selectors/user';

export default (params) => {
  const {
    dates: { nextTestDuration },
    done,
    className,
    title,
    iconLarge,
    iconSmall,
    shortDescription,
    completionTime,
    slug,
    color = '',
  } = params;
  const userData = useSelector(getUserData);

  const retakeHeader = () => {
    if (nextTestDuration > 0) {
      return (
        <div className="retake-tag">
          Retake the test in:
          <strong>
            &nbsp;
            {nextTestDuration}
            &nbsp;days
          </strong>
        </div>
      );
    }

    return (
      <div className="retake-tag">Retake the test anytime</div>
    );
  };

  let retakeDestination = '#';
  let retakeTestLinkClassNames = '';
  if (nextTestDuration <= 0) {
    retakeDestination = `/tests/${slug}/info`;
    retakeTestLinkClassNames = color;
  } else {
    retakeTestLinkClassNames = 'disabled';
  }

  retakeTestLinkClassNames += ' card-btn left w-inline-block';

  if (done) {
    return (
      <div className={`card filled ${color} ${className || ''}`}>
        <div className="card-title filled">{title}</div>
        <img src={iconLarge} alt="" className="test-icon-filled" />
        {retakeHeader()}
        <div className="card-actions filled">
          <Link to={retakeDestination} className={retakeTestLinkClassNames}>
            <div>Retake</div>
          </Link>
          <Link
            to={userData ? `/p/${userData.nickname}` : '/'}
            className={`card-btn ${color} w-inline-block`}
          >
            <div>Results</div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${className || ''}`}>
      <div className="card-header">
        <img src={iconSmall} width="48" alt="" />
        <div className="card-title">{title}</div>
      </div>
      <div className="card-divider" />
      <p className="body-txt">{shortDescription}</p>
      <div className="card-actions">
        <div className="time">
          <div className="card-time-label">Duration:</div>
          <div className={`time-icon ${color}`} />
          <div className={`time-txt ${color}`}>
            ~
            {completionTime}
            {' '}
            min
          </div>
        </div>
        <Link to={`/tests/${slug}/info`} className={`card-btn w-inline-block ${color}`}>
          <div>Read more</div>
        </Link>
      </div>
    </div>
  );
};
