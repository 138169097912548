import React from 'react';

export default ({ answers, currentAnswer, setAnswer }) => (
  <div className="test-content tc-center">
    <div className="test-answers">
      {answers.map(answer => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          key={answer._id}
          src={`images/tests/${answer.image}`.replace(
            '.png',
            `${currentAnswer[0] === answer._id ? '_red' : ''}.png`,
          )}
          width="92"
          alt=""
          onClick={() => setAnswer([answer._id])}
          onKeyDown={() => setAnswer([answer._id])}
        />
      ))}
    </div>
  </div>
);
