import React from 'react';

export default (props) => {
  const { whyRef, onShowSignup } = props;

  return (
    <div id="why-north" ref={whyRef} className="stages-wrapper">
      <div className="stages-content">
        <div className="main-title">Why join North?</div>
        <div className="main-tag">
          Your Telepat North experience is split into 3 stages.
        </div>
        <div className="north-stages-wrapper">
          <div className="stage-01-wrapper">
            <div className="stage-name">NORTH PROFILE</div>
            <div className="stage-arrow-wrapper">
              <div className="stage-arrow-dot" />
              <div className="stage-arrow-line" />
              <div className="stage-no">
                <div>1</div>
              </div>
              <div className="stage-arrow-line" />
              <img src="images/stage_arrow.svg" alt="" className="stage-arrow" />
            </div>
            <div className="lp-rank-wrapper">
              <div className="lp-rank-label">RANK</div>
              <div className="rank-badge aspirant" />
              <div className="lp-rank-name">Aspirant</div>
              <div className="rank-divider" />
              <div className="stage-benefits-wrapper">
                <div className="stage-benefits-title">BENEFITS</div>
                <div className="stage-benefits-item">North Community</div>
                <div className="stage-benefits-item">Workshops and Events</div>
              </div>
              <button type="button" onClick={() => onShowSignup()} className="stage-join-btn w-inline-block">
                JOIN US
              </button>
            </div>
          </div>
          <div className="stage-divider" />
          <div className="stage-02-wrapper">
            <div className="stage-name">NORTH&nbsp;ACCELERATION</div>
            <div className="stage-arrow-wrapper">
              <div className="stage-arrow-dot" />
              <div className="stage-arrow-line" />
              <div className="stage-no">
                <div>2</div>
              </div>
              <div className="stage-arrow-line" />
              <img src="images/stage_arrow.svg" alt="" className="stage-arrow" />
            </div>
            <div className="acceleration-wrapper">
              <div className="lp-rank-wrapper">
                <div className="lp-rank-label">RANK</div>
                <div className="rank-badge cadet" />
                <div className="lp-rank-name">Cadet</div>
                <div className="rank-divider" />
                <div className="stage-benefits-wrapper">
                  <div className="stage-benefits-title">BENEFITS</div>
                  <div className="stage-benefits-item">North Community</div>
                  <div className="stage-benefits-item">Books, courses &amp; via O’Reilly’s Learning Platform</div>
                  <div className="stage-benefits-item">Workshops and Events</div>
                </div>
              </div>
              <div className="stage-divider" />
              <div className="lp-rank-wrapper">
                <div className="lp-rank-label">RANK</div>
                <div className="rank-badge star" />
                <div className="lp-rank-name">Star</div>
                <div className="rank-divider" />
                <div className="stage-benefits-wrapper">
                  <div className="stage-benefits-title">BENEFITS</div>
                  <div className="stage-benefits-item">Profile Promo</div>
                  <div className="stage-benefits-item">Coaching</div>
                  <div className="stage-benefits-item">Career Opportunities</div>
                  <div className="stage-benefits-item">Swag Box</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stage-divider" />
          <div className="stage-03-wrapper">
            <div className="stage-name">NORTH&nbsp;CAREER</div>
            <div className="stage-arrow-wrapper">
              <div className="stage-arrow-dot" />
              <div className="stage-arrow-line" />
              <div className="stage-no">
                <div>3</div>
              </div>
              <div className="stage-arrow-line" />
              <img src="images/stage_arrow.svg" alt="" className="stage-arrow" />
            </div>
            <div className="lp-rank-wrapper">
              <div className="lp-rank-label">RANK</div>
              <div className="rank-badge super-star" />
              <div className="lp-rank-name">Super Star</div>
              <div className="rank-divider" />
              <div className="stage-benefits-wrapper">
                <div className="stage-benefits-title">BENEFITS</div>
                <div className="stage-benefits-item">Mentorship</div>
                <div className="stage-benefits-item">Career Opportunities</div>
              </div>
            </div>
            <div className="stage-01-wrapper" />
          </div>
        </div>
        <div className="section-arrow gray">
          <div className="scroll-txt gray">
            CHECK OUT THE HIGHLIGHTS OF EACH STAGE BELOW
          </div>
          <img
            src="images/lp/slide_arrow_gray.png"
            width="14"
            style={{
              WebkitTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              MozTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              msTransform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              transform:
                'translate3d(0, 0PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
            }}
            alt=""
            className="slider-arrow"
          />
        </div>
      </div>
    </div>
  );
};
