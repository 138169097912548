export const getUserLogged = state => state.user.logged;

export const getUserLoading = state => state.user.loading;

export const getUserLoadingNickname = state => state.user.loadingNickname;

export const getUserData = state => state.user.data;

export const getUserError = state => state.user.error;

export const getAllUsers = state => state.user.users;

export const getUserPickedNickname = state => state.user.data && state.user.data.pickedNickname;

export const isGhUser = state => state.user.data && state.user.data.isGhUser;

export const isLeadQualified = state => state.user.data && state.user.data.leadQualified;

export const isProcessingInvite = state => state.user.processingInvite;

export const getNickname = state => state
  .user.data.nickname
  .replace(/(?:^|-)\S/g, a => a.toUpperCase())
  .replace(/-/g, ' ');

export const getRecentResults = state => state.user.data.recentResults || [];
