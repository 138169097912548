import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { withRouter } from 'react-router-dom';

import actions from '../../constants/actions';
import { profOptions, northObjectiveOptions } from '../../constants/selectOptions';
import { isLeadQualified } from '../../selectors/user';

export default withRouter((props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [currentProfession, setCurrentProfession] = useState(null);
  const [northObjective, setNorthObjective] = useState(null);
  const leadQualified = useSelector(isLeadQualified);

  const onProfSelectChanged = (profOption) => {
    setCurrentProfession(profOption);
  };

  const onObjectiveChanged = (objectiveOption) => {
    setNorthObjective(objectiveOption);
  };

  const onSubmit = () => {
    dispatch({
      type: actions.QUALIFY_LEAD, currentProfession, northObjective,
    });
  };

  useEffect(() => {
    if (leadQualified) {
      history.replace('/tests');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadQualified]);

  useEffect(() => {
    const onValuesChanged = () => {
      if (currentProfession && northObjective) setSubmitEnabled(true);
      else setSubmitEnabled(false);
    };

    onValuesChanged();
  }, [currentProfession, northObjective]);

  const DropdownIndicator = dProps => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...dProps}>
      <button type="button" className="dropdown-arrow w-inline-block" />
    </components.DropdownIndicator>
  );

  return (
    <div className="full-bg">
      <div className="intro-card">
        <div className="content-wrapper">
          <div className="quick-intro-label">Let’s begin your North journey.</div>
          <div className="quick-info-title">What describes you best?</div>
          <div className="dropdown-wrapper">
            <div className="dropdown-group">
              <div className="quick-info-txt">I am currently</div>
              <Select
                classNamePrefix="dropdown"
                className="dropdown"
                isSearchable={false}
                options={profOptions}
                placeholder="Select"
                onChange={onProfSelectChanged}
                components={{ DropdownIndicator }}
              />
            </div>
            <div className="dropdown-group">
              <div className="quick-info-txt">and I want to join North to</div>
              <Select
                classNamePrefix="dropdown"
                className="dropdown"
                isSearchable={false}
                options={northObjectiveOptions}
                placeholder="Select"
                onChange={onObjectiveChanged}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <button type="button" onClick={onSubmit} className={`done-btn w-inline-block ${!submitEnabled ? 'disabled' : ''}`} disabled={!submitEnabled}>
            <img src="images/done_icon_1done_icon.png" width="22" alt="" />
          </button>
        </div>
      </div>
    </div>
  );
});
