import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ReactLoading from 'react-loading';

import actions from '../../constants/actions';
import { getUserData, getUserLoadingNickname, getNickname } from '../../selectors/user';

export default () => {
  const dispatch = useDispatch();
  const { pickedNickname } = useSelector(getUserData);
  const nickname = useSelector(getNickname);
  const loading = useSelector(getUserLoadingNickname);
  const regenerateNickname = () => dispatch({ type: actions.REGENERATE_NICKNAME });
  const pickNickname = () => dispatch({ type: actions.PICK_NICKNAME });

  if (pickedNickname) {
    return (<Redirect to="/tests" />);
  }

  return (
    <div className="full-bg">
      <div className="intro-card">
        <div className="content-wrapper">
          <img src="images/icon_alias.png" width="80" alt="" />
          <div className="username-tag">Choose your nickname:</div>
          <div className="username">{nickname}</div>
          {loading && (
            <div style={{ marginTop: 40, marginBottom: 40 }}>
              <ReactLoading
                type="bars"
                color="#95A2B8"
                height={32}
                width={32}
              />
            </div>
          )}
          {!loading && (
            <button
              type="button"
              className="randomize-btn w-inline-block"
              onClick={regenerateNickname}
            />
          )}
          <Link to="/tests" onClick={pickNickname} className="done-btn w-inline-block">
            <img src="images/done_icon.png" width="22" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};
