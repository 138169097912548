import React from 'react';

import TestDone from '../TestDone';
import { GMAData } from '../../../constants/testData';

export default params => (
  <TestDone
    icon={GMAData.iconLarge}
    type={GMAData.slug}
    color={GMAData.color}
    {...params}
  />
);
