import React from 'react';

export default (props) => {
  const {
    onShowSignup,
    onShowLogin,
  } = props;

  return (
    <div className="registration-btns">
      <button
        type="button"
        onClick={onShowSignup}
        className="nav-link hero w-inline-block"
        data-ix="show-signup"
      >
        <div>Join Us</div>
      </button>
      <button
        type="button"
        className="nav-link hero login w-inline-block"
        data-ix="show-login"
        onClick={onShowLogin}
      >
        <div>Log In</div>
      </button>
    </div>
  );
};
