import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../constants/actions';

export default withRouter((props) => {
  const testData = useSelector(state => state.test.data);
  const groupAnswers = useSelector(state => state.test.groupAnswers);
  const dispatch = useDispatch();
  let addedGroups = 0;

  // array#reduce avoided due to not skipping undefined elements
  if (groupAnswers) {
    // eslint-disable-next-line no-restricted-syntax
    for (const answer of groupAnswers) {
      if (answer) addedGroups += 1;
    }
  }

  const { totalGroups, history } = props;

  if (!testData) {
    return (<div />);
  }

  return (
    <div className="test-footer fixed">
      <div className="test-footer-wrapper wide">
        <div className="test-footer-box">
          <div className="test-footer-info">
            <div className="test-section-txt no-time">
              Experience areas
              &nbsp;-&nbsp;
              <span className="question-number">
                {addedGroups}
                &nbsp;of&nbsp;
                {totalGroups}
              </span>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={
            () => {
              dispatch({ type: actions.ALLOW_TRANSITIONS });
              setTimeout(() => {
                history.push('/tests/exp/done');
              }, 300);
            }
          }
          className="test-footer-next w-inline-block"
        >
          <img src="images/done_icon.png" width="22" alt="" />
        </button>
      </div>
    </div>
  );
});
