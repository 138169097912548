import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../360/Footer';

export default () => (
  <div>
    <div className="nav-bar">
      <div className="nav-bar-back">
        <div className="nav-back-box">
          <Link to="/tests" className="nav-back-btn w-inline-block" />
          <div className="nav-label">Organizational Culture</div>
        </div>
      </div>
    </div>
    <div className="page-wrapper">
      <div className="page-content narrow">
        <div className="intro-header">
          <div className="intro-bar pink" />
          <div className="title">Intro</div>
        </div>
        <div className="intro-content">
          <div className="col-wrapper">
            <div className="intro-txt-left">
              <p className="body-txt">
                This will help you identify the cultural profile of your ideal organization.
                <br />
                <br />
                For each question ask yourself, “When I boil it down and get to the heart of
                the matter, which of the four possible answers most accurately describes my
                ideal experience in an organization?”.
              </p>
              <div className="intro-txt-right mob">
                <p className="body-txt">
                  Answer every question and select only one answer for each. Remember,
                  there are no right or wrong answers!
                </p>
              </div>
            </div>
            <Link to="/tests/org/section/1/run" className="button outline w-inline-block">
              <div>Begin the test</div>
            </Link>
          </div>
          <div className="col-wrapper">
            <div className="intro-txt-right">
              <p className="body-txt">
                Answer every question and select only one answer for each.
                Remember, there are no right or wrong answers!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
