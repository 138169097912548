import React from 'react';
import moment from 'moment';

import PendingCard from './PendingCard';

export default ({ index, assessment }) => (
  <div className={`w-col w-col-6 w-col-stack ${index % 2 ? 'card-col-02' : 'card-col-01'}`}>
    {assessment.status === 'pending' && (
      <PendingCard index={index} />
    )}
    {assessment.status === 'validated' && (
      <div className={`section-card ${index % 2 ? 'right' : 'left'}`}>
        <button type="button" className="card-delete-btn w-inline-block" />
        <div className="card-content">
          <img src={`images/logo_${assessment.subtype}.png`} width="144" alt="" />
          <div className="section-card-divider" />
          <div className="section-card-title">{assessment.name}</div>
          <div className="card-time-wrapper skills">
            <img src="images/icon_time.svg" alt="" className="card-time-icon" />
            <div className="card-time">{moment(assessment.achievedAt).format('LL')}</div>
          </div>
          <div className="body-txt description">{assessment.description}</div>
          <div className="section-card-divider bottom" />
          {typeof assessment.score === 'string' && (
            <div className="card-bottom-content">
              <div className="score-label">Score achieved:</div>
              <div className="skill-score">{assessment.score}</div>
            </div>
          )}
          {typeof assessment.score !== 'string' && (
            <div className="card-bottom-content">
              <div className="score-label">Test completed</div>
              <img src="images/card_test_check.svg" alt="" className="test-completed-check" />
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
