import React from 'react';

export default (params) => {
  const { studies, result } = params;

  return (
    <div className="modal-content">
      {result && result.content && (
        <div>
          <div className="title">Test info</div>
          <div className="info-box">
            {result.content}
            {result.duration > 0 && (
              <div className="time-box-wide">
                <div className="time-label no-margin">Completed in:</div>
                <div className="time wide">
                  <img src="images/icon_time.png" width="12" alt="" className="time-icon blue" />
                  <div className="time-txt blue">
                    {(result.duration / 60).toFixed(1)}
                    &nbsp;min
                  </div>
                </div>
              </div>
            )}
            {(!result.duration || result.duration === 0) && (
              <div style={{ marginBottom: 35 }} />
            )}
          </div>
        </div>
      )}
      <div className="title">Why do we do this test?</div>
      <div className="info-list">
        {studies.map(study => (
          <div key={study.url} className="list-item info">
            <div className="dot-360" />
            <div className="list-content">
              <div className="body-txt">
                {study.text}
              </div>
              <a
                href={study.url}
                target="_blank"
                rel="noopener noreferrer"
                className="source-btn w-inline-block"
              >
                <div>Further reading</div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
